const en = {
  title: "Select an Edition",
  itemAvailable: "Item available.",
  itemsAvailable: "Items available.",
  edition: "Edition",
  price: "Price",
  ownerTh: "Owner",
  continue: "Continue",
  scarcityTh: "Rarity",
  lowestPriceOption: "Lowest",
  highestPriceOption: "Highest",
  noNftForSale: "No NFT for sale for that edition.",
  legendary: "Legendary",
  iconic: "Iconic",
  rare: "Rare",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
