const en = {
  marketingConsent:
    "Do you consent to be recieving marketing materials by email?",
  modalTitle: "Marketing Opt-In",
  accept: "Accept",
  decline: "Decline",
  skip: "Skip",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
