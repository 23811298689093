import { Button } from "../../../../components";
import { getLocalizedStrings } from "config";
import { useLanguage } from "../../../../lib/contexts/LanguageContext";

type Props = {
  onClick: () => void;
};
export function LoginButtonUI({ onClick }: Props) {
  const localizedStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );

  return <Button onClick={onClick}>{localizedStrings.button.login}</Button>;
}
