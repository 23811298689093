const en = {
  login: "Login",
  logout: "Logout",
  wallet: "Wallet",
  connectWallet: "Connect Wallet",
  marketplaceLink: "Marketplace",
  marketplaceLink2: "",
  anonymous: "Anonymous",
  change: "Change",
  utility: "Utility",
  visitMarketPlace: "Visit Marketplace",
  profile: "Profile",
  collection: "Collection",
  mobileCollection: "Collection",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
