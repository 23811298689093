// Merchant addresses do not make sense for Polygon, so I leave them blank.
const MERCHANT_LIST = {
  Testnet: [{ id: "36", address: "" }],
  Mainnet: [{ id: "74", address: "" }],
};

// Again, this means nothing for Polygon, so I put anything.
const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "" },
  Mainnet: { id: 1, address: "" },
};

// Ok, I feel like this whole file is useless for Polygon, except for the MERCHANT_LIST ids
const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 17,
};

export { MERCHANT_LIST, MINT_STORE_MARKET, WALLET_VIEWER_ID };
