import feathersClient from "lib/feathers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Ruleset } from "types";
import { Builder } from "./builder";

const regexExp =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const rulesetAPI = createApi({
  reducerPath: "rulesetAPI",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder: Builder) => ({
    getRulesetByUUID: builder.query<Ruleset.Rule, { uuid: string }>({
      async queryFn({ uuid }) {
        if (!uuid && !regexExp.test(uuid)) {
          throw "UUID not there yet."; // To be improved soon, some work to do with the backend as well
        }

        const data = await feathersClient.service("rulesets").get(uuid);
        return { data };
      },
    }),

    getRulesets: builder.query<Ruleset.Validation[], { rulesets: string[] }>({
      async queryFn({ rulesets }) {
        const data = await feathersClient
          .service("rulesets")
          .create({ rulesets });
        return { data };
      },
    }),
  }),
});
