const en = {
  noResult: "No Result",
  keepTyping: "Keep typing",
  startTyping: "Start Typing",
  unknownStatusTxt: "Unknown",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
