import { Blockchain } from "config";
import { Navigate, useParams } from "react-router-dom";
import { NFT_LIST_ROUTE } from "config/routes";
import { NftOnDemandShowUI } from "./ui/NftOnDemandShowUi";

export type ModalVisible =
  | "claim"
  | "transfer"
  | "sell"
  | "purchase"
  | "withdraw"
  | "login";

type Params = {
  smartContractAddress: string;
  smartContractName: string;
  uuid: string;
};

export default function NftOnDemandShowPage() {
  const blockchain = Blockchain.BLOCKCHAIN_NAME;

  const { uuid, smartContractAddress, smartContractName } = useParams<Params>();

  switch (true) {
    case blockchain === "flow":
      if (smartContractAddress == null || smartContractName == null) {
        // This is an error, go back to the nft list
        return <Navigate replace to={NFT_LIST_ROUTE} />;
      }
      return (
        <NftOnDemandShowUI
          // @ts-ignore
          uuid={uuid}
          contractName={smartContractName}
          contractAddress={smartContractAddress}
        />
      );
    case blockchain === "polygon":
      // This is an error, go back to the nft list
      return <Navigate replace to={NFT_LIST_ROUTE} />;

    default:
      return null;
  }
}
