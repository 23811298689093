import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";

type Props = {
  shouldRedirect: boolean;
};

export default function ClaimSuccessfulSubtitle({ shouldRedirect }: Props) {
  const strings = getLocalizedStrings("Modals", "Claim", useLanguage());
  return (
    <div>
      <h5 className="mt-2">{strings.itemInWallet}</h5>
      {shouldRedirect && <h5 className="mt-1">{strings.redirectMessage}</h5>}
    </div>
  );
}
