const MERCHANT_LIST = {
  Testnet: [{ id: "17", address: "" }],
  Mainnet: [{ id: "3", address: "" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "" },
  Mainnet: { id: 1, address: "" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 23,
};

export { MERCHANT_LIST, MINT_STORE_MARKET, WALLET_VIEWER_ID };
