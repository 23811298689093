import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../styles.module.scss";
import CollectiblesSection from "components/Widgets/Collectibles/CollectiblesSection";
import Collectible from "components/Widgets/Collectibles/Collectible";
import { Ruleset, Utility } from "types";
import { GATED_VIDEO_ROUTE, UTILITY_ROUTES } from "config/routes";
import { useNavigate } from "react-router-dom";
import { Lock } from "react-feather";
import ReactPlayer from "react-player";

type Props = {
  videos: Utility.Video[];
  rulesets: Ruleset.Validation[] | undefined;
  isOwnedByUser: any;
};

export default function Videos({ videos, rulesets }: Props) {
  const strings = getLocalizedStrings("Pages", "Utility", useLanguage());
  const navigate = useNavigate();

  return (
    <section className={styles.sectionContainer}>
      <CollectiblesSection
        title={strings.videos.title}
        description={strings.videos.description}
        displayShowAll={false}
        onShowAll={() => navigate(UTILITY_ROUTES.VIDEOS)}
      >
        <ReactPlayer
          url={
            "https://player.vimeo.com/video/778203426?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          }
          controls={true}
        />
      </CollectiblesSection>
    </section>
  );
}
