import { useLanguage } from "lib/contexts/LanguageContext";
import Modal from "components/Widgets/Modal";
import Button from "components/Widgets/Button";
import { getLocalizedStrings, Config } from "config";
import styles from "./marketing.module.scss";
import { AppContext } from "context/AppContext";
import { useContext } from "react";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";
import { authenticationAPI } from "lib/store/api/authentication-api";

export default function MarketingModal() {
  const strings = getLocalizedStrings("Modals", "Marketing", useLanguage());
  const appContext = useContext(AppContext) as AppContext;
  const user = useAppSelector(selectAppUser);
  const [optUserIn] = authenticationAPI.useOptUserInMutation();

  function clickHandler(clickType: string) {
    if (clickType === "skip") {
      sessionStorage.setItem("optIn", "skip");
    } else if (user) {
      if (clickType === "accept") {
        optUserIn({ id: user.id, isOptedOut: false });
      }
      if (clickType === "decline") {
        optUserIn({ id: user.id, isOptedOut: true });
      }
    }
    appContext.marketing.display.setIsVisible(false);
  }

  return (
    <>
      {Config.Client.MARKETING_OPT_IN &&
        user &&
        JSON.stringify(user.marketing) === "{}" &&
        sessionStorage.getItem("optIn") !== "skip" && (
          <Modal
            isVisible={appContext.marketing.display.isVisible}
            alignment={"left"}
            size={"fit"}
            clickOutsideToClose={false}
            onClose={() => {
              clickHandler("skip");
            }}
          >
            <h2>{strings.modalTitle}</h2>
            <p className="mt-2">{strings.marketingConsent}</p>
            <div className={styles.buttonRow}>
              <Button
                type="primary"
                onClick={() => {
                  clickHandler("accept");
                }}
              >
                {strings.accept}
              </Button>
              <Button
                type="secondary"
                onClick={() => {
                  clickHandler("decline");
                }}
              >
                {strings.decline}
              </Button>
              <Button
                type="secondary"
                onClick={() => {
                  clickHandler("skip");
                }}
              >
                {strings.skip}
              </Button>
            </div>
          </Modal>
        )}
    </>
  );
}
