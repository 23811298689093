const en = {
  allCollectible: "All Collectibles",
  anonymous: "Anonymous",
  onSale: "On Sale",
  collectibles: "collectibles",
  collectible: "collectible",
  error: "Error querying NFT",
  connectToBlocto: "Connect to Wallet",
  loadMoreBtnTxt: "Load more",
  claimed: "Blocto",
  notClaimed: "Unclaimed",
  noWalletConnected: "No wallet connected",
  balanceConnectedWallet: "Balance of connected wallet",
  legendaryChallenge: "Legendary Challenge",
  unnamedWallet: "Unnamed Wallet",
  challenge: {
    congratulations: "Congratulations, you have completed the",
    contributing: "The NFTs contributing to the challenge were the following:",
  },
  heading: {
    title: "Your NFTs",
    subtitle: "Manage your collectibles here!",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
