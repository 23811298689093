import * as Blockchain from "../../../global-blockchain-config";
import { SmartContract } from "types";

const MERCHANT_LIST = {
  Testnet: [
    { id: "1", address: "0x28492e97618732eb" },
    { id: "3", address: "0x28492e97618732eb" },
    { id: "5", address: "0x28492e97618732eb" },
    { id: "7", address: "0x28492e97618732eb" },
    { id: "9", address: "0x28492e97618732eb" },
    { id: "20", address: "0x28492e97618732eb" },
    { id: "21", address: "0x28492e97618732eb" },
    { id: "23", address: "0x28492e97618732eb" },
  ],
  Mainnet: [{ id: "3", address: "0xdac587e8f257a6f2" }],
};
const MINT_STORE_MARKET = {
  Testnet: { id: 0, address: "0x28492e97618732eb" },
  Mainnet: { id: 1, address: "0xdac587e8f257a6f2" },
};
const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 10,
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id: "7764d1a0-bf2e-4da1-9e57-ba7c208cd935",
    name: "MintStoreItem",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "MintStoreItemCollectionPublic",
    providerPath: "MintStoreItemCollection",
  },
  {
    id: "329814b5-4a7e-444e-9e77-b01edbdbd913",
    name: "Gamisodes",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "GamisodesCollectionPublic",
    providerPath: "GamisodesCollection",
  },
  {
    id: "013776a5-2677-478d-8f50-fa7c3fc65a5f",
    name: "LOSTiNTurismo",
    type: "PFP",
    scarcity: null,
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "LOSTiNTurismoCollectionPublic",
    providerPath: "LOSTiNTurismoCollection",
    attributes: [],
  },
  {
    id: "6f185b26-4f8d-475a-b5a5-6302e137959b",
    name: "YBees",
    type: "PFP",
    scarcity: null,
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "YBeesCollectionPublic",
    providerPath: "YBeesCollection",
    attributes: [],
  },
  {
    id: "74794c08-c063-40f2-8918-d761d14ef3d5",
    name: "YoungBoysBern",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "YoungBoysBernCollectionPublic",
    providerPath: "YoungBoysBernCollection",
  },
  {
    id: "948565ad-f560-47a2-8bab-38e337df3a3c",
    name: "OpenLockerIncBoneYardHuskyzClub",
    scarcity: null,
    type: "PFP",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath:
      "OpenLockerIncBoneYardHuskyzClubCollectionPublic",
    providerPath: "OpenLockerIncBoneYardHuskyzClubCollection",
    attributes: [],
  },
  {
    id: "3d745114-e74d-4f9c-8e77-75ab5f7f8df5",
    name: "OpenLockerInc",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "OpenLockerIncCollectionPublic",
    providerPath: "OpenLockerIncCollection",
  },
  {
    id: "ace1d98e-0449-4a41-a978-dcef9013cabc",
    name: "Pickem",
    type: "PFP",
    scarcity: null,
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "PickemCollectionPublic",
    providerPath: "PickemCollection",
    attributes: [],
  },
];

const DISCOVERY_WALLET = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  SMART_CONTRACTS,
  DISCOVERY_WALLET,
};
