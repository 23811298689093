import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const MERCHANT_LIST = {
  Testnet: [{ id: "10", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "79", address: "0x86dad8fab9647294" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 13, address: "0x86dad8fab9647294" },
};

const WALLET_VIEWER_ID = {
  Testnet: 3,
  Mainnet: 24,
};

const DISCOVERY_WALLET = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "586aa074-64d0-583a-823b-e7d36fa53bfe"
        : "329814b5-4a7e-444e-9e77-b01edbdbd913",
    name: "Gamisodes",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "GamisodesCollectionPublic",
    providerPath: "GamisodesCollection",
  },
];

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  DISCOVERY_WALLET,
  SMART_CONTRACTS,
};
