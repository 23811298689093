const en = {
  title: "No NFT.",
  subtitle: "",
  description:
    "You currently do not have any NFTs.\nStay tuned for subsequent releases.",
  noWalletTitle: "Welcome to the\n Wallet Viewer",
  noWalletDescription:
    "Complete your profile to purchase, sell and\ntransfer NFTs.",
  noWalletBtn: "Complete Profile",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
