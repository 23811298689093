const MERCHANT_LIST = {
  Testnet: [{ id: "1", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "7", address: "0xfc4b78e1076d4764" }],
};

// Playercon does not have a marketplace so the values for MINT_STORE_MARKET are placeholders

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 2, address: "0xfc4b78e1076d4764" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 15,
};

export { MERCHANT_LIST, MINT_STORE_MARKET, WALLET_VIEWER_ID };
