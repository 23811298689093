const en = {
  title: "Change Chain",
  body: "You must change chain to proceed the transaction.",
  btnTxt: "Switch Chains",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
