import LoginSrc from "../img/sheba-login.jpeg";
import LogoSvg from "../img/sheba-logo-white.svg";
import marsBrandingSrc from "../img/mars-branding.svg";
import CCPAOptOutSvg from "../img/CCPA-opt-out-icon.svg";
import { yearNow } from "utils/utils";
const LogoPng = LogoSvg;

const HTML_TITLE = "Virtual Reef Wallet";
const TEAM_CITY = "Virtual Reef Wallet";
const TEAM_NAME = "";
const COPYRIGHT = "Mars or affilates";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;
const CUSTOM_FOOTER = "sheba";

const NFT_NAME_CHARS_TO_REPLACE = "_";

export const FOOTER_COPYRIGHT = `Copyright © ${yearNow} Mars or affiliates. All rights reserved.`;
export const FOOTER_MARS_BRANDING = marsBrandingSrc;
export const FOOTER_MARS_BRANDING_TEXT = "Proudly part of Mars";

type NavItem = {
  title: string;
  link: string;
  icon?: string;
  iconAlt?: string;
};

export const FOOTER_LINKS: NavItem[] = [
  {
    title: "Privacy Statement",
    link: "https://www.mars.com/privacy",
  },
  {
    title: "Cookies Notice",
    link: "https://www.mars.com/cookies-english",
  },
  {
    title: "Legal",
    link: "https://www.mars.com/legal",
  },
  {
    title: "Accessibility",
    link: "https://www.mars.com/accessibility",
  },
  {
    title: "AdChoices",
    link: "https://www.mars.com/mars-incorporated-adchoices-united-states",
  },
  {
    title: "Contact Us",
    link: "https://www.shebahopegrows.com/contact-us",
  },
  {
    title: "CA Supply Chain Transparency Act",
    link: "https://www.mars.com/legal/ca-supply-chain-act",
  },
  {
    title: "Modern Slavery Act",
    link: "https://www.mars.com/modern-slavery-act",
  },
  {
    title: "Mars Supplier Program",
    link: "https://www.mars.com/about/policies-and-practices/next-generation-supplier-program",
  },
  {
    title: "Human Rights",
    link: "https://www.mars.com/about/policies-and-practices/human-rights-in-the-thai-fish-supply-chain",
  },
  {
    title: "Your Privacy Choices",
    link: "https://privacyportal-eu.onetrust.com/webform/5f2759ff-9efe-412c-a3be-f13092d2c945/98373dac-6247-48a6-a190-f0094af43ee8",
    icon: CCPAOptOutSvg,
    iconAlt: "California Consumer Privacy Act (CCPA) Opt-Out Icon",
  },
];

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
  HIDE_TRANSFER_BUTTON: true,
  CUSTOM_FOOTER,
  DISPLAY_SCARCITY: true,
  NFT_NAME_CHARS_TO_REPLACE,
};
