import { Chip, LoaderCircle } from "components";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent } from "react";
import { ArrowUpRight } from "react-feather";
import styles from "../ui/style.module.scss";

type Props = {
  contractAddressLink: string;
  blockChainName: SupportedBlockchain;
  transactionLink?: string | null;
  isLoading?: boolean;
  isError?: boolean;
};

export const TokenInWalletSection: FunctionComponent<Props> = ({
  contractAddressLink,
  blockChainName,
  transactionLink,
  isLoading,
  isError,
}) => {
  const detailsPageStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );

  return (
    <div className={styles.tokenInWalletSectionWrapper}>
      <Chip
        type="custom"
        className={`${styles.tokenInWalletPill} ${styles.successPill}`}
      >
        {detailsPageStrings.tokenInWallet}
      </Chip>

      <div className={styles.tokenInWalletLinkWrapper}>
        <a href={contractAddressLink} target="_blank">
          <span>{detailsPageStrings.contractAddress}</span>
        </a>
        <a href={contractAddressLink} target="_blank">
          <ArrowUpRight />
        </a>
      </div>
      {blockChainName === "flow" &&
        !isError &&
        transactionLink &&
        (!isLoading ? (
          <div className={styles.tokenInWalletLinkWrapper}>
            <a href={transactionLink} target="_blank">
              <span>{detailsPageStrings.flowScanTransaction}</span>
            </a>
            <a href={transactionLink} target="_blank">
              <ArrowUpRight />
            </a>
          </div>
        ) : (
          <LoaderCircle />
        ))}
    </div>
  );
};
