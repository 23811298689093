import React, {
  ChangeEventHandler,
  FocusEventHandler,
  FunctionComponent,
  KeyboardEvent,
  KeyboardEventHandler,
  useEffect,
  useState,
} from "react";
import style from "./style.module.scss";
import { Info } from "react-feather";
import { Config } from "config";

type Props = {
  code: string[];
  codeLength?: number;
  errorMsg?: string | null;
  errorComponent?: React.ReactNode;
  setCode: (value: React.SetStateAction<string[]>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
};

const CodeInput: FunctionComponent<Props> = ({
  code,
  setCode,
  codeLength = Config.Email.LOGIN_CODE_LENGTH,
  errorMsg,
  errorComponent,
  onKeyDown,
}: Props) => {
  const [hasError, setHasError] = useState<boolean>(
    !!errorMsg || !!errorComponent
  );

  useEffect(() => {
    setHasError(!!errorMsg || !!errorComponent);
  }, [errorMsg, errorComponent]);
  const onPaste: React.ClipboardEventHandler<HTMLInputElement> = (event) => {
    // Prevent onChange and set all inputs at once
    event.preventDefault();
    let value: string[] = Array.from(
      event.clipboardData.getData("Text")
    ).filter((num) => {
      return num === "" || Number.isInteger(Number.parseInt(num));
    });

    if (value.length > codeLength) {
      value = value.slice(0, codeLength);
    }
    if (value.length < codeLength) {
      const missingCodeLength = codeLength - value.length;
      for (let i = 0; i < missingCodeLength; i++) value.push("");
    }
    setCode(value);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const position = Number.parseInt(event.target.id);
    const value = event.target.value;

    // Sometimes the value contains the old and new one at the same time: find and set the new one
    setCode((current) => {
      const next = [...current];
      if (value == null || value.length === 0) {
        next[position] = "";
      } else if (value.length === 2) {
        const tempVal = value.slice(1, 2);
        event.target.nextSibling
          ? (next[position + 1] = tempVal)
          : (next[position] = tempVal);
      } else if (value.length > 2) {
        for (let i = 0; i < next.length; i++) {
          next[i] = value[i] ?? "";
        }
      } else {
        /* value.length === 1 */
        next[position] = value;
      }
      return next;
    });

    // Auto-focus to next input if completed or previous if deleted
    if (event.target.nextSibling && value != null && value.length > 0) {
      // @ts-ignore I don't understand why, but event.target.nextSibling and event.target.previousSibling
      // can be used as a HTMLInputElement, but are not typed as such...
      event.target.nextSibling.focus();
    } else if (!event.target.nextSibling && value != null && value.length > 0) {
      //next line is commented out as it doesn't allow you to click enter to submit the form
      //event.target.blur();
    } else if (
      event.target.previousSibling &&
      (value == null || value.length === 0)
    ) {
      // @ts-ignore same as above
      event.target.previousSibling.focus();
    }
  };

  const _onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    // Even if only numbers can be used, +, - and e are (but should not be) supported in a type="number" input
    if (
      [
        "+",
        "-",
        "e",
        "ArrowUp",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
      ].includes(event.key)
    ) {
      event.preventDefault();
    }

    // @ts-ignore I don't understand why, but event.target, event.target.nextSibling and event.target.previousSibling
    // can all be used as HTMLInputElements, but are not typed as such...
    if (event.key === "ArrowRight" && event.target.nextSibling) {
      // @ts-ignore same as above
      event.target.nextSibling.focus();
    } else if (
      (event.key === "ArrowLeft" ||
        // @ts-ignore same as above
        (event.key === "Backspace" && event.target.value === "")) &&
      // @ts-ignore same as above
      event.target.previousSibling
    ) {
      // @ts-ignore same as above
      event.target.previousSibling.focus();
    }
    if (onKeyDown) onKeyDown(event);
  };

  const onFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    event.target.select();
    if (hasError) {
      const emptyCodeInput: string[] = [];
      for (let i = 0; i < codeLength; i++) {
        emptyCodeInput.push("");
      }
      setCode(emptyCodeInput);
      setHasError(false);
    }
  };
  let inputClass = style.input;
  if (hasError) inputClass += ` ${style.error}`;
  return (
    <>
      <div className={style.code}>
        {code.map((value, index) => (
          <Input
            key={index}
            className={inputClass}
            id={index}
            value={value}
            onFocus={onFocus}
            onPaste={onPaste}
            onChange={onChange}
            onKeyDown={_onKeyDown}
          />
        ))}
      </div>

      {hasError &&
        (errorComponent
          ? errorComponent
          : !!errorMsg && (
              <div className={style.errorDetails}>
                <Info style={{ marginRight: "0.5rem" }} />
                <div className={style.errorText}>
                  <div className={style.message}>{errorMsg}</div>
                </div>
              </div>
            ))}
    </>
  );
};

type InputProps = {
  id: number;
  value: string;
  className?: string;
  onPaste: React.ClipboardEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onFocus: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
};

function Input({
  id,
  value,
  onPaste,
  onChange,
  onFocus,
  onKeyDown,
  className,
}: InputProps) {
  return (
    <input
      className={className}
      id={`${id}`}
      required
      autoFocus={id === 0}
      value={value}
      onPaste={onPaste}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      type="number"
      inputMode="numeric"
      min="0"
      max="9"
      maxLength={1}
      autoComplete="off"
      pattern="\d{1}"
    />
  );
}
export default CodeInput;
