const EMAIL_TEMPLATE = "get-password-white-with-login-link-de";
const TRANSFER_NFT_EMAIL_TEMPLATE = "send-gift-white";
const TRANSFER_NFT_CONFIRMATION_EMAIL_TEMPLATE = "send-gift-confirmation-white";
const LOGIN_CODE_LENGTH = 6;

export default {
  EMAIL_TEMPLATE,
  TRANSFER_NFT_CONFIRMATION_EMAIL_TEMPLATE,
  TRANSFER_NFT_EMAIL_TEMPLATE,
  LOGIN_CODE_LENGTH,
};
