const PRIVACY_POLICY = `
<p>
15. PRIVACY POLICY
Our Privacy Policy describes the ways we collect, use, store and disclose your personal information, and is hereby incorporated by this reference into these Terms. You agree to the collection, use, storage, and disclosure of your data in accordance with our Privacy Policy.
</p>
<p>
16. DISPUTE RESOLUTION; BINDING ARBITRATION
YOU ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.
YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY.
YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING, BUT NOT LIMITED TO, CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
(i) Binding Arbitration. All disputes arising out of or in connection with this contract, or in respect of any defined legal relationship associated therewith or derived therefrom, shall be referred to and finally resolved by arbitration under the Commercial Arbitration Rules of the American Arbitration Association (“AAA”).
The case shall be heard by one arbitrator, who shall be an executive with a company in the entertainment event production industry agreed upon by the parties, and will be conducted in English. The arbitrator will decide the substance of all claims in accordance with applicable law and will honor all claims of privilege recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different users but is bound by rulings in prior arbitrations involving the same user to the extent required by applicable law. The arbitrator’s award shall be final and binding and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.
As part of the arbitration, both you and the applicable Collectible Parties will have the opportunity for reasonable discovery of non-privileged information that is relevant to the claim. The arbitrator may award any individualized remedies that would be available in court. 
(ii) Arbitration Fees. Each party will cover its own fees and costs associated with the arbitration proceedings; however, if the arbitrator finds that you cannot afford to pay the fees and costs reasonably associated with the arbitration proceedings, we will pay them for you.
(iii) Award Enforcement. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. The parties agree that they will not appeal any arbitration decision to any court.
(iv) Our Equitable Remedies. Notwithstanding the foregoing, we may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction, and you agree that these Terms are specifically enforceable by us through injunctive relief and other equitable remedies without proof of monetary damages.
</p>
<p>
17. GENERAL
(i) Entire Agreement.  These Terms and our Privacy Policy constitute the entire legal agreement between you and us and will be deemed to be the final and integrated agreement between you and us and govern your access to and use of the App, and completely replace any prior or contemporaneous agreements between you and us related to your access to or use of the App, whether oral or written.
(ii) No Third-Party Beneficiaries. These Terms do not and are not intended to confer any rights or remedies upon any person or entity other than you
(iii) Interpretation. The language in these Terms will be interpreted as to its fair meaning, and not strictly for or against any party.
(iv) Severability. Should any part of these Terms be held invalid, illegal, void or unenforceable, that portion will deemed severed from these Terms and will not affect the validity or enforceability of the remaining provisions of these Terms.
(v) No Waivers. Our failure or delay to exercise or enforce any right or provision of these Terms will not constitute or be deemed a waiver of future exercise or enforcement of such right or provision. The waiver of any right or provision of these Terms will be effective only if in writing and signed for and on behalf of us by a duly authorized representative.
(vi) Governing Law. All matters arising out of or relating to these Terms will be governed by and construed in accordance with the laws of the State of New York without giving effect to any choice or conflict of law provision or rule.
(vii) Venue. Subject to Section 16 of these Terms, any legal action or proceeding arising under these Terms will be brought exclusively in Chicago, Illinois, and we and you irrevocably consent and attorn to the personal jurisdiction and venue there.
(viii) Notices. We may provide you with any notices (including, without limitation those regarding changes to these Terms) by email or postings on the App. By providing us with your email address, you consent to our using the email address to send you any notices. Notices sent by email will be effective when we send the email, and notices we provide by posting will be effective upon posting. It is your responsibility to keep your email address current.
(ix) Assignment. You may not assign any of your rights or obligations under these Terms, whether by operation of law or otherwise, without our prior written consent. We may assign our rights and obligations under these Terms in our sole discretion to an affiliate, or in connection with an acquisition, sale or merger.
</p>
`;

const TERMS_OF_SERVICE = `

<p>
お客様は、以下の各事項について確認及び同意します。
</p>


<p>
1. 

お客様が、この利用規約を読み、理解されたこと。
</p
<p>
2. 


お客様によるユニバーサル ミュージック グループまたはその関連会社（以下併せて「UMG」といいます）が保有または管理するコンテンツ（以下「UMGコンテンツ」といいます）へのアクセスは、認証されたNFTの保有者およびUMGが指定した地域においてのみ可能となること。

</p
<p>
3. 

お客様による、認証されたNFTの保有者としてのUMGコンテンツへのアクセスが独占的でないこと。

</p
<p>
4. 

お客様は、（このUMG利用規約に別段の定めがある場合を除き）NFTを保有することにより、NFTの保有期間に限り、このUMG利用規約の明示の条件に従って、UMGコンテンツにアクセスできること。また、お客様は、NFTを保有したとしても、UMGコンテンツまたはその一部の保有権限、著作権その他の知的財産権を付与されないこと。

</p
<p>
5. 

UMGは、①第三者が、関連するUMGコンテンツのコピーの作成またはダウンロードを行っていないこと（当該UMGコンテンツの無許諾でのリッピングまたはダウンロードを通じた行為を含みます）、および、②第三者が、当該UMGコンテンツを対象とする、または、これに関連する非代替性トークン（NFT）を販売しないことについて、表明、保証および誓約しないこと。

<p>UMGは、当該他の非代替性トークン（NFT）または当該UMGコンテンツについて対応する義務を負わず、かつ、対応しないことについて一切の責任を負わないこと。また、UMGは、上記により、UMGコンテンツの対象となった同じUMGコンテンツ、イベント、個人または財産に関連する、または、これらを対象とする他の非代替性トークン（NFT）その他のアイテムを販売することを制限されず、かつ、第三者にその販売を許諾することを制限されないこと。
</p>
</p
<p>
6. 

お客様が、お客様のアクセス権限が個別に認証された以降、UMGが指定したサイトを通じて、デジタル配信（以下「ストリーミング」といいます）によって、UMGコンテンツを視聴および閲覧する権利を有し、私的かつ非商業的な娯楽目的でのみ当該視聴および閲覧を行うこと。また、 お客様が、対象となるNFTを売却した時点において、UMGコンテンツについてアクセス、視聴および閲覧する権利を失うこと。

</p
<p>
7. 

お客様が、UMGコンテンツの再配布、複製、放送、実演、展示および二次的著作物の作成を行わず、また、アクセスまたは取得したUMGコンテンツを移転しないこと。

</p
<p>
8. 

お客様が、UMGコンテンツの保有または出所に関する表示を偽装または変更する目的を含め、理由の如何を問わず、UMGコンテンツを変更せず、また、変更を試みないこと。
</p
<p>
9. 

お客様が、理由の如何を問わず、リバースエンジニアリング、逆コンパイルおよび逆アセンブルを行わず、また、UMGコンテンツに適用されるセキュリティコンポーネント、利用のルールその他の保護手段を改ざんしないこと。  
</p
<p>
10. 

お客様が、UMGコンテンツについて、著作権その他の知的財産権を侵害するおそれのある利用を行わないこと。
</p
<p>
11. 

UMGおよびその関連会社が、UMGコンテンツに対する継続した、中断のない、または永続的なアクセスを保証しないこと。
</p


`;

export default { PRIVACY_POLICY, TERMS_OF_SERVICE };
