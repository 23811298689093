const en = {
  connectedDisconnectedStatusTxt: "Connected",
  setupDoneRequiredTxt: "Setup",
  mainWalletTxt: "Primary",
  unknownStatusTxt: "Unknown",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
