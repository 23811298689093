import Chip from "components/Widgets/Chip";
import { TableHeader } from "components/Widgets/Table";
import { Config, getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { formatUsdWithSymbol } from "utils/utils";

// FIXME: This feels a bit Bulls-specific. Should we have a section in the client config where
// this kind of behavior can be defined?
type Scarcity = "rare" | "legendary" | "iconic";

function getScarcityFromEditionNumber(editionNumber: string | null): Scarcity {
  if (editionNumber == null) return "rare";
  const editionNumberInt = Number.parseInt(editionNumber);
  if (editionNumberInt <= 6) return "legendary";
  else if (editionNumberInt < 30) return "iconic";
  else return "rare";
}

export const tableHeaders = (isMobile?: boolean) => {
  const localizedStrings = getLocalizedStrings(
    "Modals",
    "SelectEdition",
    useLanguage()
  );

  if (!isMobile) {
    return [
      {
        text: localizedStrings.price,
        value: "price",
        display: (price: number | null) => {
          return price ? formatUsdWithSymbol(price) : "";
        },
      },
      {
        text: localizedStrings.edition,
        value: "editionNumber",
        display: (editionNumber: string | null) => `# ${editionNumber}`,
      },
      Config.MarketPlace.scarcityType !== "unique" && {
        text: localizedStrings.scarcityTh,
        value: "editionNumber",
        display: (number: string | number | null) => {
          const scarcity = getScarcityFromEditionNumber(
            number?.toString() ?? null
          );
          return (
            <Chip className={scarcity} type="secondary">
              {localizedStrings[scarcity]}
            </Chip>
          );
        },
        cellClass: "pl-0",
      },
      { text: localizedStrings.ownerTh, value: "profileName" },
    ] as TableHeader<string | number | null>[];
  } else {
    return [
      { text: localizedStrings.ownerTh, value: "profileName" },
      {
        text: localizedStrings.edition,
        value: "editionNumber",
        display: (editionNumber: string | null) => `# ${editionNumber}`,
      },
      {
        text: localizedStrings.price,
        value: "price",
        display: (price: number | null) => {
          return price ? formatUsdWithSymbol(price) : "";
        },
      },
      Config.MarketPlace.scarcityType !== "unique" && {
        text: localizedStrings.scarcityTh,
        value: "editionNumber",
        display: (number: string | number | null) => {
          const scarcity = getScarcityFromEditionNumber(
            number?.toString() ?? null
          );
          return (
            <Chip className={scarcity} type="secondary">
              {localizedStrings[scarcity]}
            </Chip>
          );
        },
        cellClass: "pl-0",
      },
    ] as TableHeader<string | number | null>[];
  }
};
