const en = {
  transactionDeclinedError: "Transaction declined",
  systemError: "System Error - Please Try Again",
  unknownError: "Unknown error",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
