import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { CSSProperties, FunctionComponent } from "react";
import { ArrowLeft } from "react-feather";
import styles from "./styles.module.scss";

type Props = {
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
};

const GoBackBtn: FunctionComponent<Props> = ({ style, className, onClick }) => {
  const strings = getLocalizedStrings("Component", "GoBackBtn", useLanguage());
  const handleClick = () => {
    onClick ? onClick() : history.back();
  };

  const rootClasses = [styles.goBackBtn];
  if (className) rootClasses.push(className);

  return (
    <div
      className={rootClasses.join(" ")}
      style={style}
      onClick={() => handleClick()}
    >
      <ArrowLeft />
      <span>{strings.btnTxt}</span>
    </div>
  );
};

export default GoBackBtn;
