const en = {
  title: "OOPS!",
  description: "An unexpected error occured.",
  linkTxt: "Go back",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
