import { getLocalizedStrings } from "config";
import { GATED_PLAYLIST_ROUTE } from "config/routes";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useRulesets } from "lib/store/api/ruleset/hooks";
import { useUtilityPlaylists } from "lib/store/api/utility/hooks";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import UtilitySubpageUI, { Item } from "./ui";

const PlaylistsPage: FunctionComponent = () => {
  const strings = getLocalizedStrings("Pages", "Utility", useLanguage());
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const { data, total, isFetching, isError, rulesetIds } =
    useUtilityPlaylists(page);
  const { data: rulesets, isFetching: isRulesetsFetching } =
    useRulesets(rulesetIds);

  const items: Item[] | undefined = data.map((playlist) => {
    const ruleset = rulesets?.find((rs) => rs.id === playlist.rulesetId);
    const isAvailable = !!ruleset?.ruleSetResult.isValid;

    return {
      title: playlist.name ?? "",
      description: playlist.description ?? "",
      imageSrc: playlist.previewImage ?? "",
      onClick: () => {
        navigate(GATED_PLAYLIST_ROUTE.replace(":id", playlist.id.toString()));
      },
      isAvailable,
    };
  });

  return (
    <UtilitySubpageUI
      title={strings.playlists.title}
      description={strings.playlists.description}
      isFetching={isFetching || isRulesetsFetching}
      isError={isError}
      data={items}
      dataLength={data.length ?? 0}
      hasMore={total > (data.length ?? 0)}
      onLoadMore={() => setPage((p) => p + 1)}
    />
  );
};

export default PlaylistsPage;
