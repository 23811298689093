import * as fcl from "@blocto/fcl";
import * as t from "@onflow/types";
import axios from "axios";
import { Blockchain, Project } from "config";
import { BlockchainConfig } from "types/blockchain";
import feathersClient from "lib/feathers";
import { FlowTx, FlowScript } from "lib/blockchain/flow/cadence";
import { Builder } from "./builder";
import { Flow } from "types";
import {
  getSmartContractFromConfig,
  getNftCacheKey,
} from "../../../../utils/utils";

const FLOW_MAX_GAS_LIMIT = (Blockchain.NET_CONFIG as BlockchainConfig<"flow">)
  .FLOW_MAX_GAS_LIMIT;
const MARKET = Project.MINT_STORE_MARKET[Blockchain.NET];

export const flowOnchainActionsEndpoints = (builder: Builder) => ({
  transferNft: builder.mutation<
    null,
    {
      recipientAddress: string;
      itemFID: string;
      senderAddress?: string;
      contractAddress?: string;
      contractName?: string;
    }
  >({
    async queryFn({
      recipientAddress,
      senderAddress,
      itemFID,
      contractAddress,
      contractName,
    }) {
      if (senderAddress === recipientAddress) {
        throw new Error("You cannot transfer the NFT to the same wallet");
      }

      const smartContract = getSmartContractFromConfig(
        contractAddress,
        contractName
      );

      const isRecipientAccountSetup = await fcl
        .send([
          fcl.script(fcl.cdc`${FlowScript.isAccountSetup(smartContract)}`),
          fcl.args([fcl.arg(recipientAddress, t.Address)]),
        ])
        .then(fcl.decode);

      if (!isRecipientAccountSetup)
        throw new Error(
          `Recipient: ${recipientAddress} is not setup to receive ${contractName}`
        );

      const txId = await fcl.send([
        fcl.transaction(fcl.cdc`${FlowTx.transferNftScript(smartContract)}`),
        fcl.payer(fcl.authz),
        fcl.proposer(fcl.authz),
        fcl.authorizations([fcl.authz]),
        fcl.limit(FLOW_MAX_GAS_LIMIT),
        fcl.args([
          fcl.arg(recipientAddress, t.Address),
          fcl.arg(itemFID, t.UInt64),
        ]),
      ]);
      await fcl.tx(txId).onceSealed();

      return { data: null };
    },
    invalidatesTags: (_, __, requestArgs) => [getNftCacheKey(requestArgs)],
  }),

  purchaseNft: builder.mutation<null, Flow.FullNft>({
    async queryFn(nft) {
      const smartContract = getSmartContractFromConfig(
        nft?.smartContractAddress,
        nft?.smartContractName
      );
      const transactionPromise =
        Project.WALLET_TYPE.length === 1 && Project.WALLET_TYPE[0] === "Blocto"
          ? fcl.send([
              fcl.transaction(fcl.cdc`${FlowTx.Mint.purchaseScript}`),
              fcl.payer(fcl.authz),
              fcl.proposer(fcl.authz),
              fcl.authorizations([fcl.authz]),
              fcl.args([
                fcl.arg(nft.currentAddress, t.Address),
                fcl.arg(MARKET.address, t.Address),
                fcl.arg(nft.itemFID ?? "0", t.UInt64),
              ]),
              fcl.limit(FLOW_MAX_GAS_LIMIT),
            ])
          : fcl.send([
              fcl.transaction(
                fcl.cdc`${FlowTx.Dapper.purchaseStoreFrontScript(
                  smartContract
                )}`
              ),
              fcl.payer(fcl.authz),
              fcl.proposer(fcl.authz),
              fcl.authorizations([fcl.authz]),
              fcl.args([
                fcl.arg(nft.currentAddress, t.Address),
                fcl.arg(nft.saleInfo?.listingResourceID, t.UInt64),
                fcl.arg(nft.saleInfo?.price?.toFixed(2), t.UFix64),
              ]),
              fcl.limit(FLOW_MAX_GAS_LIMIT),
            ]);
      const txId = await transactionPromise;
      await fcl.tx(txId).onceSealed();
      return { data: null };
    },
    invalidatesTags: ["FlowMintStoreNft"],
  }),

  listForSale: builder.mutation<
    null,
    {
      itemFID: string;
      price: number;
      contractAddress?: string;
      contractName?: string;
    }
  >({
    async queryFn({ itemFID, price, contractAddress, contractName }) {
      const smartContract = getSmartContractFromConfig(
        contractAddress,
        contractName
      );
      const transactionPromise =
        Project.WALLET_TYPE.length === 1 && Project.WALLET_TYPE[0] === "Blocto"
          ? fcl.send([
              fcl.transaction(fcl.cdc`${FlowTx.Mint.listForSaleScript}`),
              fcl.payer(fcl.authz),
              fcl.proposer(fcl.authz),
              fcl.authorizations([fcl.authz]),
              fcl.limit(FLOW_MAX_GAS_LIMIT),
              fcl.args([
                fcl.arg(MARKET.address, t.Address),
                fcl.arg(itemFID, t.UInt64),
                fcl.arg(price.toFixed(2), t.UFix64),
              ]),
            ])
          : fcl.send([
              fcl.transaction(
                fcl.cdc`${FlowTx.Dapper.listForSaleStoreFront(smartContract)}`
              ),
              fcl.payer(fcl.authz),
              fcl.proposer(fcl.authz),
              fcl.authorizations([fcl.authz]),
              fcl.limit(FLOW_MAX_GAS_LIMIT),
              fcl.args([
                fcl.arg(itemFID, t.UInt64),
                fcl.arg(price.toFixed(2), t.UFix64),
              ]),
            ]);

      const txId = await transactionPromise;

      await fcl.tx(txId).onceSealed();
      return { data: null };
    },
    invalidatesTags: (_, __, requestArgs) => [getNftCacheKey(requestArgs)],
  }),

  cancelSale: builder.mutation<
    null,
    { itemFID: string; contractAddress?: string; contractName?: string }
  >({
    async queryFn({ itemFID, contractAddress, contractName }) {
      const smartContract = getSmartContractFromConfig(
        contractAddress,
        contractName
      );
      const transactionPromise =
        Project.WALLET_TYPE.length === 1 && Project.WALLET_TYPE[0] === "Blocto"
          ? fcl.send([
              fcl.transaction(fcl.cdc`${FlowTx.Mint.cancelSaleScript}`),
              fcl.payer(fcl.authz),
              fcl.proposer(fcl.authz),
              fcl.authorizations([fcl.authz]),
              fcl.limit(FLOW_MAX_GAS_LIMIT),
              fcl.args([
                fcl.arg(itemFID, t.UInt64),
                fcl.arg(MARKET.address, t.Address),
              ]),
            ])
          : fcl.send([
              fcl.transaction(
                fcl.cdc`${FlowTx.Dapper.delistStoreFront(smartContract)}`
              ),
              fcl.payer(fcl.authz),
              fcl.proposer(fcl.authz),
              fcl.authorizations([fcl.authz]),
              fcl.limit(FLOW_MAX_GAS_LIMIT),
              fcl.args([fcl.arg(itemFID, t.UInt64)]),
            ]);
      const txId = await transactionPromise;
      await fcl.tx(txId).onceSealed();
      return { data: null };
    },
    invalidatesTags: (_, __, requestArgs) => [getNftCacheKey(requestArgs)],
  }),

  claimNft: builder.mutation<
    null,
    { toAddress: string; nft: { id: number; itemFID: string } }
  >({
    async queryFn({ toAddress, nft }) {
      const { accessToken } = await feathersClient.get("authentication");
      await axios.post(
        `${Project.CLAIM_API_URL}/claim-nft`,
        {
          flowAddress: toAddress,
          purchasedNftId: nft.id,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      return { data: null };
    },
    invalidatesTags: ["FlowMintStoreNft"],
  }),
});
