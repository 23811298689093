import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const MERCHANT_LIST = {
  Testnet: [{ id: "18", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "1", address: "0x86dad8fab9647294" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 0, address: "0x28492e97618732eb" },
  Mainnet: { id: 13, address: "0x86dad8fab9647294" },
};

const WALLET_VIEWER_ID = {
  Testnet: 4,
  Mainnet: 28,
};

const DISCOVERY_WALLET = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "76f17a7b-c010-4f0f-a36c-0aa1b4a1aa00"
        : "013776a5-2677-478d-8f50-fa7c3fc65a5f",
    name: "LOSTiNTurismo",
    type: "PFP",
    scarcity: null,
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "LOSTiNTurismoCollectionPublic",
    providerPath: "LOSTiNTurismoCollection",
    attributes: [],
  },
];

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  DISCOVERY_WALLET,
  SMART_CONTRACTS,
};
