const en = {
  edition: "Edition",
  of: " of",
  claimed: "Claimed to Blocto Wallet",
  unclaimed: "Unclaimed",
  clickToClaim: "Click to Claim",
  forSale: "for sale",
  lowestPriceUnavailable: "Lowest price unavailable",
  listedFor: "Listed for",
  claimedTo: "Claimed to",
  unnamedWallet: "Unnamed Wallet",
  wallet: "Wallet",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
