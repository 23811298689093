import Dropdown from "components/Widgets/Dropdown";
import Option from "components/Widgets/Dropdown/Option/option";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useState } from "react";
import styles from "./custom.module.scss";

type SortBy = "editionNumber" | "price";
type SortOrder = "highest" | "lowest";
export type Sort = { by: SortBy; order: SortOrder };

type Props = {
  total: number;
  onSort: (sort: Sort) => void;
};

export function MobileSortHeader(props: Props) {
  const localizedStrings = getLocalizedStrings(
    "Modals",
    "SelectEdition",
    useLanguage()
  );
  const [sort, setSort] = useState<Sort>({ by: "price", order: "lowest" });

  function handleSortChange(newSort: Partial<Sort>) {
    const hasChanged = (field: "by" | "order") =>
      newSort[field] != null && newSort[field] !== sort[field];

    if (hasChanged("by") || hasChanged("order")) {
      setSort({ ...sort, ...newSort });

      props.onSort({ ...sort, ...newSort });
    }
  }

  return (
    <header>
      <div className={`${styles.sort} ${styles.mobileSort}`}>
        <Dropdown
          onSelect={(option) =>
            handleSortChange({
              order: option?.value.split(" ")[0] as SortOrder,
              by: option?.value.split(" ")[1] as SortBy,
            })
          }
          defaultSelection="lowest price"
          className={styles.dropdown}
          maxWidth="fit-content"
          fieldInputClassname={styles.selectEditionDropdown}
        >
          <Option
            value="lowest price"
            display={`${localizedStrings.price} ${localizedStrings.lowestPriceOption}`}
          />
          <Option
            value="highest price"
            display={`${localizedStrings.price} ${localizedStrings.highestPriceOption}`}
          />
          <Option
            value="lowest editionNumber"
            display={`${localizedStrings.edition} ${localizedStrings.lowestPriceOption}`}
          />
          <Option
            value="highest editionNumber"
            display={`${localizedStrings.edition} ${localizedStrings.highestPriceOption}`}
          />
        </Dropdown>
      </div>
    </header>
  );
}
