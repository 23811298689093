import { ClientConfig, Config, getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import NotFoundPage from "pages/NotFound/NotFoundPage";
import Loader from "components/Widgets/BigLoader";
import { Ruleset, Utility } from "types";
import { useRuleset } from "lib/store/api/ruleset/hooks";
import { utilityAPI } from "lib/store/api/utility";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import ProductUI from "./ui/ProductUI";
import { useGatedProductByHandle } from "lib/store/api/utility/hooks";

export type Props = {
  product: Utility.Product | undefined;
  ruleset: Ruleset.Rule | undefined;
  strings: ClientConfig["Strings"]["Pages"]["GatedPage"]["en"];
  minPrice: number | undefined;
};

const isDraftOrderSuccess = (
  obj:
    | { data: Utility.DraftOrder }
    | { error: FetchBaseQueryError | SerializedError }
): obj is { data: Utility.DraftOrder } => {
  return (obj as { data: Utility.DraftOrder }).data !== undefined;
};

const GatedItem: FunctionComponent = () => {
  const strings = getLocalizedStrings("Pages", "GatedPage", useLanguage());
  const { handle } = useParams();
  const {
    data: product,
    isLoading,
    isError,
  } = useGatedProductByHandle(handle ?? "");
  const [
    createDraftOrder,
    { isLoading: isDraftOrderLoading, isError: isDraftOrderError },
  ] = utilityAPI.useCreateDraftOrderMutation();

  const onPurchase = async (variant_id: string, quantity: number) => {
    const cart: { [key: string]: number } = {};
    cart[variant_id] = quantity;

    const response = await createDraftOrder({
      shop: Config.Utility.UTILITY_SHOPIFY_SHOP,
      collection_id: Config.Utility.UTILITY_SHOPIFY_COLLECTION_ID ?? "",
      cart,
    });

    if (isDraftOrderSuccess(response)) {
      window.location.href = response.data.invoice_url;
    }
  };

  const { data: ruleset } = useRuleset(product?.ruleSetId);

  let minPrice: number | null = null;
  product?.variants.map((variant: any) => {
    const variantPrice = parseFloat(variant.price);
    if (!minPrice || variantPrice < minPrice) {
      minPrice = variantPrice;
    }
  });

  if (isLoading) {
    return <Loader show={isLoading} />;
  }

  if (isError) {
    return <NotFoundPage />;
  }

  return (
    <ProductUI
      product={product}
      ruleset={ruleset}
      strings={strings}
      minPrice={minPrice ?? 0}
      onPurchase={onPurchase}
      isLoading={isDraftOrderLoading}
      isError={isDraftOrderError}
    />
  );
};

export default GatedItem;
