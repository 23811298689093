import { FunctionComponent, useState } from "react";
import { Props } from "..";
import parse from "html-react-parser";
import styles from "../styles.module.scss";
import { Lock, Maximize, Minimize } from "react-feather";
import Page from "components/Widgets/Page";
import { AnimatedNFT, Button, Modal } from "components";
import { formatUsdWithSymbol } from "utils/utils";
import LockedAlert from "components/AppComponent/LockedAlert";

type ProductProps = {
  onPurchase: (variant_id: string, quantity: number) => void;
  isLoading?: boolean;
  isError?: boolean;
} & Props;

const ProductUI: FunctionComponent<ProductProps> = ({
  product,
  ruleset,
  strings,
  minPrice,
  onPurchase,
  isLoading = false,
}) => {
  const [isFullscreen, setFullscreen] = useState(false);
  const variant = product?.variants[0];

  return (
    <>
      {!product?.isAvailable && <LockedAlert message={ruleset?.description} />}
      <Page className={styles.gatedItemPage}>
        <section className={styles.sectionImg}>
          <div className={styles.imgContainer}>
            <AnimatedNFT
              src={product?.featuredImage.url ?? ""}
              thumbnail={product?.featuredImage.url ?? ""}
              name={product?.featuredImage.altText ?? ""}
              fileType={"Image"}
              icon={<Maximize />}
              className={!product?.isAvailable ? styles.imageLocked : ""}
              onIconClick={() => setFullscreen((x) => !x)}
            >
              {!product?.isAvailable && (
                <>
                  <div className={styles.imgScrim}></div>
                  <div className={styles.imgLabel}>
                    <Lock size={10} /> {strings.locked}
                  </div>
                </>
              )}
            </AnimatedNFT>
          </div>
        </section>
        <section className={styles.sectionInfo}>
          <div className={styles.infoContainer}>
            <h1>{product?.title}</h1>
            <div className={styles.card}>
              <h2>{strings.info}</h2>
              <hr />
              {parse(product?.descriptionHtml ?? "")}
            </div>
            {!product?.isAvailable && (
              <div className={styles.card}>
                <h2>{strings.rules}</h2>
                <hr />
                <p>
                  {ruleset?.description ?? <i>{strings.noRulesDescription}</i>}
                </p>
              </div>
            )}
            <div className={styles.actionContainer}>
              {!!minPrice && (
                <h2>
                  {(product?.variants.length ?? 0) > 1 && (
                    <small>{strings.from}</small>
                  )}
                  <span>{formatUsdWithSymbol(minPrice)}</span>
                </h2>
              )}
              <Button
                type="primary"
                onClick={() => onPurchase((variant?.id ?? "").toString(), 1)}
                disabled={isLoading || !product?.isAvailable}
              >
                {strings.purchase}
              </Button>

              {isLoading && (
                <p className="mt-3 mb-0">Loading, please wait a moment...</p>
              )}

              {!product?.isAvailable && (
                <p className="mt-3 mb-0">
                  You do not have access to this product.
                </p>
              )}
            </div>
            {/* Ruleset Tree not ready yet - leave it hidden until then! */}
            {/* <hr />
            <div className={styles.rulesetTree}>
              <h2>{strings.rulesetTree}</h2>
            </div> */}
          </div>
        </section>

        {isFullscreen && (
          <Modal isVisible onClose={() => setFullscreen(false)} fullscreen>
            <AnimatedNFT
              src={product?.featuredImage.url ?? ""}
              thumbnail={product?.featuredImage.url ?? ""}
              name={product?.featuredImage.altText ?? ""}
              fileType={"Image"}
              icon={<Minimize />}
              className={!product?.isAvailable ? styles.imageLocked : ""}
              onIconClick={() => setFullscreen((x) => !x)}
            >
              {!product?.isAvailable && (
                <>
                  <div className={styles.imgScrim}></div>
                  <div className={styles.imgLabel}>
                    <Lock size={10} /> {strings.locked}
                  </div>
                </>
              )}
            </AnimatedNFT>
          </Modal>
        )}
      </Page>
    </>
  );
};

export default ProductUI;
