import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const MERCHANT_LIST = {
  Testnet: [
    { id: "37", address: "0x28492e97618732eb" },
    { id: "1", address: "0x28492e97618732eb" },
  ],
  Mainnet: [{ id: "71", address: "0x3744f2f82b3c0a79" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 11, address: "0x3744f2f82b3c0a79" },
};

const WALLET_VIEWER_ID = {
  Testnet: 26,
  Mainnet: 5,
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "786aa074-64d0-493a-823b-e7d36fa53bff"
        : "7764d1a0-bf2e-4da1-9e57-ba7c208cd935",
    name: "MintStoreItem",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "MintStoreItemCollectionPublic",
    providerPath: "MintStoreItemCollection",
  },
  {
    id:
      Blockchain.NET === "Testnet"
        ? "226abb86-b7d8-4502-ba63-cb5e2aa4a743"
        : "3d745114-e74d-4f9c-8e77-75ab5f7f8df5",
    name: "OwnmineSinclair",
    type: "PFP",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "OwnmineSinclairCollectionPublic",
    providerPath: "OwnmineSinclairCollection",
    scarcity: null,
    attributes: [],
  },
];

export { MERCHANT_LIST, MINT_STORE_MARKET, WALLET_VIEWER_ID, SMART_CONTRACTS };
