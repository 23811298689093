const en = {
  legendary: "Legendary",
  iconic: "Iconic",
  rare: "Rare",
  sellerHeaderTxt: "Seller",
  salePriceHeaderTxt: "Sale Price",
  datetime: "Datetime",
  editionNumberHeaderTxt: "Edition number",
  scarcityHeaderTxt: "Rarity",
  editionHeaderTxt: "Edition",
  transactionIdHeaderTxt: "TX",
  fullHistoryBtnTxt: "See full history",
  buyerHeaderTxt: "Buyer",
  recentSales: "Recent Sales",
  topSales: "Top Sales",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
