import { AppContext } from "context/AppContext";
import { useAppSelector } from "lib/store/hooks";
import { selectEthUser } from "lib/store/slices/user-slice";
import { useContext } from "react";
import { ChangeWalletButtonsUI } from "../ui/ChangeWalletButtonsUI";

export function ChangeWalletButtonsEthConnector({
  displayChangeWalletButton,
  displayConnectWallet,
  currentOwnerAddress,
  currentWalletAddress,
}: {
  displayChangeWalletButton: boolean;
  displayConnectWallet: boolean;
  currentOwnerAddress?: string;
  currentWalletAddress?: string;
}) {
  const appContext = useContext(AppContext) as AppContext;
  const ethUser = useAppSelector(selectEthUser);

  // TODO: find a way to monitor the blockchain status
  const isUnderMaintenance = false;

  const openSelectWallet = () => {
    appContext.selectWallet.callbacks.setOnClose(() => () => {
      appContext.selectWallet.display.setIsVisible(false);
    });
    appContext.selectWallet.callbacks.setOnSuccess(() => () => {
      appContext.selectWallet.display.setIsVisible(false);
    });
    appContext.selectWallet.display.setIsVisible(true);
  };

  return (
    <ChangeWalletButtonsUI
      isUnderMaintenance={isUnderMaintenance}
      onLogin={openSelectWallet}
      displayChangeWalletButton={displayChangeWalletButton}
      displayConnectWallet={displayConnectWallet}
      currentOwnerAddress={currentOwnerAddress}
      currentWalletAddress={currentWalletAddress}
      loading={ethUser?.loading}
    />
  );
}
