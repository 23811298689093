import { FC } from "react";
import { SmartContractWithSetup } from "types";
import { SetupStatus } from "../connectors/SetupWizardFlowConnector";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import Chip from "../../../../Widgets/Chip";

export const ConnectSmartContractChip: FC<{
  contract?: SmartContractWithSetup;
  onSetup: (smartContract: SmartContractWithSetup) => void;
  setupStatus?: SetupStatus;
  onClose?: () => void;
}> = ({ contract, onSetup, setupStatus, onClose }) => {
  const localizedStrings = getLocalizedStrings(
    "Modals",
    "Wizard",
    useLanguage()
  ).smartContract;
  const chipIsLoading = (item?: SmartContractWithSetup) => {
    return (
      setupStatus &&
      setupStatus.isLoading &&
      setupStatus.originalArgs?.smartContract.name === item?.name
    );
  };
  if (!contract) return null;
  return (
    <Chip
      className={"mt-4"}
      disabled={chipIsLoading(contract)}
      loading={chipIsLoading(contract)}
      type="primaryButton"
      onClick={!contract.isSetup ? () => onSetup(contract) : () => onClose?.()}
    >
      {contract.isSetup
        ? localizedStrings.closeBtn
        : chipIsLoading(contract)
        ? localizedStrings.connecting
        : localizedStrings.notConnected}
    </Chip>
  );
};
