const en = {
  title: "Connect a Wallet",
  body: "Choose a wallet provider to login with your wallet.",
  title2: "What is a Wallet?",
  description:
    "A Wallet sends, receives, stores, and displays digital assets. It can also be used as a login, so you don't have to create new accounts and passwords on every website.",
  getWallet: "Get a Wallet",
  learnMore: "Learn More",
  legal:
    "By connecting a wallet, you acknowledge and agree to the mint terms of service and privacy policy",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
