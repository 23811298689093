import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SortQueryObject } from "../../types/database/response";
import { ItemOption } from "../../components/Widgets/ExpansionPanel";
import { Project, getLocalizedStrings } from "../../config";
import { AvailableLanguage } from "lib/contexts/LanguageContext";

export type OnchainActionError = {
  errorMessage?: string | null;
  isDeclined: boolean;
};

export function parseOnchainActionError(
  error: FetchBaseQueryError | SerializedError | undefined,
  language: AvailableLanguage = "en"
): OnchainActionError {
  let errorMessage = null;
  let isDeclined = false;
  const localizedStrings = getLocalizedStrings("Component", "Error", language);

  if (error) {
    console.error(error);

    errorMessage =
      "message" in error ? error.message : localizedStrings.unknownError;

    isDeclined = false;

    errorMessage = isDeclined
      ? localizedStrings.transactionDeclinedError
      : localizedStrings.systemError;
  }

  return {
    errorMessage,
    isDeclined,
  };
}

export function getSortQueryObj(
  sort: NftSortingOptions | null
): SortQueryObject | undefined {
  if (!sort) return;
  const [field, value] = sort.split("_");
  return { [field]: value === "asc" ? 1 : 0 };
}
export function getFilterQueryObj(filters: ItemOption[], key: string) {
  return {
    [key]: filters.reduce(
      (prev: Record<string, { $in: string[] } | string[]>, current) => {
        const itemKey = current.inverted ? current.value : current.key;

        const value = current.inverted ? current.key : current.value;

        /**
         * This condition is for filter not associated to the attributes or have
         * only one depth level ex: scarcity
         */
        if (key === itemKey && (!prev.$in || Array.isArray(prev.$in))) {
          return !prev.$in
            ? { ...prev, $in: [value] }
            : { ...prev, $in: [...prev.$in, value] };
        }
        return !prev[itemKey]
          ? { ...prev, [itemKey]: { $in: [value] } }
          : {
              ...prev,
              [itemKey]: {
                $in: [...(prev[itemKey] as { $in: string[] }).$in, value],
              },
            };
      },
      {}
    ),
  };
}

export const isBlocto = (): boolean =>
  Project.WALLET_TYPE.length === 1 && Project.WALLET_TYPE[0] === "Blocto";
