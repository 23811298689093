import { SmartContract } from "../../../../../../types";

export const getTotalRoyaltyRateScript = (contract: SmartContract): any => `
    import ${contract.name} from ${contract.address}
    import MetadataViews from 0xMetadataViews

    pub fun main(addr: Address, tokenID: UInt64): UFix64 {
      let account =  getAccount(addr)
        .getCapability(${contract.name}.CollectionPublicPath)
        .borrow<&{${contract.name}.${contract.name}CollectionPublic}>()
        ?? panic("Cannot borrow a reference to the recipient's collection")

      let nft = account.borrow${contract.name}(id: tokenID) ?? panic("Cannot borrow item with ID: ".concat(tokenID.toString()));
      var totalRoyaltyCut = 0.0
        if nft.getViews().contains(Type<MetadataViews.Royalties>()) {
            let royaltiesRef = nft.resolveView(Type<MetadataViews.Royalties>())?? panic("Unable to retrieve the royalties")
            let royalties = (royaltiesRef as! MetadataViews.Royalties).getRoyalties()
            
            for royalty in royalties {
             totalRoyaltyCut = totalRoyaltyCut + royalty.cut
            }
        }
    return  totalRoyaltyCut  
}
`;
