import { FC } from "react";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "./styles.module.scss";
import { getLocalizedStrings } from "config";

type Props = {
  isActive?: boolean;
  onClick?: () => void;
};

const Switch: FC<Props> = ({ isActive = false, onClick }: Props) => {
  const strings = getLocalizedStrings(
    "Component",
    "ActiveSwitch",
    useLanguage()
  );

  return (
    <>
      <div className={styles.activeSwitch}>
        {isActive && <p className={styles.label}>{strings.active}</p>}
        <div
          className={styles.slider}
          data-active={isActive}
          onClick={onClick}
        />
      </div>
    </>
  );
};

export default Switch;
