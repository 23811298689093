const en = {
  title: "404",
  description: "Page not found",
  linkTxt: "Back to home",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
