import { useState } from "react";
import { Config, getLocalizedStrings } from "config";
import styles from "../common.module.scss";
import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import { useLanguage } from "lib/contexts/LanguageContext";
import Modal from "components/Widgets/Modal";

interface NftPreview {
  id?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
}

type Props = {
  onClose: () => void;
  nft: NftPreview;
  onMainButtonClick: () => void;
  queryResult: { isPurchaseLoading: boolean; isPurchaseError: boolean };
};

export default function PurchaseModal({
  nft,
  queryResult,
  onClose,
  onMainButtonClick,
}: Props) {
  const lang = useLanguage();
  const [localizedStrings] = useState(
    getLocalizedStrings("Modals", "Purchase", lang)
  );

  const { isPurchaseLoading, isPurchaseError } = queryResult;

  const redirectUponPurchase = () => {
    const redirectLink = Config.MarketPlace.redirectMarketplaceLink;

    redirectLink && Config.MarketPlace.redirectUponPurchase
      ? (location.href = `${Config.MarketPlace.redirectMarketplaceLink}`)
      : onClose();
  };

  const onActionClick = () => {
    const redirectLink = Config.MarketPlace.redirectMarketplaceLink;

    redirectLink && Config.MarketPlace.redirectUponPurchase
      ? (location.href = `${Config.MarketPlace.redirectMarketplaceLink}`)
      : onMainButtonClick();
  };

  if (isPurchaseLoading) {
    return (
      <Modal isVisible clickOutsideToClose={false} hideCloseButton>
        <h2>{localizedStrings.purchaseInProgressTitle}</h2>
        <p>
          {localizedStrings.purchaseInProgressSubtitle}
          <br />
          {localizedStrings.pleaseWait}
        </p>
        <div className={styles.smallNftCard}>
          <SmallNFTCard nft={nft} loading={isPurchaseLoading} />
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        isVisible
        onClose={isPurchaseError ? onClose : redirectUponPurchase}
        onActionClick={() => onActionClick()}
        btnLoading={isPurchaseLoading}
        customStrings={{
          actionBtn: localizedStrings.openWallet,
        }}
      >
        <h2>
          {isPurchaseError
            ? localizedStrings.purchasedFailedTitle
            : localizedStrings.congratulations}
        </h2>
        <p>
          {isPurchaseError
            ? localizedStrings.purchasedFailedSubtitle
            : localizedStrings.itemInWallet}
          {isPurchaseError && (
            <>
              <br />
              {localizedStrings.tryAgainLater}
            </>
          )}
        </p>
        <div className={styles.smallNftCard}>
          <SmallNFTCard nft={nft} success={!isPurchaseError} />
        </div>
      </Modal>
    );
  }
}
