import { FunctionComponent, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Config } from "config";
import { ArrowLeft } from "react-feather";
import GreetingStep from "./steps/Greeting";
import EmailStep from "./steps/Email";
import CodeStep from "./steps/Code";
import RedemptionCodeStep from "./steps/RedemptionCode";
import { Credentials } from "components/AppComponent/ActionModals/Login/types";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";

type PossibleStep = "greeting" | "email" | "code" | "redemptionCode";

const RedeemPage: FunctionComponent = () => {
  const user = useAppSelector(selectAppUser);

  const [step, setStep] = useState<PossibleStep>("greeting");
  const [_credentials, setCredentials] = useState<Credentials>();

  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (!!user && ["email", "code"].includes(step)) {
      setStep("redemptionCode");
    }
  }, [step]);

  const onBackClick = () => {
    switch (step) {
      case "email":
        setStep("greeting");
        break;
      case "code":
        setStep("email");
        break;
      case "redemptionCode":
        setStep("greeting");
        break;
    }
  };

  return (
    <div className={styles.redeemContainer}>
      <div className={styles.item}>
        <div
          className={styles.loginImageContainer}
          style={{
            backgroundImage: `url(${Config.Client.LOGIN_SRC})`,
          }}
        />
      </div>
      <div className={styles.item}>
        {!["greeting"].includes(step) && (
          <ArrowLeft
            onClick={() => onBackClick()}
            className={styles.icon}
            size={18}
          />
        )}
        <div className={styles.itemContainer}>
          {step === "greeting" && (
            <GreetingStep
              onActionClick={() =>
                !user ? setStep("email") : setStep("redemptionCode")
              }
            />
          )}
          {step === "email" && (
            <EmailStep
              email={email}
              setEmail={setEmail}
              onActionClick={() => {
                setStep("code");
              }}
              setCredentials={setCredentials}
            />
          )}
          {step === "code" && (
            <CodeStep
              email={email}
              onActionClick={() => (user ? setStep("redemptionCode") : null)}
              setCredentials={setCredentials}
              user={user}
            />
          )}
          {step === "redemptionCode" && <RedemptionCodeStep />}
        </div>
      </div>
    </div>
  );
};

export default RedeemPage;
