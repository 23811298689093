import { AvailableLanguage } from "lib/contexts/LanguageContext";
import * as Default from "./default";
import * as Global from "./global";
import * as Blockchain from "./global-blockchain-config";
import assert from "assert";
import _merge from "lodash.merge";

const CLIENT = require("./client").CLIENT;

export type ClientConfig = {
  Customizable: typeof Default.Customizable & typeof Global.Customizable;
  Project: typeof Default.Project & typeof Global.Customizable;
  Strings: typeof Default.Strings;
  Wallet: typeof Default.Wallets;
};

export type ScarcityType = "bundle" | "unique";

const forceRequireStrings = () => {
  try {
    return require(`./clients/${CLIENT}/strings`);
  } catch (e) {
    return {};
  }
};

const Client: ClientConfig = {
  Customizable: require(`./clients/${CLIENT}/customizable`),
  Project: require(`./clients/${CLIENT}/project`),
  // This will not fail if the client did not define strings to overwrite the defaults
  Strings: forceRequireStrings(),
  Wallet: require(`./clients/${CLIENT}/wallets`),
};

assert(Client.Customizable, `Not able to retrieve Config of ${CLIENT}`);
assert(Client.Project, `Not able to retrieve Project config of ${CLIENT}`);

// Taking default value if none provided in config
const Config = {
  ...Global.Customizable,
  ...Default.Customizable,
  ...Client.Customizable,
  Client: {
    ...Global.Customizable.Client,
    ...Default.Customizable.Client,
    ...Client.Customizable.Client,
  },
  Email: {
    ...Global.Customizable.Email,
    ...Default.Customizable.Email,
    ...Client.Customizable.Email,
  },
  MarketPlace: {
    ...Global.Customizable.MarketPlace,
    ...Default.Customizable.MarketPlace,
    ...Client.Customizable.MarketPlace,
  },
  SocialMedia: {
    ...Global.Customizable.SocialMedia,
    ...Default.Customizable.SocialMedia,
    ...Client.Customizable.SocialMedia,
  },
  TermsConditions: {
    ...Global.Customizable.TermsAndConditions,
    ...Default.Customizable.TermsAndConditions,
    ...Client.Customizable.TermsAndConditions,
  },
  Utility: {
    ...Default.Customizable.Utility,
    ...Client.Customizable.Utility,
  },
  AgeGate: {
    ...Default.Customizable.AgeGate,
    ...Client.Customizable.AgeGate,
  },
};
const Project = {
  ...Global.Project,
  ...Default.Project,
  ...Client.Project,
  ...Client.Wallet,
};

function moduleToObject<T>(module: T): T {
  return JSON.parse(JSON.stringify(module));
}

// Recursively merge strings, so that a client can only overwrite the strings they want to
const Strings = moduleToObject(Default.Strings);
_merge(Strings, moduleToObject(Client.Strings));

function getStrings<
  T extends keyof typeof Client.Strings,
  K extends keyof (typeof Client.Strings)[T]
>(module: T, component: K): (typeof Client.Strings)[T][K] {
  return Strings[module][component];
}

function getLocalizedStrings<
  T extends keyof typeof Client.Strings,
  K extends keyof (typeof Client.Strings)[T],
  X extends keyof (typeof Client.Strings)[T][K]
>(
  module: T,
  component: K,
  language: AvailableLanguage
): (typeof Client.Strings)[T][K][X] {
  const strings: (typeof Client.Strings)[T][K] = Strings[module][component];
  return (strings as any)[language] as (typeof Client.Strings)[T][K][X];
}

function hasUniqueWalletType(
  blockchain: SupportedBlockchain,
  walletType: WalletType
): boolean {
  if (Blockchain.BLOCKCHAIN_NAME === blockchain) {
    if (Project.WALLET_TYPE?.length === 1) {
      return (Project.WALLET_TYPE as WalletType[])[0] == walletType;
    }
  }
  return false;
}

function hasWalletType(
  blockchain: SupportedBlockchain,
  walletType: WalletType
): boolean {
  if (Blockchain.BLOCKCHAIN_NAME === blockchain) {
    return (Project.WALLET_TYPE as WalletType[]).includes(walletType);
  }
  return false;
}

function getFlowUniqueWalletType(): FlowWalletType | null {
  switch (Blockchain.BLOCKCHAIN_NAME) {
    case "flow":
      if (Project.WALLET_TYPE?.length === 1) {
        return (Project.WALLET_TYPE as WalletType[])[0] as FlowWalletType;
      }
      return null;
    default:
      return null;
  }
}

function getPolygonUniqueWalletType(): EthWalletType | null {
  switch (Blockchain.BLOCKCHAIN_NAME) {
    case "polygon":
      if (Project.WALLET_TYPE?.length === 1) {
        return (Project.WALLET_TYPE as WalletType[])[0] as EthWalletType;
      }
      return null;
    default:
      return null;
  }
}

export {
  Config,
  getStrings,
  getLocalizedStrings,
  hasUniqueWalletType,
  getFlowUniqueWalletType,
  getPolygonUniqueWalletType,
  hasWalletType,
  Blockchain,
  CLIENT,
  Project,
};
