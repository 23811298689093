const en = {
  email: {
    title: "Verify Email",
    subheader: [
      "Please enter the email you used to purchase items.",
      "After you verify your email, you can access all of your items.",
    ],
    inputPlaceholder: "Your email",
    btnTxt: "Send Verification Email",
  },
  code: {
    title: "Enter login code",
    subtitle: "Please enter the six-digit code that was sent to your email.",
    btnTxt: "Continue",
    backLinkText: "Use another account",
  },
  errors: {
    incompleteCode: "Your login code is incomplete",
    emailRequired: "Email is required",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
