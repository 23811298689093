const en = {
  nodataBtnText: "No Data Found",
  loadMoreBtnTxt: "Load more",
  marketPlaceTitle: "NFT Marketplace",
  noMoreResultTitle: "No more result available",
  noMoreSearchResultTitle: "No more search results for",
  noSearchResultTitle: "No search result for",
  noMoreResultSubtitle: "Comeback later for more NFTs",
  noMoreSearchSubtitle: "Try again with a different search term.",
  noMoreSearchBtnTxt: "Clear Search",
  noMoreResultBtnTxt: "Scroll to top",
  searchPlaceholder: "Search marketplace",
  newestOption: "Newest",
  highestPriceOption: "Highest price",
  lowestPriceOption: "Lowest price",
  connectWalletBtn: "Connect Wallet",
  notConnectedWarningTxt:
    "You must be connected to a wallet in order to purchase an NFT",
  challengeInfo: "Want to know more about the challenge ?",
  recentSalesBtnTxt: "Recent Sales",
  topSalesBtnTxt: "Top Sales",
  verified: "Verified",
  collectible: "Collectible",
  collectibles: "Collectibles",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
