import Alert from "components/Widgets/Alert";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";

type Props = {
  isUnderMaintenance: boolean;
  message?: string | null;
};

export function BlockchainMaintenanceBannerUI({
  isUnderMaintenance,
  message,
}: Props) {
  const localizedStrings = getLocalizedStrings("Pages", "App", useLanguage());
  if (isUnderMaintenance) {
    return (
      <Alert
        style={{
          borderRadius: "0px",
          borderLeft: "none",
          borderRight: "none",
          margin: 0,
        }}
        textAlign={"center"}
        type={"warning"}
      >
        {message ?? localizedStrings.blockchainNetworkStatus}
      </Alert>
    );
  } else {
    return null;
  }
}
