import Modal from "components/Widgets/Modal";
import { getLocalizedStrings } from "config";
import useIsMobile from "hooks/useIsMobile";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonTableRow from "../../../Widgets/SkeletonTableRow";
import DataTable from "../../../Widgets/Table";
import styles from "./custom.module.scss";
import { MobileSortHeader } from "./MobileSortHeader";
import { Sort, SortHeader } from "./SortHeader";

import { tableHeaders } from "./TableHeaders";
export interface ItemPreview {
  itemFID: string;
  editionNumber: string | number | null;
  price: number | null;
  profile: { profileName: string | null };
}

type Props<T> = {
  list: T[];
  total: number;
  isLoading: boolean;
  onLoadMore: () => void;
  onSort: (sort: Sort) => void;
  onSelect: (item: T) => void;
  onCancel: () => void;
};

const DUMMY_COUNT = 3;

export function SelectEditionModal<T extends ItemPreview>(props: Props<T>) {
  const localizedStrings = getLocalizedStrings(
    "Modals",
    "SelectEdition",
    useLanguage()
  );
  const [selected, setSelected] = useState<T>();
  const isMobile = useIsMobile();

  return (
    <Modal
      isVisible
      onClose={props.onCancel}
      size="lg"
      onActionClick={() => selected != null && props.onSelect(selected)}
      btnDisabled={selected == null}
      customStrings={{ actionBtn: localizedStrings.continue }}
    >
      <h2>{localizedStrings.title}</h2>
      <p>
        {props.total}{" "}
        {props.total === 1
          ? localizedStrings.itemAvailable
          : localizedStrings.itemsAvailable}
      </p>
      {isMobile ? (
        <MobileSortHeader total={props.total} onSort={props.onSort} />
      ) : (
        <SortHeader total={props.total} onSort={props.onSort} />
      )}

      <div id={styles.editionsScroll}>
        <InfiniteScroll
          next={props.onLoadMore}
          hasMore={props.total > props.list.length}
          dataLength={props.list.length}
          loader={<SkeletonTableRow count={DUMMY_COUNT} />}
          scrollableTarget={styles.editionsScroll}
        >
          <DataTable
            showSelect
            singleSelection
            noDataMsg={localizedStrings.noNftForSale}
            onSelection={(s) => {
              const nft = props.list.find((nft) => nft.itemFID === s[0]);
              if (nft) {
                setSelected(nft);
              }
            }}
            itemKey={"itemFID"}
            items={props.list}
            loading={props.isLoading}
            headers={tableHeaders(isMobile)}
            isEditionTable={true}
          />
        </InfiniteScroll>
      </div>
    </Modal>
  );
}
