import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const SERVER_API_URL =
  Blockchain.NET === "Testnet"
    ? "https://dev-testnet-api.mint.store"
    : "https://v2.api-wallet.mint.store";

const MERCHANT_LIST = {
  Testnet: [{ id: "1", address: "0x28492e97618732eb" }],
  Mainnet: [
    { id: "6", address: "0xced4e52ed75eef69" },
    { id: "9", address: "0xced4e52ed75eef69" },
    { id: "10", address: "0xced4e52ed75eef69" },
    { id: "11", address: "0xced4e52ed75eef69" },
    { id: "12", address: "0xced4e52ed75eef69" },
    { id: "13", address: "0xced4e52ed75eef69" },
    { id: "14", address: "0xced4e52ed75eef69" },
    { id: "15", address: "0xced4e52ed75eef69" },
    { id: "16", address: "0xced4e52ed75eef69" },
    { id: "17", address: "0xced4e52ed75eef69" },
    { id: "18", address: "0xced4e52ed75eef69" },
    { id: "19", address: "0xced4e52ed75eef69" },
    { id: "20", address: "0xced4e52ed75eef69" },
    { id: "21", address: "0xced4e52ed75eef69" },
    { id: "22", address: "0xced4e52ed75eef69" },
    { id: "23", address: "0xced4e52ed75eef69" },
    { id: "24", address: "0xced4e52ed75eef69" },
    { id: "25", address: "0xced4e52ed75eef69" },
    { id: "26", address: "0xced4e52ed75eef69" },
    { id: "28", address: "0xced4e52ed75eef69" },
    { id: "29", address: "0xced4e52ed75eef69" },
    { id: "30", address: "0xced4e52ed75eef69" },
    { id: "32", address: "0xced4e52ed75eef69" },
    { id: "33", address: "0xced4e52ed75eef69" },
    { id: "34", address: "0xced4e52ed75eef69" },
    { id: "35", address: "0xced4e52ed75eef69" },
    { id: "37", address: "0xced4e52ed75eef69" },
    { id: "40", address: "0xced4e52ed75eef69" },
    { id: "41", address: "0xced4e52ed75eef69" },
    { id: "42", address: "0xced4e52ed75eef69" },
    { id: "43", address: "0xced4e52ed75eef69" },
    { id: "44", address: "0xced4e52ed75eef69" },
    { id: "45", address: "0xced4e52ed75eef69" },
    { id: "46", address: "0xced4e52ed75eef69" },
    { id: "47", address: "0xced4e52ed75eef69" },
    { id: "48", address: "0xced4e52ed75eef69" },
    { id: "49", address: "0xced4e52ed75eef69" },
    { id: "50", address: "0xced4e52ed75eef69" },
    { id: "51", address: "0xced4e52ed75eef69" },
    { id: "52", address: "0xced4e52ed75eef69" },
    { id: "53", address: "0xced4e52ed75eef69" },
    { id: "54", address: "0xced4e52ed75eef69" },
    { id: "55", address: "0xced4e52ed75eef69" },
    { id: "56", address: "0xced4e52ed75eef69" },
    { id: "57", address: "0xced4e52ed75eef69" },
    { id: "58", address: "0xced4e52ed75eef69" },
    { id: "59", address: "0xced4e52ed75eef69" },
    { id: "60", address: "0xced4e52ed75eef69" },
    { id: "61", address: "0xced4e52ed75eef69" },
    { id: "62", address: "00xced4e52ed75eef69x" },
    { id: "63", address: "0xced4e52ed75eef69" },
    { id: "64", address: "0xced4e52ed75eef69" },
    { id: "65", address: "0xced4e52ed75eef69" },
    { id: "66", address: "0xced4e52ed75eef69" },
    { id: "67", address: "0xced4e52ed75eef69" },
    { id: "68", address: "0xced4e52ed75eef69" },
    { id: "69", address: "0xced4e52ed75eef69" },
    { id: "70", address: "0xced4e52ed75eef69" },
    // { id: "71", address: "0xced4e52ed75eef69" }, // ownmine
  ],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 9, address: "0xced4e52ed75eef69" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 8,
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "786aa074-64d0-493a-823b-e7d36fa53bff"
        : "7764d1a0-bf2e-4da1-9e57-ba7c208cd935",
    name: "MintStoreItem",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "MintStoreItemCollectionPublic",
    providerPath: "MintStoreItemCollection",
  },
];

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  SERVER_API_URL,
  SMART_CONTRACTS,
};
