import { AppContext } from "context/AppContext";
import { flowAPI } from "lib/store/api/flow";
import { useAppSelector } from "lib/store/hooks";
import { selectFlowUser } from "lib/store/slices/user-slice";
import { useContext } from "react";
import { ChangeWalletButtonsUI } from "../ui/ChangeWalletButtonsUI";

export function ChangeWalletButtonsFlowConnector({
  displayChangeWalletButton,
  displayConnectWallet,
  currentOwnerAddress,
  currentWalletAddress,
  customPickemInnerText,
}: {
  displayChangeWalletButton: boolean;
  displayConnectWallet: boolean;
  currentOwnerAddress?: string;
  currentWalletAddress?: string;
  customPickemInnerText?: string;
}) {
  const { data: status } = flowAPI.useGetBlockchainStatusQuery();
  const appContext = useContext(AppContext) as AppContext;
  const flowUser = useAppSelector(selectFlowUser);

  const openSelectWallet = () => {
    appContext.selectWallet.callbacks.setOnClose(() => () => {
      appContext.selectWallet.display.setIsVisible(false);
    });
    appContext.selectWallet.callbacks.setOnSuccess(() => () => {
      appContext.selectWallet.display.setIsVisible(false);
    });
    appContext.selectWallet.display.setIsVisible(true);
  };

  return (
    <ChangeWalletButtonsUI
      isUnderMaintenance={status?.isBlockchainUnderMaintenance ?? false}
      onLogin={openSelectWallet}
      displayChangeWalletButton={displayChangeWalletButton}
      displayConnectWallet={displayConnectWallet}
      currentOwnerAddress={currentOwnerAddress}
      currentWalletAddress={currentWalletAddress}
      customPickemInnerText={customPickemInnerText}
      loading={flowUser?.loading}
    />
  );
}
