import { Button } from "components";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { connectingWalletProvider } from "lib/store/api/eth/web3";
import styles from "../style.module.scss";

type Props = {
  isUnderMaintenance: boolean;
  onLogin: () => void;
  displayChangeWalletButton: boolean;
  displayConnectWallet: boolean;
  currentOwnerAddress?: string;
  currentWalletAddress?: string;
  customPickemInnerText?: string;
  loading?: boolean;
};

export function ChangeWalletButtonsUI(props: Props) {
  const localizedStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );
  const disabled = props.isUnderMaintenance || props.loading;
  const customPickemInnerText = props.customPickemInnerText;

  if (props.displayConnectWallet && !props.currentWalletAddress) {
    return (
      <Button
        disabled={disabled}
        type={customPickemInnerText ? "primary" : "secondary"}
        onClick={props.onLogin}
        loading={props.loading}
      >
        {customPickemInnerText
          ? customPickemInnerText
          : localizedStrings.connectWallet}
      </Button>
    );
  } else if (props.displayChangeWalletButton) {
    return (
      <div className={styles.warningContainer}>
        <p>{localizedStrings.walletIsNotOwner}</p>
        <p>
          {localizedStrings.connected}: {props.currentWalletAddress}
        </p>
        <p>{`${localizedStrings.owner}: ${props.currentOwnerAddress}`} </p>
        {connectingWalletProvider !== "MetaMask" && (
          <Button
            type="secondary"
            disabled={disabled}
            onClick={props.onLogin}
            loading={props.loading}
          >
            {localizedStrings.changeWallet}
          </Button>
        )}
      </div>
    );
  } else {
    return null;
  }
}
