import { NFT_ON_DEMAND_SHOW, NFT_SHOW } from "config/routes";
import { useNavigate, generatePath } from "react-router-dom";
import { withPrefix } from "@blocto/fcl";
import { useAppSelector } from "../lib/store/hooks";
import { selectAppUser } from "../lib/store/slices/user-slice";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppNavigation = () => {
  const navigate = useNavigate();
  const user = useAppSelector(selectAppUser);
  const path = `${!user ? "/public" : ""}${NFT_SHOW}`;
  const nftOnDemandPath = NFT_ON_DEMAND_SHOW;

  const goToFlowNftPage = (nft: {
    itemFID: string;
    smartContractAddress: string;
    smartContractName: string;
    uuid?: string;
  }) => {
    const { smartContractName, smartContractAddress, itemFID, uuid } = nft;

    if (itemFID) {
      navigate(
        generatePath(path, {
          smartContractAddress: withPrefix(smartContractAddress),
          smartContractName,
          itemFID,
        })
      );
    } else if (!itemFID) {
      uuid &&
        navigate(
          generatePath(nftOnDemandPath, {
            smartContractAddress: withPrefix(smartContractAddress),
            smartContractName,
            uuid,
          })
        );
    }
  };

  return { goToFlowNftPage };
};
