import { ReactNode } from "react";
import styles from "components/Widgets/Table/style.module.scss";
import Chip from "components/Widgets/Chip";
import { SetupStatus } from "components/AppComponent/ActionModals/SetupWizard/connectors/SetupWizardFlowConnector";

type ConnectButtonText = {
  loadingText: string;
  notLoadingText: string;
};

export type TableHeader<T> = {
  text: string;
  value: string;
  align?: "start" | "center" | "end";
  display: (value: T) => ReactNode;
  connectChip?: {
    setupStatus: SetupStatus | null;
    text: ConnectButtonText;
    setup: (value: T) => void;
  };
};

export type TableProps<T> = {
  headers: TableHeader<T>[];
  items: T[];
  hoverEffect?: boolean;
};

function SmartContractDataTable<T extends { name: string; isSetup: boolean }>({
  headers,
  items,
  hoverEffect = true,
}: TableProps<T>) {
  const connectChipLoading = (header: TableHeader<T>, item: T) => {
    const setupStatus = header?.connectChip?.setupStatus;
    const isLoading = setupStatus?.isLoading;
    const smartContractLoading =
      setupStatus?.originalArgs?.smartContract.name === item.name;
    return Boolean(isLoading && setupStatus && smartContractLoading);
  };

  const connectChipText = (header: TableHeader<T>, item: T) =>
    connectChipLoading(header, item)
      ? header.connectChip?.text.loadingText
      : header.connectChip?.text.notLoadingText;

  return (
    <div className={`${styles.dataTable} ${hoverEffect && styles.hoverEffect}`}>
      <div
        className={`${styles.tableWrapper} ${styles.smartContractTableWrapper}`}
      >
        <table className={styles.table}>
          <colgroup>
            {headers.map((header, colGroupIdx) => (
              <col key={`colgroup-${colGroupIdx}`} />
            ))}
          </colgroup>
          <tbody>
            {items.map((item, index) => (
              <tr key={`item-${item.name}-${index}`}>
                {headers.map((header, headerIdx) => (
                  <td key={`data-${index}-${headerIdx}-${header.value}`}>
                    <span>{header.display(item)}</span>

                    {!item.isSetup && header.connectChip && (
                      <Chip
                        disabled={
                          header.connectChip.setupStatus?.isLoading &&
                          !connectChipLoading(header, item)
                        }
                        loading={connectChipLoading(header, item)}
                        type="primaryButton"
                        onClick={() => {
                          header.connectChip?.setup(item);
                        }}
                      >
                        {connectChipText(header, item)}
                      </Chip>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SmartContractDataTable;
