import { UtilityItem } from "pages/Utility";
import CustomBannerImage from "../img/header-banner-utility.png";

const HAS_UTILITY_PAGE = true;

// Banner image links
const UTILITY_BANNER = CustomBannerImage;
const UTILITY_BANNER_PLAYLIST =
  "https://source.unsplash.com/random/1280x720/?music";
const UTILITY_BANNER_VIDEO =
  "https://source.unsplash.com/random/1280x720/?cinema";
const UTILITY_BANNER_FILE =
  "https://source.unsplash.com/random/1280x720/?folders";

const UTILITY_SECTIONS: UtilitySection[] = ["videos"];

const UTILITY_DISCORD: UtilityItem[] = [];

export default {
  HAS_UTILITY_PAGE,
  UTILITY_BANNER,
  UTILITY_BANNER_PLAYLIST,
  UTILITY_BANNER_VIDEO,
  UTILITY_BANNER_FILE,
  UTILITY_SECTIONS,
  UTILITY_DISCORD,
  HIDE_FILE_DESCRIPTION: true,
};
