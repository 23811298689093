import { useEffect, useState } from "react";

export default function useIsMobile(isHeader?: boolean): boolean | undefined {
  const [isMobile, setIsMobile] = useState<boolean | undefined>();

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      isHeader
        ? setIsMobile(window.innerWidth < 992)
        : setIsMobile(window.innerWidth < 760);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return isMobile;
}
