import * as fcl from "@blocto/fcl";
import * as t from "@onflow/types";
import { Blockchain, Project } from "config";
import feathersClient, { NftService } from "lib/feathers";
import { FlowScript } from "lib/blockchain/flow/cadence";
import {
  RedemptionCodeData,
  UserRedemptionStatusData,
  Flow,
  Response,
} from "types";
import { Builder } from "./builder";
import {
  getSmartContractFromConfig,
  isDefined,
  getNftCacheKey,
} from "utils/utils";
import { Paginated } from "lib/store/hooks";
import { Sort } from "components/AppComponent/ActionModals/SelectEdition/SortHeader";
import axios from "axios";
import { FindQuery } from "types/database/response";
import { getSortQueryObj, isBlocto, getFilterQueryObj } from "../../utils";
import { FullNft, UrlPayload } from "types/app-model/flow";
import { getLowestPriceForEdition } from "./customize/nft-endpoints/getLowestPriceForEdition";
import { PfpFilters } from "types/app-model/global";

const MERCHANT_IDS = Project.MERCHANT_LIST[Blockchain.NET].map((x) => x.id);
const MARKET = Project.MINT_STORE_MARKET[Blockchain.NET];
const WALLET_VIEWER_ID = Project.WALLET_VIEWER_ID[Blockchain.NET];

export const flowNftEndpoints = (builder: Builder) => ({
  /***
   * This will allow dev to use the same method based on query provided in the code instead of always creating a new
   * method with specific params
   */
  getNfts: builder.query<
    Paginated<Flow.PurchasedNft>,
    {
      skip?: number;
      limit?: number;
      query?: FindQuery<
        Flow.PurchasedNft & {
          walletAddresses?: string[] | string;
          type?: "claimed" | "all" | "for-sale" | "purchased";
        }
      >;
    }
  >({
    async queryFn({ limit, skip, query }) {
      const { data, total } = await feathersClient
        .service("purchased-nft")
        .find({
          query: {
            $skip: skip ?? 0,
            $limit: limit ?? 10,
            ...query,
            merchantFID: MERCHANT_IDS,
          },
        });
      return { data: { list: data, total } };
    },
  }),
  getPaginatedPurchasedNftList: builder.query<
    Paginated<Flow.PurchasedNft>,
    { page: number; limit?: number }
  >({
    async queryFn({ page, limit }) {
      const { data, total } = await feathersClient
        .service("purchased-nft")
        .find({
          query: {
            $skip: page * (limit ?? 10),
            $limit: limit,
            merchantFID: MERCHANT_IDS,
            mintingStatus: [
              "MINTED",
              "PURCHASED",
              "CLAIM_PROCESSING",
              "CLAIM_PROCESSING_ERROR",
              "MINT_ON_CLAIM_READY",
              "MINT_ON_CLAIM_PROCESSING",
              "MINT_ON_CLAIM_PROCESSING_ERROR",
            ],
          },
        });
      return { data: { list: data, total } };
    },
    providesTags: (result) =>
      result ? result.list.map(getNftCacheKey) : ["FlowMintStoreNft"],
  }),
  getPaginatedItemForSaleList: builder.query<
    Paginated<Flow.ItemForSale>,
    {
      page: number;
      $limit?: number;
      sort: NftSortingOptions | null;
      selectedFilters: PfpFilters;
      query: FindQuery<Flow.ItemForSale>;
    }
  >({
    async queryFn({ page, sort, $limit = 10, query, selectedFilters }) {
      const { data, total } = await feathersClient
        .service("item-for-sale")
        .find({
          query: {
            $limit,
            $skip: page * $limit,
            $sort: getSortQueryObj(sort),
            $associateProfiles: true,
            ...query,
            nft: {
              ...query.nft,
              ...getFilterQueryObj(selectedFilters.attributes, "attributes"),
              ...getFilterQueryObj(selectedFilters.scarcity, "scarcity"),
            },
            merchantFID: MERCHANT_IDS,
          },
        });

      const dataWithFlattenedProfiles = data.map((item) => ({
        ...item,
        ...item.profile,
      }));
      return { data: { list: dataWithFlattenedProfiles, total } };
    },
    providesTags: () => [
      {
        type: "ItemForSaleList",
      },
      "ItemForSaleList",
    ],
  }),

  getClaimedNftList: builder.query<
    Flow.AppNftResult[],
    { wallet: Flow.Wallet }
  >({
    async queryFn({ wallet }) {
      const fetchAccountNfts = FlowScript.getAccountNfts.map(async (script) => {
        return await fcl
          .send([
            fcl.script(fcl.cdc`${script}`),
            fcl.args([
              fcl.arg(wallet.address, t.Address),
              fcl.arg(MARKET.address, t.Address),
              fcl.arg(null, t.Optional(t.UInt64)),
            ]),
          ])
          .then(fcl.decode);
      });

      const nftMaps: Flow.BlockchainNftResult[][] = await Promise.all(
        fetchAccountNfts
      );

      const buildUrl = (nft: Flow.BlockchainNftResult) => {
        if (nft.thumbnail.path) {
          return "https://ipfs.io/ipfs/"
            .concat(nft.thumbnail.cid)
            .concat("/")
            .concat(nft.thumbnail.path);
        } else {
          return "https://ipfs.io/ipfs/".concat(nft.thumbnail.cid);
        }
      };

      // Find all relevant data about a wallet's NFTs (both on-chain and off-chain)
      const nftList: Flow.AppNftResult[] = nftMaps.flat().map((nft) => ({
        ...nft,
        wallet,
        id: Number(nft.id),
        itemFID: String(nft.id),
        price: Number(nft.price) || null,
        thumbnail: nft.thumbnail.url ? nft.thumbnail.url : buildUrl(nft),
        editionNumber: nft.editionNumber,
        merchantID: Number(nft.merchantID),
        name: nft.name,
        description: nft.description,
        printingLimit: Number(nft.printingLimit),
      }));

      const validNftList = nftList.filter(
        (item) =>
          item.merchantID != null &&
          MERCHANT_IDS.indexOf(item.merchantID.toString()) !== -1
      );

      return { data: validNftList };
    },

    providesTags: (result) =>
      result ? result.map(getNftCacheKey) : ["FlowMintStoreNft"],
  }),

  getFullNft: builder.query<Flow.FullNft, Flow.UrlPayload>({
    async queryFn({ itemFID, contractAddress, contractName, uuid }) {
      const service = !itemFID
        ? (feathersClient.service(`nft`) as NftService)
        : (feathersClient.service(
            `nft/${fcl.sansPrefix(contractAddress)}/${contractName}`
          ) as NftService);

      const contractType = getSmartContractFromConfig(
        contractAddress,
        contractName
      );

      const data =
        !itemFID && uuid
          ? await service.get(uuid, {
              query: {},
            })
          : await service.get(itemFID, {
              query: {
                smartContractAddress: fcl.sansPrefix(contractAddress),
                smartContractName: contractName,
              },
            });

      return {
        data: {
          ...data,
          contractType: contractType.type,
        },
      };
    },
    providesTags: (result) => [
      result ? getNftCacheKey(result) : "FlowMintStoreNft",
    ],
  }),

  getFees: builder.query<number, UrlPayload & { ownerAddress: string }>({
    async queryFn({ itemFID, contractName, contractAddress, ownerAddress }) {
      const smartContract = getSmartContractFromConfig(
        contractAddress,
        contractName
      );
      // Once MintStoreItem implement the
      // MetadaViews.Royalties we won't need that if/else logic

      const fees = isBlocto()
        ? await fcl
            .send([
              fcl.script(fcl.cdc`${FlowScript.Mint.getTotalRoyaltyRateScript}`),
              fcl.args([fcl.arg(MARKET.address, t.Address)]),
            ])
            .then(fcl.decode)
        : await fcl
            .send([
              fcl.script(
                fcl.cdc`${FlowScript.Dapper.getTotalRoyaltyRateScript(
                  smartContract
                )}`
              ),
              fcl.args([
                fcl.arg(ownerAddress, fcl.t.Address),
                fcl.arg(itemFID, fcl.t.UInt64),
              ]),
            ])
            .then(fcl.decode);

      return { data: Number.parseFloat(fees) };
    },
  }),

  getPaginatedMerchants: builder.query<
    Paginated<Flow.Merchant>,
    { page: number; merchantFID?: string; profileName?: string }
  >({
    async queryFn({ page, merchantFID, profileName }) {
      const { data, total } = await feathersClient.service("merchants").find({
        query: {
          $skip: page * 10,
          $limit: 10,
          // If the list is filtered by merchantFID, make sure it is one available to this platform
          merchantFID:
            merchantFID != null && MERCHANT_IDS.includes(merchantFID)
              ? merchantFID
              : MERCHANT_IDS,
          profileName:
            profileName != null ? { $iLike: `%${profileName}%` } : undefined,
        },
      });
      return { data: { list: data, total } };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.list.map(({ id }) => ({
              type: "FlowMerchant" as const,
              id,
            })),
            "FlowMerchant",
          ]
        : ["FlowMerchant"],
  }),

  getPaginatedEditions: builder.query<
    Paginated<Flow.EditionForSale>,
    {
      page: number;
      merchantFID?: string;
      editionFID?: string;
      name?: string;
      sort?: NftSortingOptions | null;
    }
  >({
    async queryFn({ page, merchantFID, editionFID, name, sort }) {
      const $sort: Record<string, 0 | 1> = {
        lowestPrice: sort === "price_asc" ? 1 : 0,
      };

      const { data, total } = await feathersClient
        .service("edition-for-sale")
        .find({
          query: {
            $skip: page * 10,
            $limit: 10,
            $sort,
            marketFID: MARKET.id.toString(),
            merchantFID:
              merchantFID != null && MERCHANT_IDS.includes(merchantFID)
                ? merchantFID
                : MERCHANT_IDS,
            editionFID,
            name: name != null ? { $iLike: `%${name}%` } : undefined,
          },
        });
      return { data: { list: data, total } };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.list.map(({ id }) => ({
              type: "FlowEdition" as const,
              id,
            })),
            "FlowEdition",
          ]
        : ["FlowEdition"],
  }),
  getLowestPriceForEdition: builder.query<number | null, { nft?: FullNft }>({
    async queryFn({ nft }) {
      const lowestPrice = await getLowestPriceForEdition(nft);

      return { data: lowestPrice };
    },
  }),

  getPaginatedMarketHistory: builder.query<
    Paginated<Flow.MarketHistoryWithEditionData>,
    {
      page: number;
      sort: "top" | "recent";
      editionFID?: string;
      itemFID?: string;
      contractName?: string;
      contractAddress?: string;
    }
  >({
    async queryFn({
      page,
      sort,
      editionFID,
      itemFID,
      contractName,
      contractAddress,
    }) {
      const { data: history, total } = await feathersClient
        .service("marketplace/history")
        .find({
          query: {
            $skip: page * 10,
            $limit: 10,
            $sort:
              sort === "top"
                ? { price: 0, editionNumber: 1 }
                : { blockTimestamp: 0 },
            marketFID: MARKET.id,
            merchantFID: MERCHANT_IDS,
            editionFID,
            itemFID,
            contractName,
            contractAddress: contractAddress
              ? fcl.sansPrefix(contractAddress)
              : undefined,
          },
        });

      const { data: editions } = await feathersClient
        .service("public-editions")
        .find({
          query: {
            $select: ["editionFID", "name", "thumbnail"],
            editionFID: {
              $in: history
                .map(({ editionFID }) => editionFID)
                .filter(isDefined),
            },
            //Quick fix - Setting a high number as the limit to ensure that we get all of the edition results. The default limit on the backend is 10, which prevents us from getting all of the corresponding edition names.
            $limit: 1000000,
          },
        });

      const completeHistory: Flow.MarketHistoryWithEditionData[] = history.map(
        (item) => {
          const edition = editions.find(
            ({ editionFID, merchantFID }) =>
              editionFID === item.editionFID &&
              merchantFID &&
              MERCHANT_IDS.includes(merchantFID)
          );
          return {
            ...item,
            editionName: edition?.name,
            thumbnail: edition?.thumbnail ?? undefined,
          };
        }
      );

      return { data: { list: completeHistory, total } };
    },
  }),

  getEdition: builder.query<
    Flow.EditionForSale | null,
    {
      editionFID: string;
      smartContractAddress: string;
      smartContractName: string;
    }
  >({
    async queryFn({ editionFID, smartContractAddress, smartContractName }) {
      const { data: editions } = await feathersClient
        .service("edition-for-sale")
        .find({
          query: {
            editionFID,
            marketFID: MARKET.id.toString(),
            merchantFID: MERCHANT_IDS,
            smartContractAddress: fcl.sansPrefix(smartContractAddress),
            smartContractName,
          },
        });

      // The only way to get a single edition is to use `find` with a specific editionFID,
      // which still returns an array (hopefully containing one item)
      const edition = editions.length > 0 ? editions[0] : null;

      if (edition == null) {
        return { data: null };
      }

      if (edition.merchantFID == null) {
        return { data: { ...edition, merchant: null } };
      }

      const { data: merchants } = await feathersClient
        .service("merchants")
        .find({
          query: { merchantFID: edition.merchantFID },
        });

      // The only way to get a single merchant is to use `find` with a specific merchantFID,
      // which still returns an array (hopefully containing one item)
      const merchant = merchants.length > 0 ? merchants[0] : null;

      return { data: { ...edition, merchant } };
    },
  }),

  getPaginatedSortedNftListForEdition: builder.query<
    Paginated<Flow.ItemForSale>,
    { page: number; sort: Sort; editionFID: string }
  >({
    async queryFn({ page, sort, editionFID }) {
      const { data, total } = await feathersClient
        .service("item-for-sale")
        .find({
          query: {
            $skip: page * 10,
            $limit: 10,
            $associateProfiles: true,
            $sort: { [sort.by]: sort.order === "highest" ? 0 : 1 },
            editionFID,
            merchantFID: MERCHANT_IDS,
          },
        });

      const dataWithFlattenedProfiles = data.map((item) => ({
        ...item,
        ...item.profile,
      }));

      return { data: { list: dataWithFlattenedProfiles, total } };
    },
    providesTags: (_, __, { sort, editionFID }) => [
      {
        type: "SortedFlowNftForEdition",
        id: `${editionFID}-${sort.by}-${sort.order}`,
      },
      "SortedFlowNftForEdition",
    ],
  }),
  getRedemptionCodeData: builder.query<RedemptionCodeData, string>({
    async queryFn(redemptionCode) {
      const data = await feathersClient
        .service("redemption-code")
        .get(redemptionCode);
      return { data };
    },
  }),

  getUserRedemptionStatusData: builder.query<
    UserRedemptionStatusData,
    { redemptionCode: string }
  >({
    async queryFn({ redemptionCode }) {
      const data = await feathersClient
        .service("redemption")
        .get(redemptionCode);
      return { data };
    },
  }),

  redeemNft: builder.mutation<
    Response.Redeem,
    {
      redemptionCode: string;
      latitude?: number;
      longitude?: number;
      accuracy?: number;
      isOptedInToMarketing?: boolean;
    }
  >({
    async queryFn({
      redemptionCode,
      latitude,
      longitude,
      accuracy,
      isOptedInToMarketing,
    }) {
      const { accessToken } = await feathersClient.get("authentication");
      const walletViewerId =
        Blockchain.NET === "Testnet"
          ? Project.WALLET_VIEWER_ID.Testnet
          : Project.WALLET_VIEWER_ID.Mainnet;
      const response = await axios.post<Response.Redeem>(
        `${Project.CLAIM_API_URL}/redeem`,
        {
          redemptionCode,
          latitude,
          longitude,
          accuracy,
          walletViewerId,
          isOptedInToMarketing,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return { data: response.data };
    },
    invalidatesTags: ["FlowMintStoreNft"],
  }),

  getLatestTransaction: builder.query<
    Flow.FlowHistory,
    { eventType: string; itemFID: string }
  >({
    async queryFn({ eventType, itemFID }) {
      const data = await feathersClient.service("flow-history").find({
        query: {
          eventName: "Deposit",
          itemFID,
          eventType,
          $limit: 1,
          $sort: { blockTimestamp: -1 },
        },
      });

      return { data: data.data[0] };
    },
  }),
});
