import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const MERCHANT_LIST = {
  Testnet: [{ id: "21", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "81", address: "0x86dad8fab9647294" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 0, address: "0x00007069636b656d" },
  Mainnet: { id: 0, address: "0x00007069636b656d" },
};

const WALLET_VIEWER_ID = {
  Testnet: 8,
  Mainnet: 27,
};

const DISCOVERY_WALLET = {
  Testnet: "https://staging.accounts.meetdapper.com/fcl/authn-restricted",
  Mainnet: "https://accounts.meetdapper.com/fcl/authn-restricted",
};
const listForSaleCondition = `
    let traits = nft.resolveView(Type<MetadataViews.Traits>())! as! MetadataViews.Traits
      for trait in traits.traits {
        if(trait.name == "status" && trait.value as! String == "Active") {
          panic("Cannot place for sale an NFT that is still active")
        }
      }
`;
const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "903ff495-d502-4dce-a337-f0cade6ee93f"
        : "ace1d98e-0449-4a41-a978-dcef9013cabc",
    name: "Pickem",
    type: "PFP",
    scarcity: null,
    txRejectionCondition: {
      listForSale: listForSaleCondition,
    },
    attributes: [
      {
        key: "streak",
        text: "Streak",
        multiple: false,
        inverted: false,
        options: [
          { value: "0" },
          { value: "1" },
          { value: "2" },
          { value: "3" },
          { value: "4" },
          { value: "5" },
        ],
      },
      {
        key: "team",
        text: "Team",
        multiple: false,
        inverted: false,
        options: [
          { value: "Jets" },
          { value: "Rams" },
          { value: "49ers" },
          { value: "Bears" },
          { value: "Bills" },
          { value: "Colts" },
          { value: "Lions" },
          { value: "Browns" },
          { value: "Chiefs" },
          { value: "Eagles" },
          { value: "Giants" },
          { value: "Saints" },
          { value: "Texans" },
          { value: "Titans" },
          { value: "Bengals" },
          { value: "Broncos" },
          { value: "Cowboys" },
          { value: "Falcons" },
          { value: "Jaguars" },
          { value: "Packers" },
          { value: "Raiders" },
          { value: "Vikings" },
          { value: "Chargers" },
          { value: "Dolphins" },
          { value: "Panthers" },
          { value: "Patriots" },
          { value: "Seahawks" },
          { value: "Steelers" },
          { value: "Cardinals" },
          { value: "Buccaneers" },
          { value: "Commanders" },
        ],
      },
      {
        key: "available",
        text: "Picked Team",
        multiple: true,
        inverted: true,
        options: [
          { value: "Jets" },
          { value: "Rams" },
          { value: "49ers" },
          { value: "Bears" },
          { value: "Bills" },
          { value: "Colts" },
          { value: "Lions" },
          { value: "Browns" },
          { value: "Chiefs" },
          { value: "Eagles" },
          { value: "Giants" },
          { value: "Saints" },
          { value: "Texans" },
          { value: "Titans" },
          { value: "Bengals" },
          { value: "Broncos" },
          { value: "Cowboys" },
          { value: "Falcons" },
          { value: "Jaguars" },
          { value: "Packers" },
          { value: "Raiders" },
          { value: "Vikings" },
          { value: "Chargers" },
          { value: "Dolphins" },
          { value: "Panthers" },
          { value: "Patriots" },
          { value: "Seahawks" },
          { value: "Steelers" },
          { value: "Cardinals" },
          { value: "Buccaneers" },
          { value: "Commanders" },
        ],
      },
    ],
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "PickemCollectionPublic",
    providerPath: "PickemCollection",
  },
];

const APP_IDENTIFIER = {
  Testnet: "budlight",
  Mainnet: "budlight",
};
const USE_OPENID = true;

const IS_AGE_GATED = true;
const IS_NFT_TEXTUAL_HEADING = true;

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  DISCOVERY_WALLET,
  SMART_CONTRACTS,
  APP_IDENTIFIER,
  USE_OPENID,
  IS_AGE_GATED,
  IS_NFT_TEXTUAL_HEADING,
};
