import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import Field from "components/Widgets/FieldInput";
import Modal from "components/Widgets/Modal";
import { Config, getLocalizedStrings } from "config";
import { MARKETPLACE_ROUTE } from "config/routes";
import useMoney from "hooks/useMoney";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatUsdWithSymbol } from "utils/utils";
import customStyles from "./custom.module.scss";

interface NftPreview {
  number?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
}

type Props = {
  onClose: () => void;
  onMainButtonClick: (price: number) => void;
  nft: NftPreview;
  lowestPrice?: number | null;
  fees?: number | null;
  queryResult: {
    isListForSaleLoading: boolean;
    isListForSaleSuccess: boolean;
    listForSaleErrorMessage?: string | null;
    listForSaleReset?: () => void;
  };
};

export default function SellModal(props: Props) {
  const strings = getLocalizedStrings("Modals", "Sell", useLanguage());
  const [error, setError] = useState<string | null>(null);
  const {
    display: price,
    update: updatePrice,
    value: floatPrice,
  } = useMoney({ useFormatting: false });

  const navigate = useNavigate();

  async function listForSale() {
    if (price == null || !price.length) return setError(strings.noPriceError);
    if (floatPrice >= 1e21) return setError(strings.biggerThan1e21);
    setError(null);

    return props.onMainButtonClick(floatPrice);
  }
  const {
    isListForSaleLoading,
    isListForSaleSuccess,
    listForSaleErrorMessage,
    listForSaleReset,
  } = props.queryResult;

  function cancel() {
    updatePrice("");
    listForSaleReset?.();
    props.onClose();
  }

  const isMissingPrice = price == null || !price.length;
  const isPriceToHigh = floatPrice >= Config.MarketPlace.maxPrice;
  const isPriceToLow = floatPrice < Config.MarketPlace.minPrice;

  const canListForSale = !isMissingPrice && !isPriceToHigh && !isPriceToLow;
  const dynamicSubtitleClasses = [customStyles.dynamicSubtitle];

  const redirectToMarketplaceLink = () => {
    Config.MarketPlace.redirectMarketplaceLink
      ? (location.href = `${Config.MarketPlace.redirectMarketplaceLink}/marketplace`)
      : navigate(MARKETPLACE_ROUTE);
  };

  const redirectUponListForSale = () => {
    const redirectLink = Config.MarketPlace.redirectMarketplaceLink;

    redirectLink && Config.MarketPlace.redirectUponListForSale
      ? (location.href = `${Config.MarketPlace.redirectMarketplaceLink}`)
      : cancel();
  };

  if (isPriceToHigh) {
    dynamicSubtitleClasses.push(customStyles.priceToHigh);
  } else if (!isMissingPrice && isPriceToLow) {
    dynamicSubtitleClasses.push(customStyles.priceToLow);
  }

  if (isListForSaleLoading) {
    return (
      <Modal isVisible clickOutsideToClose={false} hideCloseButton>
        <h2>{strings.placeForSaleInProgress}</h2>
        <p>
          {strings.placeForSaleInProgressSubtitle}
          <br />
          {strings.pleaseWait}
        </p>
        <div className={customStyles.smallNftCard}>
          <SmallNFTCard nft={props.nft} loading={isListForSaleLoading} />
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        isVisible
        btnDisabled={!canListForSale}
        onClose={
          props.queryResult.isListForSaleSuccess
            ? redirectUponListForSale
            : cancel
        }
        onActionClick={() =>
          props.queryResult.isListForSaleSuccess
            ? redirectToMarketplaceLink()
            : listForSale()
        }
        // btnDisabled={!canListForSale}
        customStrings={{
          actionBtn: isListForSaleSuccess
            ? strings.showOnMarketplace
            : strings.placeForSale,
        }}
      >
        <h2>
          {isListForSaleSuccess ? strings.successTitle : strings.placeForSale}
        </h2>
        <p
          className={dynamicSubtitleClasses.join(" ")}
          dangerouslySetInnerHTML={{
            __html: isListForSaleSuccess
              ? strings.successSubtitle
              : strings.subtitle,
          }}
        ></p>
        <div className={customStyles.smallNftCard}>
          <SmallNFTCard nft={props.nft} success={isListForSaleSuccess} />
        </div>
        {!isListForSaleSuccess && (
          <>
            <div className={customStyles.inputField}>
              <Field
                id="sell-price"
                className={"pl-0 mt-2"}
                placeHolder={strings.price}
                value={price ?? ""}
                useValue
                errorMsg={error ?? listForSaleErrorMessage ?? undefined}
                type="text"
                inputMode={"decimal"}
                onChange={(e) => {
                  setError(null);
                  updatePrice(e.target.value.replace(/[^\d.-]/g, ""));
                  listForSaleReset?.();
                }}
              />
              <p className={customStyles.dollarSign}>$</p>
            </div>
            <p>
              {props.lowestPrice != null && (
                <>
                  {`${strings.currentLowestPrice}: ${formatUsdWithSymbol(
                    props.lowestPrice
                  )}`}
                  <br />
                </>
              )}
              {props.fees != null && (
                <>
                  {strings.marketplaceFee}:{" "}
                  {Math.round(props.fees * 10000) / 100}%
                  <br />
                </>
              )}
            </p>
          </>
        )}
        {isListForSaleSuccess && price && (
          <p className="ma-2">{formatUsdWithSymbol(Number(price))}</p>
        )}
      </Modal>
    );
  }
}
