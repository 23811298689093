import { Project } from "config";
import { createContext, ReactNode, useContext, useState } from "react";

export type AvailableLanguage = "en" | "ja" | "de";

/**
 * Save preferred language
 * @description Save the user's preferred language in local storage.
 */
const savePreferredLanguage = (language: AvailableLanguage) => {
  window.localStorage.setItem("preferredLanguage", language);

  // We are reloading for now, although in the future,
  // this will be done in the context and we won't need that.
  window.location.reload();
};

/**
 * Get language to use
 * @description Returns the preferred language if defined in local storage. If not, returns default language.
 * @returns AvailableLanguage
 */
const getLanguage = () => {
  const allowedLanguages: AvailableLanguage[] = Project.ALLOWED_LANGUAGES;
  const projectLanguage: AvailableLanguage = Project.DEFAULT_LANGUAGE;
  const preferredLanguage = window.localStorage.preferredLanguage;

  if (!preferredLanguage || !allowedLanguages.includes(preferredLanguage)) {
    return projectLanguage;
  } else {
    return preferredLanguage;
  }
};

const LanguageContext = createContext<{
  language: AvailableLanguage;
  setLanguage: React.Dispatch<React.SetStateAction<AvailableLanguage>>;
}>({
  language: getLanguage(),
  setLanguage: () => {},
});

function LanguageProvider({ children }: { children: ReactNode }) {
  const [language, setLanguage] = useState<AvailableLanguage>(getLanguage());
  const value = { language, setLanguage };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}

const useLanguage = () => {
  const { language } = useContext(LanguageContext);

  return language;
};

export { LanguageProvider, useLanguage, getLanguage, savePreferredLanguage };
