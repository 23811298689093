import { CodeInput, Field, Modal } from "components";
import { Config, getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";

type ModalStep = "email" | "code";

type Props = {
  onSubmit: (email: string, code?: string) => Promise<void>;
  onCancel: () => void;
  isEmailDataLoading: boolean;
  emailError?: string | null;
  resetEmailData: () => void;
  isLoginDataLoading: boolean;
  loginError: boolean;
  isOnRedemptionPage: boolean;
  lastEmailAndCode?: { email: string; code: string };
};

export function LoginModalUI({
  onSubmit,
  onCancel,
  isEmailDataLoading,
  emailError,
  isLoginDataLoading,
  resetEmailData,
  loginError,
  isOnRedemptionPage,
  lastEmailAndCode,
}: Props) {
  const [step, setStep] = useState<ModalStep>("email");
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const joinedCode = code.join("");
  const localizedStrings = getLocalizedStrings(
    "Modals",
    "Login",
    useLanguage()
  );
  const TOSARRAY = Config.Client.TOSLINKS;

  const emailErrorMsg = emailError
    ? localizedStrings.errors.invalidEmailAddress
    : null;

  const validEmailAddress = emailError === null;

  const isLastTryInvalid =
    loginError &&
    lastEmailAndCode?.code === joinedCode &&
    lastEmailAndCode?.email === email;

  useEffect(() => {
    if (validEmailAddress) setStep("code");
  }, [validEmailAddress]);

  const onClose = () => {
    setStep("email");
    onCancel();
  };

  const handleReturnToEmailStepClick = () => {
    setEmail("");
    setCode(["", "", "", "", "", ""]);
    setStep("email");
  };

  const onResend = () => {
    onSubmit(email);
    setCode(["", "", "", "", "", ""]);
  };

  const loginSubtitle = Array.isArray(localizedStrings.email.subtitle)
    ? localizedStrings.email.subtitle.map((string) => (
        <span className={styles.subtitle}>{string}</span>
      ))
    : isOnRedemptionPage
    ? localizedStrings.email.redeemSubtitle
    : localizedStrings.email.subtitle;

  return (
    <>
      <Modal
        onClose={onClose}
        isVisible={step === "email"}
        onActionClick={() => onSubmit(email)}
        btnLoading={isEmailDataLoading}
        btnDisabled={!email || !!emailErrorMsg}
        customStrings={{
          actionBtn: localizedStrings.email.btnTxt,
        }}
        alignment="left"
      >
        <h2>
          {isOnRedemptionPage
            ? localizedStrings.email.redeemTitle
            : localizedStrings.email.title}
        </h2>
        <p>{loginSubtitle}</p>
        {TOSARRAY.length > 0 && (
          <>
            <p>{localizedStrings.agreeToTOS}</p>
            <p>{localizedStrings.agreeToTOS2ndP}</p>
            <div className={styles.tosContainer}>
              {TOSARRAY.map((tos: any) => (
                <div className={styles.tos} key={tos.href}>
                  <a href={tos.href} target="_blank">
                    {tos.label}
                  </a>
                </div>
              ))}
            </div>
            <p>{localizedStrings.japaneseStuff}</p>
          </>
        )}
        <Field
          onKeyDown={(e) => e.key === "Enter" && email && onSubmit(email)}
          required
          autoComplete={"on"}
          onChange={(e) => {
            if (emailErrorMsg) resetEmailData();
            setEmail(e.target.value);
          }}
          value={email}
          type={"email"}
          errorMsg={emailErrorMsg ?? ""}
          placeHolder={localizedStrings.email.inputPlaceholder}
        />
      </Modal>
      <Modal
        isVisible={step === "code"}
        onActionClick={() => onSubmit(email, joinedCode)}
        onClose={onClose}
        onBack={handleReturnToEmailStepClick}
        btnLoading={isLoginDataLoading}
        btnDisabled={
          joinedCode.length !== Config.Email.LOGIN_CODE_LENGTH ||
          !!isLastTryInvalid
        }
        customStrings={{
          actionBtn: localizedStrings.code.btnTxt,
        }}
        alignment="left"
      >
        <h2>{localizedStrings.code.title}</h2>
        <p>{localizedStrings.code.subtitle}</p>
        <CodeInput
          errorComponent={
            isLastTryInvalid ? (
              <>
                <div className={styles.codeError}>
                  <p className={styles.codeErrorText}>
                    <span>
                      {localizedStrings.errors.incorrectCode}{" "}
                      <a
                        className={styles.codeResend}
                        onClick={() => onResend()}
                      >
                        {localizedStrings.errors.resendCode}
                      </a>
                      .
                    </span>
                  </p>
                </div>
              </>
            ) : null
          }
          code={code}
          setCode={setCode}
          onKeyDown={(e) => e.key === "Enter" && onSubmit(email, joinedCode)}
        />
      </Modal>
    </>
  );
}
