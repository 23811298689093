import React, { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./pickem.module.scss";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { nflTeams } from "config/clients/pickem/customizable/client";

export const PickemFooter: FunctionComponent<{ onLogout: () => void }> =
  React.memo(({ onLogout }) => {
    const strings = getLocalizedStrings("Component", "AppFooter", useLanguage())
      .customFooters.pickem;
    return (
      <footer className={styles.pickemFooter}>
        <div className={styles.container}>
          <div className={styles.upperSection}>
            <ul className={styles.upperNav}>
              <li>
                <a
                  href={"https://www.budlight.com/nft/about/"}
                  target={"_blank"}
                >
                  {strings.upperNav.faq}
                </a>
              </li>
              <li>
                <a
                  href={"https://www.budlight.com/terms-and-conditions/"}
                  target={"_blank"}
                >
                  {strings.upperNav.termsAndConditions}
                </a>
              </li>
              <li>
                <a href={"mailto:support@mint.store"}>
                  {strings.upperNav.contactUs}
                </a>
              </li>
              <li>
                <a
                  href={"https://www.budlight.com/privacy-policy/"}
                  target={"_blank"}
                >
                  {strings.upperNav.privacyPolicy}
                </a>
              </li>
              <li>
                <a
                  href={
                    "https://www.budlight.com/california-residents-privacy-rights/"
                  }
                  target={"_blank"}
                >
                  {strings.upperNav.doNotSellInfo}
                </a>
              </li>
              <li>
                <a href={"https://www.tapintoyourbeer.com/"} target={"_blank"}>
                  {strings.upperNav.consumerInfo}
                </a>
              </li>
            </ul>
            <ul className={styles.upperSocial}>
              <li>
                <a href="https://twitter.com/budlight" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/budlight" target="_blank">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a href="https://discord.gg/budlight" target="_blank">
                  <FontAwesomeIcon icon={faDiscord} />
                </a>
              </li>
            </ul>
          </div>

          <hr />

          <div className={styles.logoSection}>
            <ul className={styles.logoList}>
              {nflTeams.map((team, i) => (
                <li key={i}>
                  <img
                    src={team.logoFileName}
                    alt={`${team.city} ${team.name}`}
                    title={`${team.city} ${team.name}`}
                  />
                </li>
              ))}
            </ul>
          </div>

          <hr />

          <div className={styles.infoSection}>
            {strings.infoSection.map((value, i) => (
              <p key={i}>{value}</p>
            ))}

            <p className={styles.logout} onClick={() => onLogout()}>
              {strings.logout}
            </p>
          </div>

          <div className={styles.legalLogoSection}>
            <img
              src="https://cdn.shopify.com/s/files/1/0662/8383/1552/files/Artboard_1_1.svg?v=1663929790"
              alt="NFL Officially Licensed Product"
            />
            <img
              src="https://cdn.shopify.com/s/files/1/0662/8383/1552/files/FlowLogo_Green_White_1_1.svg?v=1663929790"
              alt="Flow"
            />
          </div>
        </div>
      </footer>
    );
  });
