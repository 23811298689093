import Button from "components/Widgets/Button";
import { ClientConfig } from "config";
import { LOGIN_ROUTE } from "config/routes";
import { useState } from "react";
import { LoginModal } from "../ActionModals/Login";

type Props = {
  strings: ClientConfig["Strings"]["Component"]["AppHeader"]["en"];
};

export default function LoginLink({ strings }: Props) {
  const [isLoginModalVisible, setIsLoginModalVisible] =
    useState<boolean>(false);

  return (
    <div>
      {location.pathname !== LOGIN_ROUTE && (
        <>
          <Button
            className={"loginBtn"}
            onClick={() => setIsLoginModalVisible(true)}
          >
            {strings.login}
          </Button>
          {isLoginModalVisible && (
            <LoginModal
              onCancel={() => setIsLoginModalVisible(false)}
              onComplete={() => setIsLoginModalVisible(false)}
            />
          )}
        </>
      )}
    </div>
  );
}
