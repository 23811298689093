const en = {
  alertMsg:
    "You might encounter some problems because you seem to be using incognito mode.",
  learnMore: "Learn more",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
