import { AnimatedNFT, Modal } from "components";
import Loader from "components/Widgets/BigLoader";
import { Config, Project, getLocalizedStrings } from "config";
import { useEffect, useState } from "react";
import { Maximize, Minimize } from "react-feather";
import { getFileType } from "utils/utils";
import { ClaimStepList } from "../components";
import { NftDisplayName } from "./NftDisplayName";
import styles from "./style.module.scss";
import {
  useClaim,
  useCurrentWallet,
  useWalletSetupStatus,
} from "lib/store/api/flow/hooks";
import { ChangeWalletButtonsFlowConnector } from "../connectors/ChangeWalletButtonsFlowConnector";
import { ClaimButtonFlowConnector } from "../connectors/ClaimButtonFlowConnector";
import { ModalVisible } from "../NftShowPage";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";
import { withPrefix } from "@blocto/fcl";
import { flowAPI } from "lib/store/api/flow";
import { NFT_LIST_ROUTE } from "config/routes";
import { CustomizedClaimModal } from "components/AppComponent/ActionModals/Claim/wrapper";
import { useNavigate } from "react-router-dom";
import style from "pages/Editions/show/ui/styles.module.scss";
import { useLanguage } from "lib/contexts/LanguageContext";

type Props = {
  uuid: string;
  contractName: string;
  contractAddress: string;
};

export function NftOnDemandShowUI(props: Props) {
  const contractName = props.contractName;
  const contractAddress = props.contractAddress;
  const uuid = props.uuid;
  const itemFID = "";
  const [modalVisible, setModalVisible] = useState<ModalVisible | null>(null);
  const [isNftFullscreen, setIsNftFullscreen] = useState<boolean>(false);
  const user = useAppSelector(selectAppUser);
  const wallet = useCurrentWallet();
  const displayRedemptionSteps = Config.Client.DISPLAY_REDEMPTION_STEPS;
  const { data: smartContractsWithSetup } = useWalletSetupStatus();
  const detailsPageStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );
  const navigate = useNavigate();
  const { data: nft, isLoading: isNftLoading } = flowAPI.useGetFullNftQuery({
    itemFID,
    contractAddress,
    contractName,
    uuid,
  });

  const [
    claim,
    {
      isSuccess: isClaimSuccess,
      isError: isClaimError,
      isLoading: isClaimLoading,
    },
  ] = useClaim({
    itemFID,
    uuid,
    contractName,
    contractAddress,
  });

  useEffect(() => {
    if (nft && nft.itemFID && !isClaimLoading) {
      navigate(`${NFT_LIST_ROUTE}`);
    }
  }, [nft]);

  const displayChangeWalletButton = Boolean(user && wallet);
  const displayConnectWallet = Boolean(user && !wallet?.address);
  const associatedSmartContract = smartContractsWithSetup?.find(
    (walletContract) =>
      walletContract.name === contractName &&
      withPrefix(walletContract.address) === withPrefix(contractAddress)
  );
  const stepInitializeWallet = associatedSmartContract?.isSetup;
  const src = nft?.videoURL ?? "";
  const thumbnail = nft?.thumbnail ?? undefined;
  const name = nft?.name ?? undefined;
  const stepConnectWallet = !!wallet;
  const isNftClaimed = !!nft?.state?.isClaimed;
  const displayChangeWallet =
    !stepConnectWallet &&
    !isNftClaimed &&
    Project.WALLET_TYPE &&
    (displayChangeWalletButton || displayConnectWallet);

  if (isNftLoading) {
    return <Loader show={isNftLoading} />;
  }

  const queryResults = {
    claim: {
      isClaimSuccess,
      isClaimError,
      isClaimLoading,
    },
  };

  return (
    <section className={styles.editionPage}>
      <section className={styles.section_img}>
        <div className={styles.img_container}>
          {src && (
            <AnimatedNFT
              src={src}
              thumbnail={thumbnail}
              name={name}
              fileType={getFileType(src) ?? undefined}
              icon={<Maximize />}
              onIconClick={() =>
                setIsNftFullscreen((fullscreen) => !fullscreen)
              }
            />
          )}
        </div>
      </section>

      <section className={styles.section_info}>
        <div className={styles.info_container}>
          {name && <NftDisplayName name={name} />}
          {displayRedemptionSteps && !isNftClaimed && (
            <div className={style.stepsWrapper}>
              <div className={`${style.stepWrapper} ${style.grey}`}>
                <label>{detailsPageStrings.step1Label}</label>
                <span>{detailsPageStrings.hyphen}</span>
                <span>{detailsPageStrings.step1Text}</span>
              </div>
              <div
                className={
                  !wallet || !stepInitializeWallet
                    ? `${style.stepWrapper} ${style.bold}`
                    : `${style.stepWrapper} ${style.grey}`
                }
              >
                <label
                  className={!wallet || !stepInitializeWallet ? style.bold : ""}
                >
                  {detailsPageStrings.step2Label}
                </label>
                <span>{detailsPageStrings.hyphen}</span>
                <span>{detailsPageStrings.step2Text}</span>
              </div>
              <div
                className={
                  wallet && stepInitializeWallet
                    ? `${style.stepWrapper} ${style.bold}`
                    : `${style.stepWrapper} ${style.grey}`
                }
              >
                <label className={stepInitializeWallet ? style.bold : ""}>
                  {detailsPageStrings.step3Label}
                </label>
                <span>{detailsPageStrings.hyphen}</span>
                <span>{detailsPageStrings.step3Text}</span>
              </div>
            </div>
          )}
          <div className={styles.action_container}>
            <ClaimStepList
              wallet={wallet}
              renderClaimStepList={true}
              stepInitializeWallet={stepInitializeWallet}
            >
              <>
                {displayChangeWallet && (
                  <ChangeWalletButtonsFlowConnector
                    currentOwnerAddress={nft?.currentAddress}
                    displayChangeWalletButton={displayChangeWalletButton}
                    displayConnectWallet={displayConnectWallet}
                  />
                )}
              </>
              {!stepInitializeWallet && !displayChangeWallet && (
                <>
                  <ClaimButtonFlowConnector
                    itemFID={""}
                    contractAddress={contractAddress}
                    contractName={contractName}
                    setModalVisible={() => null}
                    claim={claim}
                    noModalClaim={true}
                    step="2"
                    uuid={nft?.uuid}
                  />
                </>
              )}
              {!isNftClaimed && stepInitializeWallet && nft && (
                <>
                  <ClaimButtonFlowConnector
                    itemFID={""}
                    contractAddress={contractAddress}
                    contractName={contractName}
                    setModalVisible={setModalVisible}
                    claim={(walletAddress: string, nftID: number) =>
                      claim(walletAddress, nftID)
                    }
                    uuid={nft.uuid}
                    isMinting={true}
                  />
                </>
              )}
            </ClaimStepList>

            {nft && (isClaimLoading || isClaimSuccess) && (
              <>
                <CustomizedClaimModal
                  onClose={() => setModalVisible(null)}
                  nft={nft}
                  queryResult={queryResults.claim}
                  isMinting={true}
                  hideCloseButton={true}
                  clickOutsideToClose={false}
                />
              </>
            )}

            <Modal
              isVisible={isNftFullscreen}
              onClose={() => setIsNftFullscreen(false)}
              fullscreen
            >
              {src && (
                <AnimatedNFT
                  src={src}
                  thumbnail={thumbnail}
                  name={name}
                  fileType={getFileType(src) ?? undefined}
                  icon={<Minimize />}
                  onIconClick={() =>
                    setIsNftFullscreen((fullscreen) => !fullscreen)
                  }
                />
              )}
            </Modal>
          </div>
        </div>
      </section>
    </section>
  );
}
