const en = {
  usernameLbl: "Username",
  usernamePlaceholder: "Anonymous-123",
  emailLbl: "Email",
  emailPlaceholder: "example@hotmail.com",
  submitBtnText: "Save new username",
  walletsChip: "Wallets",
  logoutBtnText: "Logout",
  addWallet: "Add wallet",
  connectWallet: "Connect wallet",
  profileChip: "Profile",
  badWordsError: "Your username can't have bad words",
  setUsernameError: "An error occurred while updating your username",
  contactUs: "Contact us to change your email address",
  uploadTitle: "Upload your Photo",
  uploadSubtitle: "Time to shine on the court.",
  uploadUploadBtn: "Upload Photo",
  uploadSaveBtn: "Save",
  uploadDeleteBtn: "Delete current photo",
  usernameErrorMsg: "This username is already taken or invalid.",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
