import { FunctionComponent } from "react";
import styles from "./styles.module.scss";

type Props = {
  src?: string;
  name?: string;
  hasBorder?: boolean;
  onClick?: () => void;
  isUserIcon?: boolean;
};

const RoundImg: FunctionComponent<Props> = ({
  src,
  name = "",
  hasBorder = false,
  onClick,
  isUserIcon,
}) => {
  const cardClasses = [styles.card];
  if (hasBorder) cardClasses.push(styles.hasBorder);
  if (onClick) cardClasses.push(styles.hasPointer);
  if (isUserIcon) cardClasses.push(styles.userIcon);

  return (
    <div onClick={onClick} className={cardClasses.join(" ")}>
      <div
        className={styles.img}
        style={{
          backgroundImage: `url(${src})`,
        }}
      >
        {!src && <span>{name.charAt(0).toUpperCase()}</span>}
      </div>
    </div>
  );
};

export default RoundImg;
