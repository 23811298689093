const en = {
  noWalletMsg: "No wallet are associated to the account",
  setAsPrimaryOption: "Set as primary",
  removeOption: "Remove",
  setupOption: "Setup",
  addFunds: "Add funds",
  withdrawFunds: "Withdraw funds",
  disconnectOption: "Disconnect",
  connectOption: "Connect",
  balanceTitle: "Balance",
  registeringWallet: "Registering wallet ...",
  setupWallet: "Setting up wallet ...",
  fusdTxRejected: "FUSD Transaction was rejected by the user",
  setupTxRejected: "Setup Account Transaction was rejected by the user",
  registerError: "An error occurred while registering the wallet",
  removingWallet: "Removing wallet ...",
  setupFailed: "Setup Failed",
  sessionExpired: "Session expired. Please login again",
  wallet: "Wallet",
  unnamedWallet: "Unnamed wallet",
  editWallet: "Edit Wallet",
  setupWalletBtn: "Settings",
  balanceUnavailable: "Connect to view balance",
  save: "Save",
  customNamePlaceholder: "Enter custom wallet name",
  addWallet: "Add wallet",
  connectWallet: "Connect wallet",
  removeWallet: "Remove wallet",
  walletHistory: "View all wallets",
  errorCharacters: "Your wallet name must contain letters or numbers.",
  errorLengthMin: "Your wallet name must be at least 3 characters long.",
  errorLengthMax: "Your wallet name must not be more than 25 characters long.",
  setupWalletOption: "Setup Wallet",
  historyModal: {
    title: "Wallet History",
    noWalletHistory: "You do not have any other wallet.",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
