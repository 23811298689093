const en = {
  greeting: {
    collectible: "Collectible",
    text: "Thank you for your album purchase. Please access your wallet in order to claim your collectible.",
    actionBtn: "Claim your Collectible",
  },
  email: {
    title: "Access your wallet",
    text: "Please enter your email to get access to your wallet.",
    placeholder: "Your Email",
    actionBtn: "Continue",
    error: "An error occured, please try again.",
  },
  code: {
    title: "Please enter login code",
    text: "Please enter the six-digit code sent to your email.",
    warning: "Note: This is NOT your redemption code.",
    actionBtn: "Continue",
    error: "An error occured, please try again.",
  },
  redemptionCode: {
    title: "Enter redemption code",
    // text: "Please enter the code you received with the purchase of the album to redeem the collectible.", // FOR UMG
    text: "Please enter your redemtion code to redeem the collectible.",
    placeholder: "Enter redemption code",
    actionBtn: "Claim your Collectible",
    error: "The code you entered is invalid, please try again.",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
