const en = {
  merchantMarketPlaceTitle: "Marketplace",
  recentMarketSales: "Recent Market Sales",
  searchPlaceholder: "Search marketplace",
  sortPlaceholder: "Sort",
  sortLowestPrice: "Lowest Price",
  sortHighestPrice: "Highest Price",
  creatorsHeading: "Creators",
  creatorsHeadingSearch: "All Creators for",
  collectiblesHeading: "Collectibles",
  collectiblesHeadingSearch: "All Collectibles for",
  showAll: "Show all",
  showLess: "Show less",
  forSale: "for sale",
  priceFrom: "From",
  clearAll: "Clear all",
  done: "Done",
  noResult: "Nothing to display.",
  filters: "Filters",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
