import { Chip } from "components";
import { Config, getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { NftPreview } from "../types";
import styles from "./style.module.scss";

interface Props {
  setShareModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  nft?: NftPreview;
}
export const VisibleInfoChips = (props: Props) => {
  const localizedStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );

  const { editionNumber, printingLimit, scarcity } = props.nft ?? {};

  const renderEditionChipTxt = () => {
    if (props.nft?.contractType === "PFP") {
      return `#${props.nft.itemFID}`;
    }
    return (
      <>
        {`#${editionNumber}`}
        {!Config.Client.HIDE_PRINTING_LIMIT &&
          printingLimit &&
          `\u00A0/\u00A0${printingLimit}`}
      </>
    );
  };

  const renderScarcityChip = () => {
    return (
      Config.Client.DISPLAY_SCARCITY &&
      scarcity && (
        <Chip type="secondary" className={styles.scarcity}>
          {scarcity}
        </Chip>
      )
    );
  };

  return (
    <div className={styles.info_chips}>
      <Chip type="secondary" className={styles.info_chip}>
        {renderEditionChipTxt()}
      </Chip>
      {renderScarcityChip()}
      <Chip
        type="secondary"
        onClick={() => props.setShareModalVisible(true)}
        className={styles.info_chip}
        share={true}
      >
        {localizedStrings.shareNft}
      </Chip>
    </div>
  );
};

export const NftInfoChips = (props: Props) => {
  switch (Config.Client.HIDE_NFT_INFO_CHIPS) {
    case true:
      return null;
    default:
      return <VisibleInfoChips {...props} />;
  }
};
