import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import {
  ChangeEvent,
  FunctionComponent,
  SyntheticEvent,
  useState,
} from "react";
import styles from "../styles.module.scss";
import { Button, Field } from "components";
import { useNavigate } from "react-router-dom";
import { REDEMPTION_NFT_ROUTE } from "config/routes";

const RedemptionCodeStep: FunctionComponent = () => {
  const strings = getLocalizedStrings("Pages", "Redeem", useLanguage());
  const navigate = useNavigate();
  const [redemptionCode, setRedemptionCode] = useState<string>("");

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRedemptionCode(e.target.value);
  };

  const runAction = () => {
    navigate(REDEMPTION_NFT_ROUTE.replace(":redemptionCode", redemptionCode));
  };

  return (
    <>
      <h2>{strings.redemptionCode.title}</h2>
      <p>{strings.redemptionCode.text}</p>

      <form
        onSubmit={(e: SyntheticEvent) => {
          e.preventDefault();
          runAction();
        }}
      >
        <div className={styles.emailContainer}>
          <Field
            required
            outline
            autoComplete={"on"}
            onChange={onChange}
            value={redemptionCode}
            type={"text"}
            errorMsg={""}
            label={strings.redemptionCode.placeholder}
            placeHolder={strings.redemptionCode.placeholder}
            className={styles.monospaceInput}
          />
        </div>
      </form>

      <Button type="primary" onClick={runAction}>
        {strings.redemptionCode.actionBtn}
      </Button>
    </>
  );
};

export default RedemptionCodeStep;
