import Loader from "components/Widgets/BigLoader";
import { Project } from "config";
import { NET } from "config/global/blockchain";
import { usePrevious } from "hooks/usePrevious";
import { ethAPI } from "lib/store/api/eth";
import { useMerchants } from "lib/store/api/eth/hooks";
import { useAppSelector } from "lib/store/hooks";
import {
  selectAppUser,
  selectIsInitialized,
} from "lib/store/slices/user-slice";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { RedemptionNftUI } from "../ui/RedemptionNftUI";
import InvalidCodeUI from "../ui/InvalidCodeUI";

export default function EthConnector() {
  const { redemptionCode = "" } = useParams();
  const user = useAppSelector(selectAppUser);
  const isInitialized = useAppSelector(selectIsInitialized);
  const previousIsInitialized = usePrevious(isInitialized);
  const startPollingRef = useRef<number>(Infinity);

  const [lastRedemptionUUID, setLastRedemptionUUID] = useState<string | null>(
    null
  );

  const {
    data: redemptionCodeData,
    error: redemptionCodeError,
    isLoading: redemptionCodeLoading,
  } = ethAPI.useGetRedemptionCodeDataQuery(redemptionCode, {
    skip: !redemptionCode,
  });

  const [
    fetchUserRedemptionStatus,
    {
      data: userRedemptionData,
      error: userRedemptionError,
      isLoading: userRedemptionLoading,
    },
  ] = ethAPI.useLazyGetUserRedemptionStatusDataQuery({});

  const [
    redeemNft,
    { data: redeemData, error: redeemError, isLoading: isRedeemLoading },
  ] = ethAPI.useRedeemNftMutation();

  useEffect(() => {
    setLastRedemptionUUID(redeemData?.id ?? null);
  }, [redeemData]);

  const hasRedemptionStarted =
    userRedemptionData?.status === "started" ||
    redeemData?.status === "started";

  if (hasRedemptionStarted && !isFinite(startPollingRef.current)) {
    startPollingRef.current = Date.now();
  }

  const isPollingTakingTooLong = Date.now() - startPollingRef.current > 20_000;

  const lastCheckIsGeofencingError =
    !!lastRedemptionUUID &&
    userRedemptionData?.id === lastRedemptionUUID &&
    userRedemptionData?.status === "geoFencingError";

  if (lastCheckIsGeofencingError) {
    startPollingRef.current = Infinity;
  }

  const { error: pollingError, data: pollingData } =
    ethAPI.useGetUserRedemptionStatusDataQuery(
      { redemptionCode },
      {
        skip:
          !hasRedemptionStarted ||
          isPollingTakingTooLong ||
          !redemptionCode ||
          userRedemptionData?.status === "done" ||
          lastCheckIsGeofencingError,
        pollingInterval: 1000,
      }
    );

  // This is a special case where we want to detect the app initialization. We then
  // want to make a network call at this moment if the user is logged in
  useEffect(() => {
    if (!previousIsInitialized && isInitialized && user && redemptionCode) {
      fetchUserRedemptionStatus({ redemptionCode }, false);
    }
  }, [previousIsInitialized, isInitialized, user]);

  const { data: merchants } = useMerchants(0, {
    id: redemptionCodeData?.edition?.merchantId?.toString(),
  });

  const merchant = {
    name: merchants[0]?.profileName ?? "",
    image: merchants[0]?.profileImg ?? "",
  };

  const editionsLeft = redemptionCodeData?.editionsLeft ?? null;
  const editionsRedeemed = redemptionCodeData?.redemptionCount ?? null;
  const edition = redemptionCodeData?.edition;
  const loading = redemptionCodeLoading || userRedemptionLoading;
  const maxRedemptions =
    redemptionCodeData?.maxRedemptions ??
    redemptionCodeData?.redemptionCampaign.maxRedemptions ??
    null;
  const merchantIds = Project.MERCHANT_LIST[NET].map((merchant) => merchant.id);

  const userLimitReached =
    userRedemptionData?.status === "userLimitReached" ||
    pollingData?.status === "userLimitReached" ||
    redeemData?.status === "userLimitReached";

  const isGlobalLimitReached =
    editionsLeft === 0 ||
    userRedemptionData?.status === "globalLimitReached" ||
    pollingData?.status === "globalLimitReached" ||
    redeemData?.status === "globalLimitReached";

  const isCodeLimitReached =
    redeemData?.status === "codeLimitReached" ||
    pollingData?.status === "codeLimitReached";

  const error = Boolean(
    redemptionCodeError ||
      userRedemptionError ||
      redeemError ||
      pollingError ||
      userRedemptionData?.status === "error" ||
      pollingData?.status === "error" ||
      redeemData?.status === "error"
  );

  const geoDenied = Boolean(
    userRedemptionData?.status === "geoFencingError" ||
      redeemData?.status === "geoFencingError" ||
      pollingData?.status === "geoFencingError"
  );

  const isRedemptionDone =
    userRedemptionData?.status === "done" ||
    pollingData?.status === "done" ||
    redeemData?.status === "done";

  const basicNftInfo = userRedemptionData?.ethNft || pollingData?.ethNft;

  const checkIfUserAlreadyRedeemedAndRedeemNft = async (
    position?: GeolocationPosition,
    isOptedInToMarketing?: boolean
  ) => {
    const userRedemptionStatus = await fetchUserRedemptionStatus(
      { redemptionCode },
      false
    ).unwrap();
    if (userRedemptionStatus.ethNft) {
      return;
    } else {
      redeemNft({
        redemptionCode,
        latitude: position?.coords.latitude ?? undefined,
        longitude: position?.coords.longitude ?? undefined,
        accuracy: position?.coords.accuracy ?? undefined,
        isOptedInToMarketing: isOptedInToMarketing ?? undefined,
      });
    }
  };

  const isRedemptionMerchantAssociatedToCurrentMerchant = merchantIds.includes(
    edition?.merchantId?.toString() ?? ""
  );
  const isRedeemable =
    !geoDenied &&
    !error &&
    !geoDenied &&
    !isCodeLimitReached &&
    !isGlobalLimitReached &&
    !userLimitReached;

  const submitRedemptionRequest = async (
    position?: GeolocationPosition,
    redemptionMrktOptedIn?: boolean
  ) => {
    redemptionCode
      ? checkIfUserAlreadyRedeemedAndRedeemNft(position, redemptionMrktOptedIn)
      : undefined;
  };

  if (loading) {
    return <Loader show />;
  } else {
    return edition && isRedemptionMerchantAssociatedToCurrentMerchant ? (
      <RedemptionNftUI
        userLimitReached={userLimitReached}
        globalLimitReached={isGlobalLimitReached}
        codeLimitReached={isCodeLimitReached}
        edition={edition}
        nft={basicNftInfo}
        merchant={merchant}
        isLoggedIn={!!user}
        submitRedemptionRequest={submitRedemptionRequest}
        editionsLeft={editionsLeft}
        maxRedemptions={maxRedemptions}
        editionsRedeemed={editionsRedeemed}
        error={error}
        geoDenied={geoDenied}
        isRedemptionDone={isRedemptionDone}
        isLoadingModalVisible={
          (isRedeemLoading || hasRedemptionStarted) && isRedeemable
        }
        redemptionCodeData={redemptionCodeData}
      />
    ) : (
      <InvalidCodeUI />
    );
  }
}
