import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { getLocalizedStrings } from "config";
import {
  useAppDispatch,
  useAppSelector,
  usePaginatedList,
} from "lib/store/hooks";
import { selectAppUser, selectFlowUser } from "lib/store/slices/user-slice";
import { useEffect, useState } from "react";
import { Flow } from "types";
import { flowAPI } from ".";

import { Sort } from "components/AppComponent/ActionModals/SelectEdition/SortHeader";
import { useLanguage } from "lib/contexts/LanguageContext";
import { parseOnchainActionError } from "lib/store/utils";
import { ItemForSale } from "types/app-model/flow";
import { PfpFilters } from "types/app-model/global";
import { FindQuery } from "types/database/response";

// We cannot simply use a dynamic list of flowAPI.useGetClaimedNftListQuery, because of the laws of hooks.
// So instead I created this more complicated hook to serve a list of getClaimedNftList selectors,
// one for each wallet the user has. All item in the "lists" has its own "data", "isLoading", etc.
export function useGetClaimedNftLists({
  userId,
}: {
  userId: string | undefined;
}) {
  const { data: wallets } = flowAPI.useGetWalletListQuery({ userId });

  const dispatch = useAppDispatch();
  const lists = useAppSelector((state) =>
    (wallets ?? []).map((wallet) =>
      flowAPI.endpoints.getClaimedNftList.select({ wallet })(state)
    )
  );

  useEffect(() => {
    // Add one subscription for each wallet
    const subscriptions = wallets?.map((wallet) =>
      dispatch(flowAPI.endpoints.getClaimedNftList.initiate({ wallet }))
    );
    // Return the `unsubscribe` callback to be called in the `useEffect` cleanup step
    return () => subscriptions?.forEach((sub) => sub.unsubscribe());
  }, [dispatch, wallets]);

  return lists;
}

// We have multiple type of NFT lists: purchased, claimed, for sale, etc.
// This hook returns a flattened list of all NFTs of the current user.
export function useNftList(page = 0, limit?: number) {
  const userId = useAppSelector(selectAppUser)?.id?.toString();
  const {
    isFetching: isFetchingWallets,
    originalArgs: getWalletsOriginalArgs,
  } = flowAPI.useGetWalletListQuery({
    userId,
  });

  const purchased = usePaginatedList(page, (skip) =>
    flowAPI.useGetPaginatedPurchasedNftListQuery({ page, limit }, { skip })
  );

  const isPurchasedFirstPageLoading = purchased.isFetching && page === 0;

  const claimed = useGetClaimedNftLists({ userId });

  const claimedFlat = claimed.map(({ data }) => data ?? []).flat();

  let data = [...purchased.data, ...claimedFlat];

  if (limit) data = data.slice(0, limit);

  /**
   * This condition is a bit confusing because there is 4 wallet fetching states
   *
   * 1. Fetching wallets with userId === undefined
   * 2. Resolving wallets with userID === undefined
   * 3. Fetching wallets with userId === <number>
   * 4. Resolving wallets with userId === <number>
   *
   * The step we care about is 4 so this is why I went with the isDoneFetchingWallets variable
   */
  const isDoneFetchingWallets =
    !isFetchingWallets && Boolean(getWalletsOriginalArgs?.userId);
  const areClaimedNftsLoading =
    claimed.some((c) => c.isLoading || c.isUninitialized) ||
    !isDoneFetchingWallets;

  const isError = purchased.isError;

  const totalUnclaimed = purchased?.total ?? 0;

  const total = totalUnclaimed + claimedFlat.length;

  return {
    data,
    isLoading: areClaimedNftsLoading || isPurchasedFirstPageLoading,
    isError,
    totalUnclaimed,
    total,
  };
}

export function useEditions(
  page = 0,
  query: {
    merchantFID?: string;
    editionFID?: string;
    name?: string;
    sort?: NftSortingOptions | null;
  }
) {
  return usePaginatedList(page, (skip) =>
    flowAPI.useGetPaginatedEditionsQuery({ page, ...query }, { skip })
  );
}
export function useItemForSale(
  page = 0,
  query: FindQuery<ItemForSale>,
  selectedFilters: PfpFilters,
  sort: NftSortingOptions | null
) {
  return usePaginatedList(page, (skip) =>
    flowAPI.useGetPaginatedItemForSaleListQuery(
      { page, query, selectedFilters, sort },
      { skip }
    )
  );
}

export function useMerchants(
  page = 0,
  query: { merchantFID?: string; profileName?: string }
) {
  return usePaginatedList(page, (skip) =>
    flowAPI.useGetPaginatedMerchantsQuery({ page, ...query }, { skip })
  );
}

export function useMarketHistory(
  page = 0,
  query: {
    sort: "top" | "recent";
    editionFID?: string;
    itemFID?: string;
    contractName?: string;
    contractAddress?: string;
  }
) {
  return usePaginatedList(page, (skip) =>
    flowAPI.useGetPaginatedMarketHistoryQuery({ page, ...query }, { skip })
  );
}

export function useSortedNftListForEdition(
  page = 0,
  query: { sort: Sort; editionFID: string },
  options?: { skip?: boolean }
) {
  return usePaginatedList(page, (skip) =>
    flowAPI.useGetPaginatedSortedNftListForEditionQuery(
      { page, ...query },
      { skip: options?.skip || skip }
    )
  );
}

type UseOnchainActionStatus = {
  isLoading: boolean;
  isDeclined: boolean;
  isError: boolean;
  isSuccess: boolean;
  reset?: () => void;
  errorMessage?: string | null;
};

type UseTransfer = [
  (
    recipientAddress: string,
    contractAddress?: string,
    contractName?: string
  ) => void,
  UseOnchainActionStatus
];
export function useTransfer(nftInfo: Flow.UrlPayload): UseTransfer {
  const localizedStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );
  const [mutation, { error, isError, reset }] =
    flowAPI.useTransferNftMutation();

  const sender = useCurrentWallet();

  const [waitForSuccess, { isLoading, isSuccess, isDeclined, errorMessage }] =
    usePollNftState(
      nftInfo,
      ({ sendToFlowAddress }) =>
        sender ? sender.address === sendToFlowAddress : false,
      error
    );

  const transferNft = (
    recipientAddress: string,
    contractAddress?: string,
    contractName?: string
  ) =>
    waitForSuccess(
      mutation({
        recipientAddress,
        senderAddress: sender?.address,
        itemFID: nftInfo.itemFID,
        contractAddress,
        contractName,
      })
    );

  useEffect(() => {
    if (isDeclined) {
      reset();
    }
  }, [isDeclined]);

  // Don't expose cadence runtime errors to users
  const transferErrorMessage = errorMessage?.includes("cadence runtime error")
    ? localizedStrings.transferFailed
    : errorMessage;

  return [
    transferNft,
    {
      isLoading,
      isDeclined,
      isError,
      errorMessage: transferErrorMessage,
      isSuccess,
      reset,
    },
  ];
}

type UsePurchase = [(nft: Flow.FullNft) => void, UseOnchainActionStatus];
export function usePurchase(nftInfo: Flow.UrlPayload): UsePurchase {
  const [mutation, { error, isError, reset }] =
    flowAPI.usePurchaseNftMutation();

  const [waitForSuccess, { isLoading, isSuccess, errorMessage, isDeclined }] =
    usePollNftState(nftInfo, ({ state }) => state.isOwner, error);

  const purchaseNft = (nft: Flow.FullNft) => waitForSuccess(mutation(nft));

  useEffect(() => {
    if (isDeclined) {
      reset();
    }
  }, [isDeclined]);

  return [
    purchaseNft,
    { isLoading, isDeclined, isError, errorMessage, isSuccess },
  ];
}

type UseListForSale = [
  (price: number, contractAddress?: string, contractName?: string) => void,
  UseOnchainActionStatus
];
export function useListForSale(nftInfo: Flow.UrlPayload): UseListForSale {
  const [mutation, { error, isError, reset }] =
    flowAPI.useListForSaleMutation();

  const [waitForSuccess, { isLoading, isSuccess, errorMessage, isDeclined }] =
    usePollNftState(nftInfo, ({ state }) => state.isForSale, error);

  const listForSale = (
    price: number,
    contractAddress?: string,
    contractName?: string
  ) =>
    waitForSuccess(
      mutation({
        itemFID: nftInfo.itemFID,
        price,
        contractAddress,
        contractName,
      })
    );

  useEffect(() => {
    if (isDeclined) {
      reset();
    }
  }, [isDeclined]);

  return [
    listForSale,
    { isLoading, isDeclined, isError, errorMessage, isSuccess, reset },
  ];
}

type UseWithdraw = [
  (contractAddress?: string, contractName?: string) => void,
  UseOnchainActionStatus
];
export function useWithdraw(nftInfo: Flow.UrlPayload): UseWithdraw {
  const [mutation, { error, isError, reset }] = flowAPI.useCancelSaleMutation();

  const [waitForSuccess, { isLoading, isSuccess, errorMessage, isDeclined }] =
    usePollNftState(nftInfo, ({ state }) => !state.isForSale, error);

  const withdraw = (contractAddress?: string, contractName?: string) =>
    waitForSuccess(
      mutation({ itemFID: nftInfo.itemFID, contractAddress, contractName })
    );

  useEffect(() => {
    if (isDeclined) {
      reset();
    }
  }, [isDeclined]);

  return [
    withdraw,
    { isLoading, isDeclined, isError, errorMessage, isSuccess, reset },
  ];
}

type UseClaim = [
  (toAddress: string, id: number) => void,
  UseOnchainActionStatus
];
export function useClaim(nftInfo: Flow.UrlPayload): UseClaim {
  const [mutation, { error, isError, reset }] = flowAPI.useClaimNftMutation();
  const [waitForSuccess, { isLoading, isSuccess, errorMessage, isDeclined }] =
    usePollNftState(
      nftInfo,
      ({ state, history }) =>
        history !== undefined && history?.length > 1 && state.isClaimed,
      error
    );

  const claim = (toAddress: string, nftId: number) =>
    waitForSuccess(
      mutation({ toAddress, nft: { id: nftId, itemFID: nftInfo.itemFID } })
    );

  useEffect(() => {
    if (isDeclined) {
      reset();
    }
  }, [isDeclined]);

  return [claim, { isLoading, isDeclined, isError, errorMessage, isSuccess }];
}

type UsePollNftState = [
  (mutationPromise: Promise<unknown>) => Promise<void>,
  {
    isLoading: boolean;
    isSuccess: boolean;
    isDeclined: boolean;
    errorMessage?: string | null;
  }
];
function usePollNftState(
  nftInfo: Flow.UrlPayload,
  stopCondition: (nftState: Flow.NftState | any) => boolean,
  error: FetchBaseQueryError | SerializedError | undefined,
  pollingInterval = 3000
): UsePollNftState {
  const language = useLanguage();
  const { errorMessage, isDeclined } = parseOnchainActionError(error, language);
  const [isLoading, setLoading] = useState(false);
  const [isPolling, setPolling] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const dispatch = useAppDispatch();

  const { data: nftState } = flowAPI.useGetFullNftQuery(nftInfo, {
    pollingInterval,
    skip: !isPolling,
  });

  useEffect(() => {
    if (isPolling && nftState?.state != null && stopCondition(nftState)) {
      setLoading(false);
      setPolling(false);
      setIsSuccess(true);
      dispatch(
        flowAPI.util.invalidateTags([
          {
            type: "FlowMintStoreNft",
            id: nftInfo.itemFID ? nftInfo.itemFID : nftInfo.uuid,
          },
          "FlowMintStoreNft",
        ])
      );
    }
  }, [nftState, isPolling]);

  useEffect(() => {
    if (isDeclined || error != null) {
      setPolling(false);
      setLoading(false);
    }
  }, [isDeclined || error != null]);

  const waitForSuccess = async (mutationPromise: Promise<unknown>) => {
    setLoading(true);
    await mutationPromise;
    setPolling(true);
  };

  return [waitForSuccess, { isLoading, isSuccess, errorMessage, isDeclined }];
}

// The current wallet depends on the wallet-endpoints section of flowAPI and the user-slice.
export const useCurrentWallet = (): Flow.Wallet | null => {
  const appUser = useAppSelector(selectAppUser);
  const flowUser = useAppSelector(selectFlowUser);

  const { data: wallets } = flowAPI.useGetWalletListQuery(
    {
      userId: appUser?.id?.toString() ?? "",
    },
    { skip: appUser == null }
  );

  if (appUser == null || flowUser == null || wallets == null) {
    return null;
  }

  return wallets.find((wallet) => wallet.address === flowUser.addr) ?? null;
};

export const useWalletSetupStatus = () => {
  const flowUser = useAppSelector(selectFlowUser);

  return flowAPI.endpoints.checkWalletSetup.useQueryState(
    flowUser
      ? {
          address: flowUser.addr,
        }
      : skipToken
  );
};

export const useRegister = (): [
  () => void,
  { isLoading: boolean; isSuccess: boolean }
] => {
  const [onLogin, { isLoading: isLoginLoading, isSuccess }] =
    flowAPI.useLoginMutation();

  // onLogin is the first step; When it is done, onRegister is triggered.
  return [onLogin, { isLoading: isLoginLoading, isSuccess }];
};
