import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import Modal from "components/Widgets/Modal";
import { ClientConfig } from "config";
import { NftPreview } from ".";
import customStyles from "./custom.module.scss";
import { Recipient } from "types/app-model/global";

type Props = {
  recipient?: Recipient;
  nft: NftPreview;
  strings: ClientConfig["Strings"]["Modals"]["Sell"]["en"];
};

export default function TransferStepLoading(props: Props) {
  const localizedStrings = props.strings.transferModal.transferStep;
  return (
    <Modal clickOutsideToClose={false} isVisible hideCloseButton>
      <h2>{localizedStrings.loadingTitle}</h2>
      <hr />
      <p>
        {props.recipient?.profileName && (
          <>
            {localizedStrings.profileName}: {props.recipient.profileName}
            <br />
          </>
        )}
        {props.recipient?.address && (
          <>
            {localizedStrings.address}: {props.recipient.address}
            <br />
          </>
        )}
      </p>
      <hr />
      <div className={customStyles.smallNftCard}>
        <SmallNFTCard nft={props.nft as NftPreview} loading />
      </div>
      <p>{localizedStrings.loadingDisclaimer}</p>
    </Modal>
  );
}
