import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import Modal from "components/Widgets/Modal";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../common.module.scss";

interface NftPreview {
  number?: number;
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
}

type Props = {
  onClose: () => void;
  onMainButtonClick: () => void;
  nft: NftPreview;
  queryResult: {
    isWithdrawLoading: boolean;
    isWithdrawSuccess: boolean;
    isWithdrawError: boolean;
    withdrawReset?: () => void;
  };
};

export default function WithdrawModal({
  queryResult,
  onClose,
  onMainButtonClick,
  nft,
}: Props) {
  const strings = getLocalizedStrings("Modals", "Withdraw", useLanguage());

  const {
    isWithdrawLoading,
    isWithdrawSuccess,
    isWithdrawError,
    withdrawReset,
  } = queryResult;

  const handleClose = () => {
    onClose();
    withdrawReset?.();
  };

  if (isWithdrawLoading) {
    return (
      <Modal isVisible clickOutsideToClose={false} hideCloseButton>
        <h2>{strings.withdrawInProgressTitle}</h2>
        <p>
          {strings.withdrawInProgressSubtitle}
          <br />
          {strings.pleaseWait}
        </p>
        <div className={styles.smallNftCard}>
          <SmallNFTCard nft={nft} loading={isWithdrawLoading} />
        </div>
        <p className={styles.error}>{isWithdrawError}</p>
      </Modal>
    );
  } else {
    return (
      <Modal
        isVisible
        onClose={() => handleClose()}
        btnDisabled={isWithdrawLoading}
        onActionClick={() => onMainButtonClick()}
        customStrings={{
          actionBtn: strings.openWallet,
        }}
      >
        <h2>
          {isWithdrawError
            ? strings.withdrawFailedTitle
            : strings.congratulations}
        </h2>
        {isWithdrawError && <p>{strings.withdrawFailedSubtitle}</p>}
        {isWithdrawSuccess && (
          <p>
            {strings.successfulWithdraw}
            <br />
            {strings.itemInWallet}
          </p>
        )}
        <div className={styles.smallNftCard}>
          <SmallNFTCard nft={nft} success={isWithdrawSuccess} />
        </div>
      </Modal>
    );
  }
}
