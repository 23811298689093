const en = {
  logout: "Logout",
  termsOfService: "Terms of Service",
  privacyPolicy: "Privacy Policy",
  faq: "FAQ",
  contactUs: "Contact Us",
  showPoweredBy: true,
  poweredBy: "Powered by",
  onFlow: "on Flow",
  home: "Home",
  walletPrvdrLink: "",
  customFooters: {
    pickem: {
      upperNav: {
        faq: "FAQ",
        termsAndConditions: "Terms and Conditions",
        contactUs: "Contact Us",
        privacyPolicy: "Privacy Policy",
        doNotSellInfo: "Do not sell my personal information",
        consumerInfo: "Consumer information",
      },
      infoSection: [
        "Always Enjoy Responsibly. © 2023 Anheuser-Busch InBev BUD LIGHT ® BEER, St. Louis, MO. Do not share this content with minors.",
        "ANHEUSER-BUSCH IS COMMITTED TO IMPROVING THE ACCESSIBILITY OF OUR WEBSITES FOR ALL USERS, INCLUDING THOSE WITH DISABILITIES. IF YOU EXPERIENCE ISSUES OR DIFFICULTIES ACCESSING ANY INFORMATION ON OUR SITE AS A RESULT OF A DISABILITY, WE WANT TO HEAR FROM YOU. PLEASE CONTACT US AT CRG@ANHEUSER-BUSCH.COM WITH YOUR CONTACT INFORMATION, THE WEB ADDRESS WHERE YOU EXPERIENCED THE ISSUE, AND A BRIEF DESCRIPTION OF THE PROBLEM. THIS EMAIL ADDRESS IS INTENDED FOR ACCESSIBILITY-RELATED INQUIRIES ONLY. IF YOU HAVE AN UNRELATED COMPLAINT OR FEEDBACK, PLEASE VISIT OUR SUPPORT PAGE.",
        "©2023 NFL Properties, LLC. All Rights Reserved.",
        "Officially Licensed Partner of the National Football League Players Association © [2023]",
        "BUD LIGHT x NFL ULTIMATE FANDOM SURVIVOR PICK'EM. NO PURCHASE NECESSARY. Begins 9/19/22 and ends on 1/8/23. Open to US residents who are 21+ at time of entry. See Official Rules at http://budlight.com/ultimatefandomrules for free method of entry, prize descriptions/restrictions/ARVs and complete details. Message and data rates may apply. Void where prohibited.",
      ],
      logout: "Logout",
    },
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
