import { ClientConfig } from "config";
import { FC, useState } from "react";
import { Flow } from "types";
import styles from "./pfp.module.scss";
import { Button, Chip, ExpansionPanels, Modal } from "components";
import { ItemOption, PanelHeader } from "components/Widgets/ExpansionPanel";
import { capitalize } from "../../../../utils/utils";
import MarketplaceUI from "../../ui";

type Props = {
  strings: ClientConfig["Strings"]["Pages"]["ArtistMarketplace"]["en"];
  items: Flow.ItemForSale[];
  loading: boolean;
  selectedFilter?: ItemOption[];
  setSelectedFilter?: (items: ItemOption[]) => void;
  onRemoveFilter?: (item: ItemOption) => void;
  total: number;
  headers: PanelHeader<Flow.FullNft["attributes"]>[];
  onLoadMore: () => void;
  searchTerm: string;
  onSearchChange: () => void;
  onSearchClear: () => void;
};

const PfpUI: FC<Props> = ({
  strings,
  items,
  total,
  headers,
  onLoadMore,
  loading,
  onSearchChange,
  onRemoveFilter = (_) => {},
  setSelectedFilter = (_) => {},
  selectedFilter = [],
  searchTerm,
}) => {
  const [openFilterModal, setOpenFilterModal] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        {selectedFilter.map((x, idx) => (
          <Chip
            key={`remove-${x.key}-${x.value}`}
            squared
            type={"custom"}
            className={`${styles.filterChip} ${
              idx % 2 === 0 ? styles.gridStart1 : styles.gridStart2
            }`}
            onRemoveClick={() => onRemoveFilter(x)}
          >
            <span>
              {capitalize(x.key)}: {x.value}
            </span>
          </Chip>
        ))}
        {selectedFilter.length > 0 && (
          <span
            onClick={() => setSelectedFilter([])}
            className={styles.clearAllLbl}
            style={{
              gridRowStart: 1,
              gridRowEnd: Math.ceil(selectedFilter?.length / 2),
            }}
          >
            {strings.clearAll}
          </span>
        )}
      </div>

      <ExpansionPanels
        className={styles.desktopOnly}
        setSelectedItems={setSelectedFilter}
        onRemove={onRemoveFilter}
        selectedItems={selectedFilter}
        headers={headers}
      />
      <Button
        type={"secondary"}
        className={styles.mobileOnly}
        onClick={() => setOpenFilterModal(true)}
      >
        {strings.filters}
      </Button>
      <Modal
        isVisible={openFilterModal}
        onActionClick={() => setOpenFilterModal(false)}
        onClose={() => setOpenFilterModal(false)}
        customStrings={{
          actionBtn: strings.done,
        }}
      >
        <h2>{strings.filters}</h2>
        <ExpansionPanels
          className={styles.mobileFilterTable}
          setSelectedItems={setSelectedFilter}
          onRemove={onRemoveFilter}
          selectedItems={selectedFilter}
          headers={headers}
        />
      </Modal>
      <div className={styles.collectionContainer}>
        <MarketplaceUI.Items
          onSearchChange={onSearchChange}
          loading={loading}
          strings={strings}
          items={items}
          total={total}
          onLoadMore={onLoadMore}
          searchTerm={searchTerm}
        />
      </div>
    </div>
  );
};

export default PfpUI;
