import * as Blockchain from "../../../global-blockchain-config";

const SERVER_API_URL =
  Blockchain.NET === "Testnet"
    ? "https://dev-testnet-api.mint.store"
    : "https://v2.api-wallet.mint.store";

const MERCHANT_LIST = {
  Testnet: [{ id: "9", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "5", address: "0xfc4b78e1076d4764" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 2, address: "0x8c0c210d007efa25" },
  Mainnet: { id: 2, address: "0xfc4b78e1076d4764" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 2,
};

export { MERCHANT_LIST, MINT_STORE_MARKET, WALLET_VIEWER_ID, SERVER_API_URL };
