import Alert from "components/Widgets/Alert";
import { getLocalizedStrings, Project } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent, useEffect, useState } from "react";
import { detectIncognito } from "detectincognitojs";

const IncognitoModeWarning: FunctionComponent = () => {
  if (!Project.WALLET_TYPE?.includes("Blocto")) {
    return <></>;
  }

  const strings = getLocalizedStrings(
    "Component",
    "IncognitoModeWarning",
    useLanguage()
  );
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    detectIncognito().then((res) => {
      setIsVisible(res.isPrivate);
    });
  }, []);

  return (
    <Alert
      style={{
        borderRadius: "0px",
        borderLeft: "none",
        borderRight: "none",
        margin: 0,
      }}
      textAlign={"center"}
      type={"warning"}
      isVisible={isVisible}
      dismissible
    >
      {strings.alertMsg}
      {/* 
        Commenting it for now but leaving here for the idea;
        I think we should make a page with some details about:
          - Why you see this warning (incognito more or maybe similar browser settings)
          - How it impacts the wallet viewer
        Some people might be freaked out what we warn them for using incognito and it would be good to explain to them why.
      */}
      {/* <a href={"#"} target={"_blank"}>
        {strings.learnMore}
      </a> */}
    </Alert>
  );
};

export default IncognitoModeWarning;
