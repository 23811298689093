import { Blockchain } from "config";

// TODO This is a temporary fix to make sure the route on flow don't break, will need to be in config
const SHOW_FLOW = ":contractAddress/:contractName/:id";
const SHOW_POLYGON = ":contractAddress/:id";
// const SHOW_FLOW_ON_DEMAND = ":contractAddress/:contractName";

export const LEGACY_SHOW = ":id";
export const SHOW =
  Blockchain.BLOCKCHAIN_NAME === "flow" ? SHOW_FLOW : SHOW_POLYGON;
// export const SHOW_ON_DEMAND = SHOW_FLOW_ON_DEMAND;
export const NFT_LIST_ROUTE = "/nft";
export const NFT_SHOW = `${NFT_LIST_ROUTE}/:smartContractAddress/:smartContractName?/:itemFID`;
export const NFT_ON_DEMAND_SHOW = `/minting-nft/:smartContractAddress/:smartContractName/:uuid`;
export const PUBLIC_NFT_ROUTE = "/public/nft";
export const LOGIN_ROUTE = "/login";
export const MAGIC_LOGIN_ROUTE = "/login-with-link";
export const TERMS_ROUTE = "/terms-of-service";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const FAQ_ROUTE = "/frequently-asked-questions";
export const FAQ_ZENDESK = "https://mintstore.zendesk.com/hc/en-us";
// export const MERCHANTS_ROUTE = "/merchants";
export const WITHDRAW_FUNDS_ROUTE = "/withdraw-funds";

/////////////new Routes adeed/////////////////////
export const EDITION_DETAILS = "/editions";
export const SHOW_SALES_HISTORY = `:type/${SHOW}`;
export const SALES_HISTORY_ROUTE = `/sales-history`;
export const VERSION_ROUTE = "/version";

export const MARKETPLACE_ROUTE = "/marketplace";
export const MARKETPLACE_ROUTES = {
  CREATORS: "/marketplace/creators",
};
export const MARKETPLACE_MERCHANT_ROUTE = `${MARKETPLACE_ROUTE}/merchant/:id/:profileName`;
export const MARKETPLACE_PFP_ROUTE = `pfp/:smartContractAddress/:smartContractName`;
export const REDEMPTION_NFT_ROUTE = "/redemption/:redemptionCode";
export const REDEEM_ROUTE = "/redeem";
export const UTILITY_ROUTE = "/utility";
export const UTILITY_ROUTES = {
  PRODUCTS: "/utility/products",
  PLAYLISTS: "/utility/playlists",
  VIDEOS: "/utility/videos",
  FILES: "/utility/files",
};
export const PLAYLIST_ROUTE = "/playlist";
export const GATED_ITEM_ROUTE = "/product/:handle";
export const GATED_PLAYLIST_ROUTE = "/playlist/:id";
export const GATED_VIDEO_ROUTE = "/video/:id";
export const GATED_FILE_ROUTE = "/file/:id";
