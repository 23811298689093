import { Config } from "config";
import { nflTeams } from "config/clients/pickem/customizable/client";
import React from "react";
import { formatNftName } from "utils/utils";

interface Props {
  name: string;
  itemID?: string;
  className?: string;
}

export const CustomPickemNftName = React.memo(
  ({ name, itemID, className }: Props) => {
    const teamAbbr = name.split(" ")[0];
    const team = nflTeams.find((team) => team.abbr === teamAbbr);

    if (team) {
      return (
        <h1>
          {team.city} {team.name} {itemID?.padStart(6, "0")}
        </h1>
      );
    } else {
      return name.length > 0 ? (
        <h1 className={className ?? ""}>
          {name} {itemID?.padStart(6, "0")}
        </h1>
      ) : (
        <></>
      );
    }
  }
);

export const NftDisplayName = (props: Props) => {
  switch (Config.Client.CUSTOM_NFT_NAME) {
    case "pickem":
      return <CustomPickemNftName {...props} />;
    default:
      return props.name.length > 0 ? (
        <h1>{formatNftName(props.name)}</h1>
      ) : (
        <></>
      );
  }
};
