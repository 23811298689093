const en = {
  claimInProgressTitle: "Claiming in Progress",
  claimInProgressSubtitle: "This could take a few minutes.",
  congratulations: "Congratulations!",
  successfulClaim: "Claim was successful.",
  pleaseWait: "Please wait...",
  unsuccessfulTitle: "Claim unsuccessful",
  itemInWallet: "The item is now in your wallet.",
  unsuccessfulSubtitle: "An error has occured.",
  tryAgainLater: "Please try again in a few minutes.",
  mustLogin: "Must be logged in to the Flow blockchain to proceed.",
  openWallet: "Open wallet",
  claimBtnTxt: "Claim more",
  gameLinkTxt: "",
  redirectMessage: "You are now being redirected...",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
