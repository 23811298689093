import { ButtonHref } from "components";
import { CustomizedClaimModal } from "components/AppComponent/ActionModals/Claim/wrapper";
import TransferModal from "components/AppComponent/ActionModals/Transfer";
import { Blockchain, CLIENT, Project } from "config";
import { NFT_LIST_ROUTE } from "config/routes";
import { ethAPI } from "lib/store/api/eth";
import {
  useClaim,
  useCurrentWallet,
  useTransfer,
} from "lib/store/api/eth/hooks";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser, selectEthUser } from "lib/store/slices/user-slice";
import { useState } from "react";
import { ClaimStepList, TokenInWalletSection } from "../components";
import { ModalVisible } from "../NftShowPage";
import { NftShowUI } from "../ui/NftShowUI";
import { ChangeWalletButtonsEthConnector } from "./ChangeWalletButtonsEthConnector";
import { ClaimButtonEthConnector } from "./ClaimButtonEthConnector";
import { TransferButtonEthConnector } from "./TransferButtonEthConnector";

export function NftShowEthConnector({
  itemEID,
  contractAddress,
}: {
  itemEID: string;
  contractAddress: string;
}) {
  const {
    data: nft,
    isLoading: isNftLoading,
    isError,
  } = ethAPI.useGetNftQuery({ itemEID, contractAddress });

  const [modalVisible, setModalVisible] = useState<ModalVisible | null>(null);

  const user = useAppSelector(selectEthUser);

  // We know that NftShowPage's children depend on this hook to decide how they are displayed.
  // But I don't want each of the child to handle their own loading, so I decided to handle it
  // one time here. Is that too much leaking or is that ok with a Connector?
  const { isLoading: isStateLoading, data: nftState } =
    ethAPI.useGetNftStateQuery({
      itemEID,
      contractAddress,
      user,
    });

  const [
    claim,
    {
      isSuccess: isClaimSuccess,
      isError: isClaimError,
      isLoading: isClaimLoading,
    },
  ] = useClaim(itemEID, contractAddress);

  const onClaimButtonClick = (walletAddress: string, nftID: number) => {
    claim(walletAddress, nftID);
  };

  const shareLink = `${window.location.host}/public${NFT_LIST_ROUTE}/${contractAddress}/${itemEID}`;

  const [displayUnclaimableMessage, setDisplayUnclaimableMessage] = useState<
    string | null
  >(null);

  const wallet = useCurrentWallet();
  const isCurrentWalletNftOwner = Boolean(
    wallet?.address === nft?.currentAddress
  );

  const [
    transfer,
    {
      isLoading: isTransferLoading,
      isSuccess: isTransferSuccess,
      errorMessage: transferErrorMessage,
      reset: transferReset,
    },
  ] = useTransfer(itemEID, contractAddress);

  const [
    onGetRecipient,
    {
      data: recipient,
      isLoading: isLoadingRecipient,
      isUninitialized: isRecipientUninitialized,
      reset: recipientReset,
    },
  ] = ethAPI.useGetRecipientMutation();

  const onTransferClick = async () => {
    if (recipient?.address) {
      if (wallet?.walletType == "Blocto") {
        transfer(recipient.address);
      } else if (wallet?.walletType == "MetaMask") {
        transfer(recipient.address);
      }
    }
  };

  const recipientStep = {
    onGetRecipient,
    recipient,
    isLoadingRecipient,
    isRecipientUninitialized,
    recipientReset,
  };

  const transferStep = {
    isTransferLoading,
    isTransferSuccess,
    transferErrorMessage,
    transferReset,
    transfer: onTransferClick,
  };

  const appUser = useAppSelector(selectAppUser);
  const userId = appUser ? String(appUser.id) : undefined;
  const { data: wallets } = ethAPI.useGetWalletListQuery({ userId });
  const isUsersNft = Boolean(
    wallets?.find((wallet) => wallet.address === nft?.currentAddress)
  );

  const displayChangeWalletButton = Boolean(
    appUser &&
      wallet &&
      isUsersNft &&
      !isCurrentWalletNftOwner &&
      nft?.state.isClaimed
  );
  const displayConnectWallet = Boolean(appUser && !wallet?.address);
  const isNftClaimed = !!nft?.state?.isClaimed;
  const renderClaimStepList = !!nft?.state?.isOwner && !isNftClaimed;
  const contractAddressLink = `${Project.POLYGON_CONTRACT_ADDRESS_LINK}${nft?.contractAddress}`;

  return (
    <>
      <NftShowUI
        nft={nft}
        isLoading={isNftLoading || isStateLoading}
        isError={isError}
        nftState={nftState}
        shareLink={shareLink}
        displayUnclaimableMessage={displayUnclaimableMessage}
        setDisplayUnclaimableMessage={setDisplayUnclaimableMessage}
        wallet={wallet}
        isCurrentWalletNftOwner={isCurrentWalletNftOwner}
        currentOwnerAddress={nft?.currentAddress}
      >
        {!renderClaimStepList && (
          <>
            {Project.WALLET_TYPE &&
              (displayChangeWalletButton || displayConnectWallet) && (
                <ChangeWalletButtonsEthConnector
                  currentOwnerAddress={nft?.currentAddress}
                  currentWalletAddress={wallet?.address}
                  displayChangeWalletButton={displayChangeWalletButton}
                  displayConnectWallet={displayConnectWallet}
                />
              )}
            <ClaimButtonEthConnector
              itemEID={itemEID}
              contractAddress={contractAddress}
              claim={onClaimButtonClick}
              setDisplayUnclaimableMessage={setDisplayUnclaimableMessage}
              setModalVisible={setModalVisible}
            />
            <TransferButtonEthConnector
              itemEID={itemEID}
              contractAddress={contractAddress}
              setModalVisible={setModalVisible}
            />
            {/* Axiom Discord link */}
            {CLIENT === "axiomspace" && (
              <>
                <ButtonHref
                  href="https://discord.gg/ct9Tf2XDmE"
                  target="_blank"
                  type="secondary"
                >
                  Join Discord
                </ButtonHref>
              </>
            )}
          </>
        )}
        {renderClaimStepList && (
          <ClaimStepList
            wallet={wallet}
            renderClaimStepList={renderClaimStepList}
          >
            {!wallet?.address && (
              <ChangeWalletButtonsEthConnector
                currentOwnerAddress={nft?.currentAddress}
                currentWalletAddress={wallet?.address}
                displayChangeWalletButton={displayChangeWalletButton}
                displayConnectWallet={displayConnectWallet}
              />
            )}
            {wallet?.address && (
              <ClaimButtonEthConnector
                itemEID={itemEID}
                contractAddress={contractAddress}
                claim={onClaimButtonClick}
                setDisplayUnclaimableMessage={setDisplayUnclaimableMessage}
                setModalVisible={setModalVisible}
              />
            )}
          </ClaimStepList>
        )}
        {nft && nft.state?.isOwner && isNftClaimed && <hr />}

        {nft && nft.state?.isOwner && isNftClaimed && (
          <TokenInWalletSection
            contractAddressLink={contractAddressLink}
            blockChainName={Blockchain.BLOCKCHAIN_NAME}
          />
        )}
      </NftShowUI>
      {nft && (
        <>
          {modalVisible === "claim" && (
            <CustomizedClaimModal
              onClose={() => setModalVisible(null)}
              nft={nft}
              queryResult={{ isClaimError, isClaimLoading }}
            />
          )}
          {modalVisible === "transfer" && (
            <TransferModal
              onClose={() => {
                setModalVisible(null);
              }}
              nft={nft}
              recipientStep={recipientStep}
              transferStep={transferStep}
            />
          )}
        </>
      )}
    </>
  );
}
