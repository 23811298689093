import { FunctionComponent } from "react";
import MarketplaceUI, { useMarketplaceContext } from "../../ui";
import {
  useEditions,
  useMerchants,
} from "../../../../lib/store/api/flow/hooks";

const EditionsView: FunctionComponent = () => {
  const {
    searchTerm,
    strings,
    sorting,
    editionsPage,
    setEditionsPage,
    merchantsPage,
    setMerchantsPage,
  } = useMarketplaceContext();

  const {
    data: editions,
    isLoading: editionsLoading,
    total: totalEditions,
  } = useEditions(editionsPage, {
    name: searchTerm,
    sort: sorting,
  });

  const {
    data: merchants,
    isLoading: merchantLoading,
    total: totalMerchant,
  } = useMerchants(merchantsPage, {
    profileName: searchTerm,
  });

  const onSearchChange = () => {
    setEditionsPage(0);
    setMerchantsPage(0);
  };
  return (
    <>
      <MarketplaceUI.Merchants
        total={totalMerchant}
        onSearchChange={onSearchChange}
        loading={merchantLoading}
        items={merchants}
        strings={strings}
        searchTerm={searchTerm}
        onLoadMore={() => setMerchantsPage(merchantsPage + 1)}
      />
      <MarketplaceUI.Editions
        loading={editionsLoading}
        onSearchChange={onSearchChange}
        strings={strings}
        searchTerm={searchTerm}
        onLoadMore={() => setEditionsPage(editionsPage + 1)}
        total={totalEditions}
        items={editions}
      />
    </>
  );
};

export default EditionsView;
