import { ClientConfig } from "config";
import { FunctionComponent, ReactNode } from "react";
import { Flow } from "types";
import styles from "./styles.module.scss";
import { Outlet, useOutletContext } from "react-router-dom";
import Editions from "./template/editions";
import { HeadingProps, Merchants, Header } from "./template/index";
import Items from "./template/items";
import { UIProps } from "./template/types";

type UiChildrenComponents = {
  Header: FunctionComponent<HeadingProps>;
  Merchants: FunctionComponent<UIProps<Flow.Merchant>>;
  Editions: FunctionComponent<UIProps<Flow.EditionForSale>>;
  Items: FunctionComponent<UIProps<Flow.ItemForSale>>;
};

export type MarketplaceProps = {
  children?: ReactNode;
  onSearchClear: () => void;
  onSearchChange?: () => void;
  sorting: NftSortingOptions | null;
  strings: ClientConfig["Strings"]["Pages"]["ArtistMarketplace"]["en"];
  searchTerm: string;
};

type QueryProps = {
  editionsPage: number;
  merchantsPage: number;
  setEditionsPage: (page: number) => void;
  setMerchantsPage: (page: number) => void;
};

const MarketplaceUI: FunctionComponent<MarketplaceProps & QueryProps> &
  UiChildrenComponents = (props) => {
  return (
    <div className={styles.marketplacePage}>
      <div className={styles.container}>
        {props.children}
        <Outlet context={{ ...props }}></Outlet>
      </div>
    </div>
  );
};
MarketplaceUI.Header = Header;
MarketplaceUI.Merchants = Merchants;
MarketplaceUI.Editions = Editions;
MarketplaceUI.Items = Items;

export function useMarketplaceContext() {
  return useOutletContext<MarketplaceProps & QueryProps>();
}

export default MarketplaceUI;
