import { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useLanguage } from "lib/contexts/LanguageContext";
import Modal from "components/Widgets/Modal";
import styles from "../common.module.scss";
import bounceEffect from "./bounceEffect.module.scss";
import { getLocalizedStrings } from "config";

type Props = { isVisible: boolean; onClose: () => void; link: string };

export default function ShareModal({ isVisible, onClose, link }: Props) {
  const strings = getLocalizedStrings("Modals", "Sell", useLanguage());
  const transitionRef = useRef(null);
  const [success, setSuccess] = useState<boolean>(false);

  async function copyTextToClipBoard() {
    await navigator.clipboard.writeText(link);
    setSuccess(!success);
    onClose();
    setTimeout(() => setSuccess(false), 1000);
  }

  return (
    <>
      <Modal
        isVisible={isVisible}
        onClose={onClose}
        onActionClick={copyTextToClipBoard}
        onCancelClick={onClose}
        customStrings={{
          actionBtn: strings.copyLink,
          cancelBtn: strings.cancel,
        }}
      >
        <h2>{strings.shareModalTitle}</h2>
        <p className="mt-2">{strings.shareModalSubtitle}</p>
      </Modal>
      <CSSTransition
        nodeRef={transitionRef}
        in={success}
        timeout={2000}
        classNames={bounceEffect}
        unmountOnExit
      >
        <div ref={transitionRef} className={`${styles.successMsg}`}>
          <p>{strings.copiedToClipBoard}</p>
        </div>
      </CSSTransition>
    </>
  );
}
