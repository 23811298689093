import {
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  Info,
  CheckCircle,
} from "react-feather";
import LoaderCircle from "components/Widgets/LoaderCircle";
import { useContext } from "react";
import { AppContext } from "context/AppContext";
import parse from "html-react-parser";
import Modal from "components/Widgets/Modal";

export function AlertModal() {
  const appContext = useContext(AppContext) as AppContext;

  const renderIcon = () => {
    let icon;
    switch (appContext.alert.content.data?.type) {
      case "success":
        icon = <CheckCircle size={50} />;
        break;
      case "info":
        icon = <Info size={50} />;
        break;
      case "warning":
        icon = <AlertTriangle size={50} />;
        break;
      case "danger":
        icon = <AlertOctagon size={50} />;
        break;
      default:
        icon = <AlertCircle size={50} />;
        break;
    }
    return <div>{icon}</div>;
  };

  return (
    <Modal
      clickOutsideToClose={false}
      isVisible={appContext.alert.display.isVisible}
      onClose={() => {
        appContext.alert.display.setIsVisible(false);
        appContext.alert.callbacks.onClose();
      }}
      onActionClick={
        appContext.alert.content.data?.onClick &&
        appContext.alert.content.data.onClick
      }
      customStrings={{
        actionBtn: appContext.alert.content.data?.btnTxt,
      }}
    >
      {appContext.alert.content.data?.isLoading ? (
        <LoaderCircle size={50} />
      ) : (
        renderIcon()
      )}

      <h2>{appContext.alert.content.data?.title}</h2>
      <p className="ma-0 text-center">
        {appContext.alert.content.data?.body &&
          parse(appContext.alert.content.data?.body)}
      </p>
    </Modal>
  );
}
