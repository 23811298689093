import SmallNFTCard from "components/AppComponent/SmallNFTCard";
import Modal from "components/Widgets/Modal";
import { ClientConfig } from "config";
import { Info } from "react-feather";
import { NftPreview } from ".";
import customStyles from "./custom.module.scss";
import TransferStepLoading from "./TransferStepLoading";
import { useNavigate } from "react-router-dom";
import { NFT_LIST_ROUTE } from "config/routes";
import { Recipient } from "types/app-model/global";

export type TransferQueryInfo = {
  isTransferLoading: boolean;
  isTransferSuccess: boolean;
  transferErrorMessage?: string | null;
  transferReset?: () => void;
  transfer: () => void;
};

type Props = {
  transferStep: TransferQueryInfo;
  setRecipientValue: React.Dispatch<React.SetStateAction<string>>;
  recipient?: Recipient;
  recipientReset?: () => void;
  nft: NftPreview;
  onClose: () => void;
  strings: ClientConfig["Strings"]["Modals"]["Sell"]["en"];
};

export default function TransferStep(props: Props) {
  const localizedStrings = props.strings.transferModal.transferStep;
  const navigate = useNavigate();

  if (props.transferStep.isTransferLoading) {
    return (
      <TransferStepLoading
        nft={props.nft}
        strings={props.strings}
        recipient={props.recipient}
      />
    );
  } else if (props.transferStep.isTransferSuccess) {
    const _onClose = () => {
      props.recipientReset?.();
      props.transferStep.transferReset?.();
      props.onClose();
      navigate(NFT_LIST_ROUTE);
    };
    return (
      <Modal
        clickOutsideToClose={false}
        isVisible
        onActionClick={_onClose}
        onClose={_onClose}
      >
        <h2>
          {props.transferStep.isTransferSuccess
            ? localizedStrings.successTitle
            : localizedStrings.title}
        </h2>
        <p>{localizedStrings.successSubtitle}</p>

        <hr />
        <p>
          {props.recipient?.profileName && (
            <>
              {localizedStrings.profileName}: {props.recipient.profileName}
              <br />
            </>
          )}
          {props.recipient?.address && (
            <>
              {localizedStrings.address}: {props.recipient.address}
              <br />
            </>
          )}
        </p>
        <hr />

        <div className={customStyles.smallNftCard}>
          <SmallNFTCard nft={props.nft as NftPreview} />
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        clickOutsideToClose={false}
        isVisible
        onClose={() => {
          props.recipientReset?.();
          props.transferStep.transferReset?.();
          props.onClose();
        }}
        onActionClick={props.transferStep.transfer}
        onCancelClick={() => {
          props.transferStep.transferReset?.();
          props.recipientReset?.();
        }}
        customStrings={{
          actionBtn: localizedStrings.transferBtn,
          cancelBtn: localizedStrings.changeInput,
        }}
      >
        <h2>
          {props.transferStep.transferErrorMessage
            ? localizedStrings.errorTitle
            : localizedStrings.title}{" "}
        </h2>
        <p>
          {props.transferStep.transferErrorMessage
            ? localizedStrings.errorSubtitle
            : localizedStrings.confirmSubtitleWithUser}
        </p>
        <hr />
        <p>
          {props.recipient?.profileName && (
            <>
              {localizedStrings.profileName}: {props.recipient.profileName}
              <br />
            </>
          )}
          {props.recipient?.address && (
            <>
              {localizedStrings.address}: {props.recipient.address}
              <br />
            </>
          )}
        </p>
        <hr />
        <div className={customStyles.smallNftCard}>
          <SmallNFTCard nft={props.nft as NftPreview} />
        </div>
        {props.transferStep.transferErrorMessage && (
          <p className={customStyles.errorMessage}>
            <span>
              <Info size={16} /> {props.transferStep.transferErrorMessage}
            </span>
          </p>
        )}
      </Modal>
    );
  }
}
