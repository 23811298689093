const en = {
  locked: "Locked",
  howToUnlock: "How to unlock this item?",
  defaultMessage: "Sorry! There are no instructions available at the moment.",
  modalBtnTxt: "Got it!",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
