import { UtilityItem } from "..";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../styles.module.scss";
import CollectiblesSection from "components/Widgets/Collectibles/CollectiblesSection";
import Collectible from "components/Widgets/Collectibles/Collectible";

type Props = {
  streams: UtilityItem[];
};

export default function Discord({ streams }: Props) {
  const strings = getLocalizedStrings("Pages", "Utility", useLanguage());

  return (
    <section className={styles.sectionContainer}>
      <CollectiblesSection
        title={strings.discord.title}
        description={strings.discord.description}
      >
        {streams.map((stream, i) => (
          <Collectible
            key={i}
            title={stream.name ?? ""}
            description={stream.description ?? ""}
            imageSrc={stream.thumbnail ?? ""}
            onClick={() => {
              // Temporary redirection for the demo, will be improved soon (or totally changed when we implement streams for good)
              if (stream.link && stream.link.length) {
                window.location.href = stream.link;
              }
            }}
          />
        ))}
      </CollectiblesSection>
    </section>
  );
}
