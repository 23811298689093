const en = {
  myProfileLink: "My Profile",
  myDapperWalletLink: "My Dapper Wallet",
  withdrawLink: "How do I withdraw?",
  showWalletSetupStatus: "Show Wallet Setup Status",
  signOut: "Sign Out",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
