const en = {
  of: "of",
  steps: "steps",
  step: "step",
  completed: "completed",
  completeProfile: "Complete Profile",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
