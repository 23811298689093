import { Config, getLocalizedStrings } from "config";
import { GATED_FILE_ROUTE } from "config/routes";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useRulesets } from "lib/store/api/ruleset/hooks";
import { useUtilityFiles } from "lib/store/api/utility/hooks";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import UtilitySubpageUI, { Item } from "./ui";

const FilesPage: FunctionComponent = () => {
  const strings = getLocalizedStrings("Pages", "Utility", useLanguage());
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const { data, total, isFetching, isError, rulesetIds } =
    useUtilityFiles(page);
  const { data: rulesets, isFetching: isRulesetsFetching } =
    useRulesets(rulesetIds);

  const items: Item[] | undefined = data.map((file) => {
    const ruleset = rulesets?.find((rs) => rs.id === file.rulesetId);
    const isAvailable = !!ruleset?.ruleSetResult.isValid;

    return {
      title: file.name ?? "",
      description: file.description ?? "",
      imageSrc: file.thumbnail ?? "",
      onClick: () => {
        navigate(GATED_FILE_ROUTE.replace(":id", file.uuid));
      },
      isAvailable,
    };
  });

  return (
    <UtilitySubpageUI
      title={strings.archive.title}
      description={strings.archive.description}
      isFetching={isFetching || isRulesetsFetching}
      isError={isError}
      data={items}
      dataLength={data.length ?? 0}
      hasMore={total > (data.length ?? 0)}
      hideDescription={Config.Utility.HIDE_FILE_DESCRIPTION}
      onLoadMore={() => setPage((p) => p + 1)}
    />
  );
};

export default FilesPage;
