import { Button, CodeInput } from "components";
import { Config, getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent, useEffect, useState } from "react";
import styles from "../styles.module.scss";
import { Credentials } from "components/AppComponent/ActionModals/Login/types";
import { authenticationAPI } from "lib/store/api/authentication-api";
import { AppUser } from "lib/store/slices/user-slice";

type Props = {
  email: string;
  onActionClick: () => void;
  setCredentials: React.Dispatch<React.SetStateAction<Credentials | undefined>>;
  user: AppUser | null;
};

const CodeStep: FunctionComponent<Props> = ({
  email,
  onActionClick,
  setCredentials,
  user,
}) => {
  const strings = getLocalizedStrings("Pages", "Redeem", useLanguage());
  const [code, setCode] = useState(["", "", "", "", "", ""]);

  const [loginWithCode, { isLoading, isError }] =
    authenticationAPI.useLazyLoginWithCodeQuery();

  useEffect(() => {
    const _code = code.join("");
    if (_code.length >= Config.Email.LOGIN_CODE_LENGTH) {
      setCredentials((prev) => {
        if (prev) return { ...prev, code: _code };
      });

      runAction();

      // @ts-ignore
      document.activeElement?.blur();
    }
  }, [code]);

  useEffect(() => {
    onActionClick();
  }, [user]);

  const runAction = async () => {
    const _code = code.join("");
    await loginWithCode({ email, code: _code });
    setCode(["", "", "", "", "", ""]);
  };

  return (
    <>
      <h2>{strings.code.title}</h2>
      <p>
        {strings.code.text}
        <br />
        <i>{strings.code.warning}</i>
      </p>

      <div className={styles.codeContainer}>
        <CodeInput
          errorMsg={isError ? strings.code.error : ""}
          code={code}
          setCode={setCode}
        />
      </div>

      <Button type="primary" onClick={runAction} disabled={isLoading || !!user}>
        {strings.code.actionBtn}
      </Button>
    </>
  );
};

export default CodeStep;
