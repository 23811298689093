import { useState } from "react";
import { getLocalizedStrings } from "config";
import Chip from "components/Widgets/Chip";
import Dropdown from "components/Widgets/Dropdown";
import Option from "components/Widgets/Dropdown/Option/option";
import styles from "./custom.module.scss";
import { useLanguage } from "lib/contexts/LanguageContext";

type SortBy = "editionNumber" | "price";
type SortOrder = "highest" | "lowest";
export type Sort = { by: SortBy; order: SortOrder };

type Props = {
  total: number;
  onSort: (sort: Sort) => void;
};

export function SortHeader(props: Props) {
  const localizedStrings = getLocalizedStrings(
    "Modals",
    "SelectEdition",
    useLanguage()
  );
  const [sort, setSort] = useState<Sort>({ by: "price", order: "lowest" });

  function handleSortChange(newSort: Partial<Sort>) {
    const hasChanged = (field: "by" | "order") =>
      newSort[field] != null && newSort[field] !== sort[field];

    if (hasChanged("by") || hasChanged("order")) {
      setSort({ ...sort, ...newSort });
      props.onSort({ ...sort, ...newSort });
    }
  }

  return (
    <header>
      <div className={styles.sort}>
        <div>
          <Chip
            type={sort.by === "editionNumber" ? "primary" : "secondary"}
            onClick={() => handleSortChange({ by: "editionNumber" })}
          >
            {localizedStrings.edition}
          </Chip>
          <Chip
            type={sort.by === "price" ? "primary" : "secondary"}
            onClick={() => handleSortChange({ by: "price" })}
          >
            {localizedStrings.price}
          </Chip>
        </div>
        {
          <Dropdown
            onSelect={(option) =>
              handleSortChange({ order: option?.value as SortOrder })
            }
            defaultSelection="lowest"
            className={styles.dropdown}
            maxWidth="fit-content"
            fieldInputClassname={styles.selectEditionDropdown}
          >
            <Option
              value="lowest"
              display={localizedStrings.lowestPriceOption}
            />
            <Option
              value="highest"
              display={localizedStrings.highestPriceOption}
            />
          </Dropdown>
        }
      </div>
    </header>
  );
}
