import { Button } from "components";
import { Config, getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { FunctionComponent } from "react";

type Props = {
  onActionClick: () => void;
};

const GreetingStep: FunctionComponent<Props> = ({ onActionClick }) => {
  const strings = getLocalizedStrings("Pages", "Redeem", useLanguage());
  const hideTeamName = Config.Client.HIDE_LOGIN_PAGE_TEAM_NAME;

  return (
    <>
      <h1>
        {!hideTeamName && Config.Client.TEAM_NAME}
        <br />
        <span>{strings.greeting.collectible}</span>
      </h1>
      <p>{strings.greeting.text}</p>

      <Button type="primary" onClick={onActionClick}>
        {strings.greeting.actionBtn}
      </Button>
    </>
  );
};

export default GreetingStep;
