import { getLocalizedStrings } from "config";
import { NFT_LIST_ROUTE } from "config/routes";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../styles.module.scss";
import { Flow } from "types";
import CollectiblesSection from "components/Widgets/Collectibles/CollectiblesSection";
import Collectible from "components/Widgets/Collectibles/Collectible";
import { useAppNavigation } from "../../../hooks/useNavigation";

type Props = {
  nftCollection: (Flow.PurchasedNft | Flow.AppNftResult)[];
};

export default function NFTCollections({ nftCollection }: Props) {
  const strings = getLocalizedStrings("Pages", "Utility", useLanguage());

  const navigate = useNavigate();
  const navigator = useAppNavigation();

  return (
    <section className={styles.sectionContainer}>
      <CollectiblesSection
        title={strings.nftCollections.title}
        description={strings.nftCollections.description}
        displayShowAll={true}
        showAllText={strings.nftCollections.showAllText}
        onShowAll={() => navigate(NFT_LIST_ROUTE)}
      >
        {nftCollection.map((nft, i) => (
          <Collectible
            key={i}
            title={nft.name ?? ""}
            description={nft.description ?? ""}
            imageSrc={nft.thumbnail ?? ""}
            onClick={() => navigator.goToFlowNftPage(nft)}
            displayUnclaimedStatus={
              "mintingStatus" in nft && nft.mintingStatus === "PURCHASED"
            }
          />
        ))}
      </CollectiblesSection>
    </section>
  );
}
