import { SmartContract } from "types";
import * as Blockchain from "../../../global-blockchain-config";

const SERVER_API_URL =
  Blockchain.NET === "Testnet"
    ? "https://dev-testnet-api.mint.store"
    : "https://v2.api-wallet.mint.store";

const MERCHANT_LIST = {
  Testnet: [{ id: "9", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "77", address: "0x86dad8fab9647294" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 13, address: "0x86dad8fab9647294" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 19,
};

const SMART_CONTRACTS: SmartContract[] = [
  {
    id:
      Blockchain.NET === "Testnet"
        ? "786aa074-64d0-493a-823b-e7d36fa53bff"
        : "7764d1a0-bf2e-4da1-9e57-ba7c208cd935",
    name: "MintStoreItem",
    type: "Edition",
    address:
      Blockchain.NET === "Testnet"
        ? "0x985d410b577fd4a1"
        : "0x20187093790b9aef",
    publicCollectionStoragePath: "MintStoreItemCollectionPublic",
    providerPath: "MintStoreItemCollection",
  },
];

export {
  MERCHANT_LIST,
  MINT_STORE_MARKET,
  WALLET_VIEWER_ID,
  SERVER_API_URL,
  SMART_CONTRACTS,
};
