const en = {
  withdrawInProgressTitle: "Withdraw in Progress",
  withdrawInProgressSubtitle: "This could take a few minutes.",
  pleaseWait: "Please wait...",
  congratulations: "Congratulations!",
  successfulWithdraw: "Withdraw was successful.",
  itemInWallet: "The item is now in your wallet.",
  sessionExpired: "Blocto session expired. Please try again to log back in.",
  withdrawFailedTitle: "Withdraw unsuccessful",
  withdrawFailedSubtitle: "The transaction was not executed.",
  pleaseRefresh: "Please refresh and try again.",
  mustLogin: "Must be logged in to the Flow blockchain to proceed.",
  openWallet: "Open wallet",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
