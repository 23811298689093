import Button from "components/Widgets/Button";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { ModalVisible } from "../NftShowPage";

interface NftPreview {
  thumbnail?: string | null;
  name?: string | null;
  editionNumber: string | number | null;
  printingLimit: number | null;
}

type Props = {
  disabled: boolean;
  nft: NftPreview;
  onClick: () => void;
  setModalVisible: React.Dispatch<React.SetStateAction<ModalVisible | null>>;
};

export function WithdrawButtonUI(props: Props) {
  const localizedStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );
  return (
    <>
      <Button
        type="primary"
        disabled={props.disabled}
        onClick={() => {
          props.onClick();
          props.setModalVisible("withdraw");
        }}
      >
        {localizedStrings.withdraw}
      </Button>
    </>
  );
}
