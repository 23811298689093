import { Config, getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import styles from "../styles.module.scss";
import CollectiblesSection from "components/Widgets/Collectibles/CollectiblesSection";
import Collectible from "components/Widgets/Collectibles/Collectible";
import { useNavigate } from "react-router-dom";
import { GATED_FILE_ROUTE, UTILITY_ROUTES } from "config/routes";
import { Ruleset, Utility } from "types";
import { Lock } from "react-feather";

type Props = {
  archives: Utility.File[];
  rulesets: Ruleset.Validation[] | undefined;
};

export default function Archive({ archives, rulesets }: Props) {
  const strings = getLocalizedStrings("Pages", "Utility", useLanguage());
  const navigate = useNavigate();

  return (
    <section className={styles.sectionContainer}>
      <CollectiblesSection
        title={strings.archive.title}
        description={strings.archive.description}
        displayShowAll={true}
        onShowAll={() => navigate(UTILITY_ROUTES.FILES)}
      >
        {archives.map((file, i) => {
          const ruleset =
            rulesets?.find((rs) => rs.id === file.rulesetId) ?? null;
          const isAvailable = !!ruleset?.ruleSetResult.isValid;

          return (
            <Collectible
              key={i}
              title={file.name ?? ""}
              description={file.description ?? ""}
              imageSrc={file.thumbnail ?? ""}
              onClick={() =>
                navigate(GATED_FILE_ROUTE.replace(":id", file.uuid))
              }
              hideDescription={Config.Utility.HIDE_FILE_DESCRIPTION}
              label={
                !isAvailable ? (
                  <>
                    <Lock size={10} /> {strings.locked}
                  </>
                ) : undefined
              }
            />
          );
        })}
      </CollectiblesSection>
    </section>
  );
}
