import feathers from "@feathersjs/client";
import rest from "@feathersjs/rest-client";
import { captureException } from "@sentry/react";
import axios, { AxiosError } from "axios";
import { Blockchain, Project } from "config";
import jwtDecode from "jwt-decode";
import {
  Eth,
  Flow,
  MintingStatus,
  PrimaryWallet,
  RedemptionCodeData,
  Response,
  Ruleset,
  User,
  UserRedemptionStatusData,
  Utility,
} from "../types";
import { ItemForSale } from "../types/app-model/flow";
import { FindQuery, FindResponse } from "../types/database/response";
import { Recipient } from "types/app-model/global";

interface LoginService {
  create: (option: {
    email: string;
    template: string;
    origin: string;
    redirectionUrl: string;
  }) => Response.LoginCode;
}
interface UserService {
  patch: (
    userId: string,
    fields: { profileName?: string; profileImg?: string | null }
  ) => Promise<User>;
  get: (id: string) => Promise<User>;
}
interface ItemForSaleService {
  find: (options: {
    query: FindQuery<
      Omit<ItemForSale, "merchantFID"> & { merchantFID: string[] }
    >;
  }) => Promise<FindResponse<Flow.ItemForSale>>;
}
interface ItemForSaleByStreakService {
  get: (id: string) => Promise<Flow.ItemForSaleStreak>;
}

interface WizardService {
  patch: (
    id: string,
    fields: { hasDoneProfile?: boolean; hasAddFunds?: boolean }
  ) => Promise<unknown>;
}

interface PrimaryProfileService {
  find: (options: {
    query: {
      profileName?: string;
      address?: string;
    };
  }) => Promise<FindResponse<PrimaryWallet>>;
}

interface MarketStatusService {
  find: (options: {
    query: {
      marketAddress: string;
    };
  }) => Promise<FindResponse<MarketStatus>>;
}

interface EthEditionsService {
  find: (options: {
    query: {
      $limit?: number;
      merchantId: string[];
    };
  }) => Promise<FindResponse<Eth.Edition>>;
}

interface PublicEthNftService {
  find: (options: {
    query: {
      $skip?: number;
      $limit?: number;
      $sort?: Record<string, 0 | 1>;
    };
  }) => Promise<FindResponse<Eth.PublicNft>>;
  get: (
    tokenId: string,
    options: {
      query: {
        contractAddress: string;
        merchantId?: string[];
      };
    }
  ) => Promise<Eth.PublicNft>;
}

interface MarketHistoryService {
  find: (options: {
    query: {
      $skip: number;
      $limit?: number;
      $sort: Record<string, 0 | 1>;
      marketFID: number;
      merchantFID: string[] | string | null;
      editionFID?: string;
      itemFID?: string;
      contractName?: string;
      contractAddress?: string;
    };
  }) => Promise<FindResponse<Flow.MarketHistory>>;
}

interface PublicEditionsService {
  find: (options: {
    query: {
      editionFID: string | { $in: string[] };
      $select?: string[];
      $limit: number;
    };
  }) => Promise<FindResponse<Flow.PublicEdition>>;
}

interface MerchantsService {
  find: (options: {
    query: {
      $skip?: number;
      $limit?: number;
      merchantFID?: string | string[];
      id?: string | string[];
      profileName?: { $iLike: `%${string}%` };
    };
  }) => Promise<FindResponse<Flow.Merchant>>;
}

interface FlowClientWalletService {
  find: (options: { query: unknown }) => Promise<FindResponse<Flow.Wallet>>;
  patch: (id: string, fields: unknown) => Promise<Flow.Wallet>;
  remove: (id: string, options: unknown) => Promise<Flow.Wallet>;
}

interface FlowClientWalletContractService {
  patch: (id: string, fields: unknown) => Promise<Flow.Wallet>;
}

interface EditionForSaleService {
  find: (options: {
    query: {
      $skip?: number;
      $limit?: number;
      $associateProfiles?: boolean;
      $sort?: Record<string, 0 | 1>;
      marketFID?: string;
      merchantFID?: string | string[];
      editionFID?: string;
      name?: { $iLike: `%${string}%` };
      smartContractAddress?: string;
      smartContractName?: string;
    };
  }) => Promise<FindResponse<Flow.EditionForSale>>;
}

interface NftHistoryService {
  find: (options: {
    query: {
      $skip?: number;
      $limit?: number;
      $sort?: Record<string, 0 | 1>;
    } & { itemFID: string; contractName: string; contractAddress: string };
  }) => Promise<FindResponse<HistoryItem>>;
}

export interface NftService {
  get: (
    id: string,
    options: {
      query: {
        smartContractAddress?: string;
        smartContractName?: string;
      };
    }
  ) => Promise<Flow.FullNft>;
}

export interface PublicNftService {
  find: (options: {
    query: {
      $skip?: number;
      $limit?: number;
      $sort?: Record<string, 0 | 1>;
    };
  }) => Promise<FindResponse<Flow.PublicNft>>;
  get: (
    id: string,
    options: {
      query: {
        merchantFID: string | string[];
        smartContractAddress: string | null;
        smartContractName: string | null;
      };
    }
  ) => Promise<Flow.PublicNft>;
}

export interface PurchasedNftService {
  find: (options: {
    query: FindQuery<Flow.PurchasedNft>;
  }) => Promise<FindResponse<Flow.PurchasedNft>>;
  get: (
    id: string,
    options: {
      query: unknown;
    }
  ) => Promise<Flow.PurchasedNft>;
}

interface RegisterWalletService {
  create: (fields: {
    flowAddress: string;
    walletType: FlowWalletType;
  }) => Promise<Flow.Wallet>;
}

interface EthClientWalletService {
  find: (options: {
    query: { userId?: string; address?: string };
  }) => Promise<FindResponse<Eth.Wallet>>;
  patch: (id: string, fields: unknown) => Promise<Eth.Wallet>;
}

interface EthNftHistoryService {
  get: (
    id: string,
    options: { query: { contractAddress: string } }
  ) => Promise<FindResponse<HistoryItem>>;
}

interface EthNftStateService {
  find: (options: {
    query: {
      $skip?: number;
      $limit?: number;
      $sort?: Record<string, 0 | 1>;
      itemEID: string;
      contractAddress: string;
    };
  }) => Promise<Eth.NftState>;
}

interface PurchasedEthNftService {
  find: (options: {
    query: {
      $skip?: number;
      $limit?: number;
      $sort?: Record<string, 0 | 1>;
    } & {
      contractAddress?: string;
      merchantId?: string | string[];
      mintingStatus?: MintingStatus | { $or: MintingStatus[] };
    };
  }) => Promise<FindResponse<Eth.PurchasedNft>>;
  get: (
    id: string,
    options: {
      query: unknown;
    }
  ) => Promise<Eth.PurchasedNft>;
}

interface RegisterEthWalletService {
  create: (fields: {
    ethAddress: string;
    walletType: EthWalletType;
  }) => Promise<Eth.Wallet>;
}

interface RedemptionCodeService {
  get: (redemptionCode: string) => Promise<RedemptionCodeData>;
}
interface UserRedemptionService {
  get: (redemptionCode: string) => Promise<UserRedemptionStatusData>;
}

interface GatedProductsService {
  get: (
    handle: string,
    options: {
      query: {
        shop: string;
      };
    }
  ) => Promise<Utility.Product>;
  find: (options: {
    query: {
      shop: string;
      collection_id: number | null;
      cursor?: string;
      limit?: number;
    };
  }) => Promise<Utility.ProductsResponse>;
}

interface CreateDraftOrderService {
  create: (data: {
    shop: string;
    cart: { [key: string]: number };
    collection_id: string;
  }) => Promise<Utility.DraftOrder>;
}

interface GatedPlaylistsService {
  get: (id: string) => Promise<Utility.Playlist>;
  find: (options: {
    query: {
      $skip?: number;
      $limit?: number;
      walletViewerId?: number;
    };
  }) => Promise<Utility.PlaylistsResponse>;
}

interface GatedVideosService {
  get: (uuid: string) => Promise<Utility.Video>;
  find: (options: {
    query: {
      $skip?: number;
      $limit?: number;
      walletViewerId?: number;
    };
  }) => Promise<Utility.VideosResponse>;
}

interface GatedFilesService {
  get: (uuid: string) => Promise<Utility.File>;
  find: (options: {
    query: {
      $skip?: number;
      $limit?: number;
      walletViewerId?: number;
    };
  }) => Promise<Utility.FilesResponse>;
}

interface UtilityService {
  find: (options: {
    query: {
      shop?: string;
      collection_id?: number | null;
    };
  }) => Promise<Utility.UtilityResponse>;
}

interface RulesetsService {
  get: (id: string) => Promise<Ruleset.Rule>;
  create: (options: { rulesets: string[] }) => Promise<Ruleset.Validation[]>;
}

interface FlowNonceService {
  create: (options: unknown) => Promise<Flow.Nonce>;
}

interface AccountProofService {
  create: (data: unknown) => Promise<Flow.AccountProof>;
}

export interface LatestFlowTransactionService {
  find: (options: {
    query: {
      eventType?: string;
      itemFID?: string;
      eventName?: string;
      $sort?: Record<string, -1>;
      $limit?: number;
    };
  }) => Promise<FindResponse<Flow.FlowHistory>>;
}

export interface UsersMarketingOptInsService {
  create: (options: { isOptedOut?: boolean }) => Promise<any>;
}

export interface UsersPrimaryWalletAddressService {
  get: (address: string) => Promise<Recipient>;
}

export interface UsersPrimaryWalletProfileNameService {
  get: (profileName: string) => Promise<Recipient>;
}

const client = feathers<{
  login: LoginService;
  users: UserService;
  wizard: WizardService;
  merchants: MerchantsService;
  redemption: UserRedemptionService;
  "redemption-code": RedemptionCodeService;
  "public-eth-nft": PublicEthNftService;
  "eth-editions": EthEditionsService;
  "item-for-sale": ItemForSaleService;
  "item-for-sale/streak": ItemForSaleByStreakService;
  "primary-wallet": PrimaryProfileService;
  "marketplace/status": MarketStatusService;
  "marketplace/history": MarketHistoryService;
  "public-editions": PublicEditionsService;
  "flow/wallet": FlowClientWalletService;
  "flow-client-wallet-contract-status": FlowClientWalletContractService;
  "edition-for-sale": EditionForSaleService;
  "nft-history": NftHistoryService;
  nft: NftService;
  "public-nft": PublicNftService;
  "purchased-nft": PurchasedNftService;
  "flow/wallet/register": RegisterWalletService;
  "polygon/wallet": EthClientWalletService;
  "polygon/nft/history": EthNftHistoryService;
  "eth-nft-state": EthNftStateService;
  "purchased-eth-nft": PurchasedEthNftService;
  "polygon/wallet/register": RegisterEthWalletService;
  utility: UtilityService;
  "gated-products": GatedProductsService;
  "create-draft-order": CreateDraftOrderService;
  "gated-playlists": GatedPlaylistsService;
  "gated-videos": GatedVideosService;
  "gated-files": GatedFilesService;
  rulesets: RulesetsService;
  "flow-nonce": FlowNonceService;
  "account-proof": AccountProofService;
  "flow-history": LatestFlowTransactionService;
  [`users/:id/marketing-opt-ins`]: UsersMarketingOptInsService;
  "users/primary-wallet/address": UsersPrimaryWalletAddressService;
  "users/primary-wallet/profileName": UsersPrimaryWalletProfileNameService;
}>();

const restClient = rest(Project.SERVER_API_URL);

let xWalletType: "Polygon" | WalletType;
if (Blockchain.BLOCKCHAIN_NAME === "polygon") {
  xWalletType = "Polygon";
} else {
  xWalletType = Project.WALLET_TYPE?.[0]; //safe since flow should only have 1 wallet at a time
}

const jwt = localStorage.getItem("feathers-jwt");
if (jwt) {
  const decodedToken = jwtDecode<{ exp: number }>(jwt);
  if (Date.now() > decodedToken.exp * 1000) {
    localStorage.removeItem("feathers-jwt");
  }
}
const axiosInstance = axios.create({
  headers: {
    "X-Wallet-Type": xWalletType,
    "X-Blockchain-Name": Blockchain.BLOCKCHAIN_NAME,
    "X-Wallet-Viewer-Id": Project.WALLET_VIEWER_ID[Blockchain.NET],
    "X-Wallet-Version": process.env.REACT_APP_VERSION as string,
  },
});

axiosInstance.interceptors.response.use(
  undefined,
  function (error: AxiosError) {
    if (error && error.response && error.response.status === 500) {
      captureException(error);
    }
    return Promise.reject(error);
  }
);

client.configure(restClient.axios(axiosInstance));
client.configure(feathers.authentication({ storage: window.localStorage }));

// I don't know if it is possible with FeathersJS, but here would be a good place to
// set up error interception and handling in a global way, to avoid having to do it
// on every page / component.

export type FeathersClient = typeof client;

export default client;
