import { getLocalizedStrings } from "config";
import { GATED_ITEM_ROUTE } from "config/routes";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useRulesets } from "lib/store/api/ruleset/hooks";
import { useUtilityProducts } from "lib/store/api/utility/hooks";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Utility } from "types";
import UtilitySubpageUI, { Item } from "./ui";

const ProductsPage: FunctionComponent = () => {
  const strings = getLocalizedStrings("Pages", "Utility", useLanguage());
  const navigate = useNavigate();
  const [cursor, setCursor] = useState<string>("");
  const {
    data,
    cursor: lastCursor,
    hasNextPage,
    isFetching,
    isError,
    rulesetIds,
  } = useUtilityProducts(cursor);
  const { data: rulesets, isFetching: isRulesetsFetching } =
    useRulesets(rulesetIds);

  const items: Item[] = data.map((product: Utility.Product) => {
    const ruleset = rulesets?.find((rs) => rs.id === product.ruleSetId);
    const isAvailable = !!ruleset?.ruleSetResult.isValid;

    return {
      title: product.title,
      description: product.description,
      imageSrc: product.featuredImage.url ?? "",
      onClick: () => {
        navigate(GATED_ITEM_ROUTE.replace(":handle", product.handle));
      },
      isAvailable,
    };
  });

  return (
    <UtilitySubpageUI
      title={strings.merchandise.title}
      description={strings.merchandise.description}
      isFetching={isFetching || isRulesetsFetching}
      isError={isError}
      data={items}
      dataLength={data.length ?? 0}
      hasMore={hasNextPage ?? true}
      onLoadMore={() => setCursor(lastCursor)}
    />
  );
};

export default ProductsPage;
