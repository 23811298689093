import styles from "./styles.module.scss";
import {
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  FunctionComponent,
  useEffect,
  useState,
  ReactNode,
  RefObject,
} from "react";
import { Info } from "react-feather";
import { generateInputId } from "../../../utils/utils";

type InputType = "email" | "password" | "number" | "text";
export type Props = {
  prependIcon?: ReactNode;
  appendIcon?: ReactNode;
  onPrependIconClick?: () => void;
  onAppendIconClick?: () => void;
  inputRef?: RefObject<HTMLInputElement>;
  disabled?: boolean;
  id?: string;
  value?: string | number;
  placeHolder?: string;
  label?: string;
  type?: InputType;
  copy?: boolean;
  outline?: boolean;
  errorMsg?: string | null;
  autoComplete?: string;
  onClick?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  className?: string;
  readOnly?: boolean;
  useValue?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
  maxLength?: number;
  pattern?: string;
  inputMode?:
    | "email"
    | "text"
    | "search"
    | "none"
    | "tel"
    | "url"
    | "numeric"
    | "decimal"
    | undefined;
  [x: string]: unknown;
};
const DEFAULT_ID = `input-${generateInputId()}`;
const Field: FunctionComponent<Props> = ({
  value,
  onChange,
  placeHolder,
  type = "text",
  autoComplete = "off",
  disabled = false,
  id = DEFAULT_ID,
  label,
  outline = false,
  errorMsg,
  className,
  onFocus,
  appendIcon,
  onAppendIconClick,
  prependIcon,
  onPrependIconClick,
  inputRef,
  onKeyDown,
  readOnly = false,
  onClick,
  useValue,
  required,
  onBlur,
  style,
  maxLength,
  pattern,
  inputMode,
  ...rest
}: Props) => {
  const [_value, setValue] = useState(value);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const _onFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    if (onFocus) onFocus(event);
  };

  useEffect(() => {
    setValue(value);
    if (value) setIsFocused(true);
  }, [value]);

  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);

    setValue(e.target.value);
  };

  const _onBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (!_value || _value === "") {
      setIsFocused(false);
      if (onBlur) onBlur(e);
    }
  };
  const _onClick = () => {
    if (onClick) onClick();
  };

  const _onAppendClick = () => {
    if (!onAppendIconClick) setValue("");
    else onAppendIconClick();
  };
  let inputClass = "";
  if (isFocused) inputClass += ` ${styles.inputActive}`;
  if (errorMsg) inputClass += ` ${styles.error}`;

  const renderOutline = () => {
    return (
      <div
        className={`${styles.inputContainer} ${styles.outlined} ${className} ${
          errorMsg ? styles.errorText : ""
        }`}
      >
        <div className={styles.control}>
          <div className={styles.rInput}>
            <fieldset aria-hidden={true} className={inputClass}>
              <legend
                style={{
                  width: !isFocused
                    ? "0"
                    : "0" /* `${(label?.length ?? 0) * 0.8}ch`*/,
                }}
              />
            </fieldset>
            <div
              className={styles.field}
              onClick={() => setIsFocused(true)}
              onBlur={_onBlur}
            >
              <label
                className={`${styles.outlined} ${inputClass}`}
                htmlFor={id}
              >
                {label}
              </label>
              <input
                id={id}
                name={id}
                required={required}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onKeyDown={onKeyDown}
                autoComplete={autoComplete}
                onBlur={_onBlur}
                onClick={_onClick}
                ref={inputRef}
                readOnly={readOnly}
                placeholder={isFocused ? placeHolder : undefined}
                className={styles.input}
                disabled={disabled}
                value={useValue ? value : _value}
                onChange={_onChange}
                type={type}
                maxLength={maxLength}
                inputMode={inputMode}
                pattern={pattern}
              />
            </div>
          </div>
          {errorMsg && (
            <div className={styles.errorDetails}>
              <Info style={{ marginRight: "0.5rem" }} />
              <div className={styles.errorText}>
                <div className={styles.message}>{errorMsg}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      {outline ? (
        renderOutline()
      ) : (
        <div
          className={`${styles.inputContainer} ${styles.filled} ${className} ${
            errorMsg ? styles.errorText : ""
          }`}
          style={style}
        >
          {label && (
            <label htmlFor={id} className={inputClass}>
              {label}
            </label>
          )}

          <div className={`${styles.filledContainer}`}>
            {prependIcon && (
              <span
                className={styles.icon}
                onClick={onPrependIconClick}
                style={{
                  pointerEvents: onPrependIconClick ? "all" : "none",
                  cursor: onPrependIconClick ? "pointer" : "none",
                }}
              >
                {prependIcon}
              </span>
            )}
            <input
              id={id}
              name={id}
              required={required}
              ref={inputRef}
              onKeyDown={onKeyDown}
              className={`${styles.input} ${styles.filled} ${inputClass}`}
              disabled={disabled}
              onFocus={_onFocus}
              onBlur={_onBlur}
              value={useValue ? value : _value}
              onChange={_onChange}
              onClick={onClick}
              type={type}
              readOnly={readOnly}
              placeholder={placeHolder}
              style={style}
              maxLength={maxLength}
              inputMode={inputMode}
              pattern={pattern}
              {...rest}
            />
            {appendIcon && (
              <span
                className={styles.icon}
                onClick={_onAppendClick}
                style={{
                  pointerEvents: "all",
                  cursor: "pointer",
                }}
              >
                {appendIcon}
              </span>
            )}
          </div>
          {errorMsg && (
            <div className={styles.errorDetails}>
              <Info style={{ marginRight: "0.5rem" }} />
              <div className={styles.errorText}>
                <div className={styles.message}>{errorMsg}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default Field;
