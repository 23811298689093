import { Config, getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { Checkbox } from "../../../../components";
import styles from "../ui/style.module.scss";

interface Props {
  onCheckClick: (checked: boolean) => void;
}
export const HasConsentBeforePurchase = ({ onCheckClick }: Props) => {
  const localizedStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );

  switch (Config.Client.REQUIRE_CONSENT_BEFORE_PURCHASE) {
    case true:
      return (
        <>
          <hr className={styles.purchaseConsentContainerDivider} />
          <div className={styles.purchaseConsentContainer}>
            <Checkbox onChange={onCheckClick} />
            <div>
              <p className={styles.checkboxText}>
                {localizedStrings.requireConsentBeforePurchaseMessageTitle}
                <br />
                {localizedStrings.requireConsentBeforePurchaseMessage}
              </p>
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
};
