import { FunctionComponent } from "react";
import styles from "./invalid-code.styles.module.scss";
import { getLocalizedStrings } from "config";
import { useLanguage } from "lib/contexts/LanguageContext";
import { REDEEM_ROUTE } from "config/routes";

const InvalidCodeUI: FunctionComponent = () => {
  const localizedStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );

  return (
    <div className={styles.content}>
      <h1>{localizedStrings.invalidRedemptionCode.title}</h1>
      <h3>{localizedStrings.invalidRedemptionCode.text}</h3>
      <div>
        <a href={REDEEM_ROUTE}>{localizedStrings.invalidRedemptionCode.btn}</a>
      </div>
    </div>
  );
};

export default InvalidCodeUI;
