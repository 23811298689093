const en = {
  locked: "Locked",
  info: "Info",
  rules: "Rules",
  from: "From",
  purchase: "Purchase",
  rulesetTree: "Ruleset Tree",
  noDescription: "No description",
  noRulesDescription: "No description",
  downloadFile: "Download file",
  type: "Type",
  noAccessNFT: "The user must own the appropriate NFT to view this content.",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
