import { FC, useRef, useState } from "react";
import { Config, getLocalizedStrings } from "../../../../config";
import { NftPreview } from "../types";
import { CSSTransition } from "react-transition-group";

import styles from "../ui/style.module.scss";

import { Clipboard } from "react-feather";
import { useLanguage } from "lib/contexts/LanguageContext";

type Props = {
  nft?: NftPreview;
  restrictionBasedOnEditionName?: boolean;
};

const DisplayPfpTraits: FC<Props> = ({
  nft,
  restrictionBasedOnEditionName,
}) => {
  const strings = getLocalizedStrings("Modals", "Sell", useLanguage());
  const transitionRef = useRef(null);
  const [success, setSuccess] = useState<boolean>(false);

  async function copyTextToClipBoard(text: string) {
    await navigator.clipboard.writeText(text);
    setSuccess(!success);
    setTimeout(() => setSuccess(false), 1000);
  }
  if (restrictionBasedOnEditionName) {
    return (
      <div className={styles.pfpTraits}>
        {Object.entries(nft?.attributes ?? {})
          .filter(
            (attribute) =>
              attribute[0] === "Points" || attribute[0] === "PrizeEligibility"
          )
          .map(([name, value], index) => (
            <div key={index} className={styles.pfpTrait}>
              <p>
                {name === "PrizeEligibility" ? "Prize Eligibility" : name}
                <br />
                <span>{value}</span>
              </p>
            </div>
          ))}
      </div>
    );
  } else {
    return (
      <div className={styles.pfpTraits}>
        {Object.entries(nft?.attributes ?? {})
          .sort(([attributeName1], [attributeName2]) =>
            attributeName1 > attributeName2 ? 1 : -1
          )
          .map(([name, value], index) => (
            <div key={index} className={styles.pfpTrait}>
              {name === "dna" && (
                <>
                  <div
                    title="Copy full Dna"
                    className={styles.shareIcon}
                    onClick={() => copyTextToClipBoard(value)}
                  >
                    <Clipboard />
                  </div>
                  <CSSTransition
                    nodeRef={transitionRef}
                    in={success}
                    timeout={2000}
                    unmountOnExit
                  >
                    <div ref={transitionRef} className={`${styles.successMsg}`}>
                      <p>
                        {name} {strings.copiedToClipBoard}
                      </p>
                    </div>
                  </CSSTransition>
                </>
              )}
              <p>
                {name.toUpperCase()}
                <br />
                <span className={styles.pfpTraitDescription}>{value}</span>
              </p>
            </div>
          ))}
      </div>
    );
  }
};

export const PfpTraits: FC<Props> = ({
  nft,
  restrictionBasedOnEditionName,
}) => {
  if (nft?.contractType !== "PFP") return null;
  switch (Config.Client.HIDE_PFP_TRAITS) {
    case true:
      return restrictionBasedOnEditionName ? (
        <DisplayPfpTraits
          nft={nft}
          restrictionBasedOnEditionName={restrictionBasedOnEditionName}
        />
      ) : null;
    default:
      return <DisplayPfpTraits nft={nft} />;
  }
};
