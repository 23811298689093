const en = {
  hasChallengeStrings: false,
  challengeSummary: "",
  challengeHeading: "",
  prizeDescriptionHeader: "",
  prizeDescription: [],
  wantToKnowMore: "",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
