import styles from "./style.module.scss";
import { FunctionComponent, useState } from "react";
import { useLanguage } from "lib/contexts/LanguageContext";
import { getLocalizedStrings } from "config";

interface RoundProfileImageProps {
  className?: string;
  imageSrc?: string;
  size?: number;
  style?: string;
  profileName?: string;
  hasBorder?: boolean;
  cover?: boolean;
  onUploadClick?: () => void;
}

const RoundImage: FunctionComponent<RoundProfileImageProps> = ({
  className,
  imageSrc,
  size = 50,
  profileName,
  hasBorder = false,
  cover = false,
  onUploadClick,
}) => {
  const strings = getLocalizedStrings("Component", "AppHeader", useLanguage());

  const [isUploadOnClick, setIsUploadOnClick] = useState<boolean>(false);
  let rootClass = styles.holder;
  if (cover) rootClass += ` ${styles.cover}`;
  if (className) rootClass += ` ${className}`;
  if (cover) rootClass += ` ${styles.cover}`;

  const style = imageSrc
    ? {
        backgroundImage: `url("${imageSrc}")`,
        width: `${size}px`,
        height: `${size}px`,
      }
    : { width: `${size}px`, height: `${size}px` };

  const renderAbrv = () => {
    if (imageSrc) return null;
    if (profileName) return profileName.charAt(0);

    return strings.anonymous.charAt(0);
  };

  const _onUploadClick = () => {
    return onUploadClick ? onUploadClick() : null;
  };

  const imgClasses = [styles.imgContainer];
  if (hasBorder) imgClasses.push(styles.imgBorder);

  useState(() => {
    if (onUploadClick) setIsUploadOnClick(true);
  });

  return (
    <div
      className={imgClasses.join(" ")}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${Math.ceil(size / 50)}em`,
      }}
    >
      {isUploadOnClick && (
        <div className={styles.uploadOnClick} onClick={_onUploadClick}>
          <span>{strings.change}</span>
        </div>
      )}
      <div className={rootClass} style={style}>
        {renderAbrv()}
      </div>
    </div>
  );
};

export default RoundImage;
