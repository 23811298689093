import { Project } from "config";
import {
  setEthUser,
  setEthUserLoading,
  clearEthUser,
} from "../../slices/user-slice";
import { setError } from "lib/store/slices/error-slice";
import { isValidAddress } from "utils/utils";
import { Builder } from "./builder";
import {
  walletProvider,
  connectingWalletProvider,
  bloctoSDK,
  bloctoWeb3,
  getMetaMask,
  reinitBlocto,
  setWalletProvider,
  setEthConnectingWalletProvider,
} from "./web3";
import { Eth } from "types";
import { serializeError } from "eth-rpc-errors";

export const ethAuthenticationEndpoints = (builder: Builder) => ({
  login: builder.mutation<null, void>({
    async queryFn(_, { dispatch }) {
      try {
        switch (connectingWalletProvider) {
          case "Blocto": {
            if (!bloctoWeb3) return { data: null };

            //open login popup
            const [address] = await bloctoWeb3.eth.getAccounts();

            if (isValidAddress(address)) {
              const balanceInWei = await bloctoWeb3.eth.getBalance(address);
              const balance = bloctoWeb3.utils.fromWei(balanceInWei);

              localStorage.setItem("walletProvider", "blocto");
              localStorage.setItem("connectedWallet", address);

              setWalletProvider("Blocto");
              dispatch(
                setEthUser({ address: address, balance, loading: false })
              );
            } else {
              throw "Invalid wallet address";
            }

            break;
          }

          case "MetaMask": {
            dispatch(setEthUserLoading(true));

            const { metamaskProvider, metamaskWeb3 } = await getMetaMask();
            if (!metamaskProvider || !metamaskWeb3) {
              throw "MetaMask not found";
            }

            const accounts = await metamaskProvider.request({
              method: "eth_requestAccounts",
            });

            if (accounts && Array.isArray(accounts)) {
              if (isValidAddress(accounts[0])) {
                const balanceInWei = await metamaskWeb3.eth.getBalance(
                  accounts[0]
                );
                const balance = metamaskWeb3.utils.fromWei(balanceInWei);

                localStorage.setItem("walletProvider", "metamask");
                localStorage.setItem("connectedWallet", accounts[0]);

                setWalletProvider("MetaMask");
                dispatch(
                  setEthUser({
                    address: accounts[0],
                    balance,
                    loading: false,
                  })
                );
              } else {
                throw "Invalid wallet address";
              }

              break;
            } else {
              throw "No accounts added";
            }
          }
        }
      } catch (error: unknown) {
        // Detect errors
        // Note: the errors usually metamask specific
        const serializedError = serializeError(error);
        if (serializedError) {
          dispatch(
            setError({
              type: "login",
              code: serializedError.code.toString(),
              message: serializedError.message,
              provider: connectingWalletProvider,
            })
          );
        }
        setWalletProvider(null);
        return { data: null };
      }

      //We only need to reset this wallet provider if using multiple wallet types
      if (Project.WALLET_TYPE?.length > 1) {
        setEthConnectingWalletProvider(null);
      }
      return { data: null };
    },
  }),

  logout: builder.mutation<null, { isDisconnecting?: boolean }>({
    async queryFn({ isDisconnecting }, { dispatch }) {
      switch (walletProvider) {
        case "Blocto": {
          if (!bloctoSDK) return { data: null };

          //logout only if connected to wallet
          if ((bloctoSDK.ethereum as any)?.connected) {
            //clear blocto session storage
            localStorage.removeItem("sdk.session");
            //reset bloctoSDK and web3 integration to remove connected status
            reinitBlocto();
          }
          break;
        }
      }

      if (isDisconnecting) {
        localStorage.removeItem("walletProvider");
        localStorage.removeItem("connectedWallet");
      }

      dispatch(clearEthUser());
      return { data: null };
    },
  }),

  tryLoginWithBlocto: builder.mutation<null, void>({
    async queryFn(_, { dispatch }) {
      if (!bloctoWeb3) return { data: null };

      //called on login
      const session = localStorage.getItem("sdk.session");

      if (session && new Date().getTime() < JSON.parse(session).expiry) {
        const [address] = await bloctoWeb3.eth.getAccounts();

        if (isValidAddress(address)) {
          const balanceInWei = await bloctoWeb3.eth.getBalance(address);
          const balance = bloctoWeb3.utils.fromWei(balanceInWei);

          setWalletProvider("Blocto");
          dispatch(setEthUser({ address: address, balance, loading: false }));
          return { data: null };
        }
      }

      localStorage.removeItem("walletProvider");
      localStorage.removeItem("connectedWallet");
      return { data: null };
    },
  }),

  tryLoginWithMetaMask: builder.mutation<null, { wallets: Eth.Wallet[] }>({
    async queryFn({ wallets }, { dispatch }) {
      //called on login
      const { metamaskProvider, metamaskWeb3 } = await getMetaMask();
      if (!metamaskProvider || !metamaskWeb3) return { data: null };

      const lastConnectedWallet = localStorage.getItem("connectedWallet");

      if (metamaskProvider.isConnected() && lastConnectedWallet != null) {
        const allAddresses: string[] = wallets.map((wallet) => wallet.address);

        if (allAddresses.includes(lastConnectedWallet)) {
          const balance = await metamaskWeb3.eth
            .getBalance(lastConnectedWallet)
            .then((balanceInWei) => metamaskWeb3.utils.fromWei(balanceInWei));

          setWalletProvider("MetaMask");
          dispatch(
            setEthUser({
              address: lastConnectedWallet,
              balance,
              loading: false,
            })
          );
          return { data: null };
        }
      }

      localStorage.removeItem("walletProvider");
      localStorage.removeItem("connectedWallet");
      return { data: null };
    },
  }),

  switchChains: builder.mutation<null, Chain>({
    async queryFn(chain, { dispatch }) {
      dispatch(setEthUserLoading(true));

      const { metamaskProvider, metamaskWeb3 } = await getMetaMask();
      if (!metamaskProvider || !metamaskWeb3) return { data: null };

      try {
        await metamaskProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: metamaskWeb3.utils.toHex(chain.chainId) }],
        });
      } catch (switchError) {
        const serializedError = serializeError(switchError);
        if (serializedError.code === 4001) {
          dispatch(setEthUserLoading(false));
          dispatch(
            setError({
              type: "chain",
              code: serializedError.code.toString(),
              message: serializedError.message,
              provider: "MetaMask",
            })
          );
          throw serializedError;
        }
        try {
          const params = {
            chainId: metamaskWeb3.utils.toHex(chain.chainId),
            chainName: chain.name,
            nativeCurrency: {
              name: chain.nativeCurrency.name,
              symbol: chain.nativeCurrency.symbol,
              decimals: chain.nativeCurrency.decimals,
            },
            rpcUrls: chain.rpc,
            blockExplorerUrls: [
              chain.explorers &&
              chain.explorers.length > 0 &&
              chain.explorers[0].url
                ? chain.explorers[0].url
                : chain.infoURL,
            ],
          };

          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [params, metamaskProvider.selectedAddress],
          });
        } catch (error) {
          dispatch(setEthUserLoading(false));
          const serializedError = serializeError(error);
          if (serializedError) {
            dispatch(
              setError({
                type: "chain",
                code: serializedError.code.toString(),
                message: serializedError.message,
                provider: "MetaMask",
              })
            );
          }
          throw error;
        }
      }

      dispatch(setEthUserLoading(false));
      return { data: null };
    },
  }),

  getChain: builder.query<string | null, void>({
    async queryFn() {
      const { metamaskProvider } = await getMetaMask();
      if (!metamaskProvider) return { data: null };

      const chain = metamaskProvider.networkVersion;

      return { data: chain };
    },
  }),
});
