const en = {
  title: "Choose your provider",
  subtitle: "Choose",
  yourAddress: "Your address",
  sources: {
    moonpay: "Moonpay",
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
