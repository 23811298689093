const en = {
  changeImg: "Change",
  noFile: "No file selected",
  fileTooLarge: "File is too large",
  invalidFileType: "Invalid File Type",
  errorReadingFile: "Error reading File",
  uploadTitle: "Upload your Photo",
  uploadSubtitle: "Time to shine.",
  uploadUploadBtn: "Upload Photo",
  uploadSaveBtn: "Save",
  uploadDeleteBtn: "Delete current photo",
  errorDeleting: "An expected error occured. Please try again later.",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
