const en = {
  seeAll: "See All",
  historyTitle: "History",
  historyUnavailable: "The history is unavailable at the moment for this NFT.",
  noHistoryYet: "This doesn't have any history yet.",
  you: "You",
  purchasedBy: "Purchased by",
  mintedBy: "Minted by",
  owner: "Owner",
  creator: "Creator",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
