const en = {
  purchaseInProgressTitle: "Processing Purchase",
  purchaseInProgressSubtitle: "This could take a few minutes.",
  pleaseWait: "Please wait...",
  congratulations: "Congratulations!",
  successfulPurchase: "Purchase was successful.",
  itemInWallet: "The item is now in your wallet.",
  purchasedFailedTitle: "Purchase unsuccessful",
  purchasedFailedSubtitle: "The transaction was not executed.",
  tryAgainLater: "Please try again later.",
  mustLogin: "Must be logged in to the Flow blockchain to proceed.",
  openWallet: "Open wallet",
  close: "Close",
  showInMarketplace: "Show in Marketplace",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
