import LogoSvg from "../img/akog-logo.png";
import LoginSrc from "../img/hotel-wagen-2.jpeg";
const LogoPng = LogoSvg;

const HTML_TITLE = "AKOG Wallet Viewer";
const TEAM_CITY = "";
const TEAM_NAME = "";
const COPYRIGHT = "AKOG";
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = LogoSvg;
const LOGO_PNG_SRC = LogoPng;
const DIGITAL_PASSPORT_LINK =
  "https://dkuxa1i6sgo8h.cloudfront.net/AKOG/AKOG-DPP.pdf";

export default {
  HTML_TITLE,
  TEAM_CITY,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
  DIGITAL_PASSPORT_LINK,
};
