import LoginSrc from "../img/umj_login.png";
import Logo from "../img/logo.svg";
import { yearNow } from "utils/utils";

const HTML_TITLE = "My NFT Collection by Universal Music Japan";
const TEAM_CITY = "Universal Music";
const TEAM_NAME = "UNIVERSAL MUSIC JAPAN";
const COPYRIGHT_YEAR = "";
const COPYRIGHT = `Copyright ${yearNow} Universal Music Group N.V. All rights reserved.`;
const LOGIN_SRC = LoginSrc;
const LOGO_SVG_SRC = Logo;
const LOGO_PNG_SRC = Logo;
const PRIVACY_POLICY_URL = "https://www.universal-music.co.jp/privacy-policy/";
const WALLET_FAQ_LINK = "https://umusic.jp/mnc-faq";
const CONTACT_US = "";
const CONTACT_US_EXTERNAL =
  "https://support.universal-music.co.jp/hc/ja/requests/new";
const WALLET_PROVIDER_LINK = "https://umusic.jp/blocto-faq";

const TOSLINKS: { label: string; href: string }[] = [
  {
    label: "本サービスの利用規約（日本語）",
    href: "https://collection.umj.jp/terms-of-service",
  },
  {
    label: "Bloctoウォレット利用規約（英語）",
    href: "https://blocto.io/terms",
  },
  {
    label: "Mint.Store利用規約（英語）",
    href: "https://www.mint.store/terms-and-conditions",
  },
];

export default {
  HTML_TITLE,
  TEAM_CITY,
  COPYRIGHT_YEAR,
  TEAM_NAME,
  COPYRIGHT,
  LOGIN_SRC,
  LOGO_SVG_SRC,
  LOGO_PNG_SRC,
  REDEMPTION_SHOW_REMAINING: false,
  REDEMPTION_SHOW_REDEEMED: false,
  REDEMPTION_CAMPAIGN_SHOW_REMAINING: false,
  REDEMPTION_CAMPAIGN_SHOW_REDEEMED: false,
  HIDE_TRANSFER_BUTTON: true,
  HAS_FUSD: false,
  MARKETING_OPT_IN: false,
  PRIVACY_POLICY_URL,
  REDEMPTION_MARKETING_OPT_IN: true,
  TOSLINKS,
  DISPLAY_REDEMPTION_STEPS: true,
  UMG_WALLET_MODAL: true,
  HIDE_PROFILE_ALERT_STEPS: true,
  HIDE_CLAIM_STEP_PILLS: true,
  HIDE_LOGIN_PAGE_TEAM_NAME: true,
  WALLET_FAQ_LINK,
  CONTACT_US,
  CONTACT_US_EXTERNAL,
  WALLET_PROVIDER_LINK,
  CUSTOM_UTIL_BANNER: true,
  DISPLAY_LANGUAGE_SELECTOR: true,
};
