import styles from "./style.module.scss";
import { useContext, FunctionComponent } from "react";
import { useLanguage } from "lib/contexts/LanguageContext";
import { Button } from "components";
import { getLocalizedStrings, Project } from "config";
import { AppContext } from "context/AppContext";

type Props = {
  hasWallet: boolean;
};

const NoNFT: FunctionComponent<Props> = ({ hasWallet }) => {
  const appContext = useContext(AppContext) as AppContext;
  const localizedStrings = getLocalizedStrings(
    "Component",
    "NoNft",
    useLanguage()
  );

  return (
    <section className={styles.container}>
      {hasWallet || !Project.WALLET_TYPE ? (
        <div className={styles.data}>
          <h1>{localizedStrings.title}</h1>
          <h1>{localizedStrings.subtitle}</h1>
          <p>{localizedStrings.description}</p>
        </div>
      ) : (
        <div className={styles.data}>
          <h1>{localizedStrings.noWalletTitle}</h1>
          <p>{localizedStrings.noWalletDescription}</p>
          <Button
            type="secondary"
            onClick={() => {
              appContext.wizard.display.setIsVisible(true);
            }}
          >
            {localizedStrings.noWalletBtn}
          </Button>
        </div>
      )}
    </section>
  );
};

export default NoNFT;
