import { Flow } from "../../../types";
import CollectiblesInfiniteSection from "components/Widgets/Collectibles/CollectiblesInfiniteSection";
import Collectible from "components/Widgets/Collectibles/Collectible";
import { useNavigate } from "react-router-dom";
import { withPrefix } from "@blocto/fcl";
import {
  EDITION_DETAILS,
  MARKETPLACE_MERCHANT_ROUTE,
  MARKETPLACE_PFP_ROUTE,
} from "config/routes";
import { FunctionComponent } from "react";
import { UIProps } from "./types";
import { Project } from "config";
import { formatUsdWithSymbol } from "utils/utils";

const Editions: FunctionComponent<UIProps<Flow.EditionForSale>> = ({
  total,
  items,
  onLoadMore,
  searchTerm,
  loading,
  strings,
}) => {
  const navigate = useNavigate();

  return (
    <CollectiblesInfiniteSection
      hasMore={total > items.length}
      loading={loading}
      dataLength={items.length}
      title={strings.collectiblesHeading}
      searchTerm={searchTerm}
      onLoadMore={onLoadMore}
    >
      {items.map((edition: Flow.EditionForSale, index) => (
        <Collectible
          key={index}
          index={index}
          title={edition.name}
          description={`${edition.itemsForSale} ${strings.forSale}`}
          imageSrc={edition.thumbnail ?? undefined}
          footer={
            edition.lowestPrice != null && (
              <p>
                {`${strings.priceFrom} ${formatUsdWithSymbol(
                  edition.lowestPrice
                )}`}
              </p>
            )
          }
          onClick={() => {
            const {
              smartContractName,
              smartContractAddress,
              editionFID,
              merchantFID,
              merchant,
            } = edition;
            const merchantMarketplaceRoute = `${MARKETPLACE_MERCHANT_ROUTE}`
              .replace(":id", merchantFID ?? "")
              .replace(":profileName", merchant?.profileName ?? "");
            const isPFP =
              Project.SMART_CONTRACTS.find(
                (x) =>
                  x.name === smartContractName &&
                  x.address === withPrefix(smartContractAddress)
              )?.type === "PFP";
            navigate(
              isPFP
                ? merchantMarketplaceRoute.concat(
                    `/${MARKETPLACE_PFP_ROUTE}`
                      .replace(
                        ":smartContractAddress",
                        withPrefix(smartContractAddress) ?? ""
                      )
                      .replace(":smartContractName", smartContractName ?? "")
                  )
                : `${EDITION_DETAILS}/${withPrefix(
                    smartContractAddress
                  )}/${smartContractName}/${editionFID}`
            );
          }}
        />
      ))}
    </CollectiblesInfiniteSection>
  );
};

export default Editions;
