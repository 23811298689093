export const getCurrentPosition = async (
  onSuccess?: (value: GeolocationPosition) => void,
  onError?: (error: GeolocationPositionError) => void,
  onNotSupported?: () => void
) => {
  if (navigator.geolocation) {
    return await getCoords()
      .then((pos) => {
        onSuccess?.(pos as GeolocationPosition);
        return pos;
      })
      .catch((error) => {
        onError?.(error as GeolocationPositionError);
      });
  } else {
    onNotSupported?.();
  }
};

const getCoords = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};
