import styles from "../ui/style.module.scss";

type Props = {
  editionName: string;
};

const SisiARLinks = ({ editionName }: Props) => {
  const renderLinks = () => {
    switch (editionName.trim()) {
      case "Dress of Resistance":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_02_rare_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_02_rare_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of the Chosen One":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_03_classic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_03_classic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Choice":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_04_epic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_04_epic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of the Night":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_05_rare_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_05_rare_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of the Empress":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_06_classic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_06_classic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Disobedience":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_07_classic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_07_classic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Reconciliation":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_08_epic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_08_epic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Upheaval":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_09_rare_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_09_rare_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Uniform of the Emperor":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_10_rare_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_10_rare_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Conscience":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_11_classic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_11_classic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Trust":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_12_rare_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_12_rare_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Courage":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_13_epic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_13_epic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Dignity":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_14_classic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_14_classic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Chance":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_15_rare_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_15_rare_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Feelings":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_16_epic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_16_epic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Strength":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_17_classic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_17_classic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Conviction":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_18_rare_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_18_rare_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Purpose":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_19_epic_AR.glb">
              Android Link
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_19_epic_AR.usdz">
              iOS Link
            </a>
          </>
        );
      case "Dress of Determination":
        return (
          <>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/android/Sisi_01_epic_AR.glb">
              Android Links
            </a>
            <a href="https://mint-store-metadata.s3.us-east-2.amazonaws.com/sisi/ar/ios/Sisi_01_epic_AR.usdz">
              iOS Link
            </a>
          </>
        );
    }
  };

  return (
    <div className={styles.sisiARLinks}>
      <p>Exklusive Mehrwerte </p>
      <p>Kleider in Augmented Reality (AR)</p>
      <p>
        Die 3D-Modelle der Kleider zum Öffnen und Anzeigen mit deinem Smartphone
        in der realen Welt.
      </p>
      {renderLinks()}
    </div>
  );
};

export default SisiARLinks;
