const en = {
  shareModalTitle: "Share your unique collectible with others",
  shareModalSubtitle:
    "Show fans your collected NFT. Simply copy and paste the public link.",
  claimAlertModalTitle: "Wallet connected",
  claimAlertModalSubtitle:
    "The connected wallet is not your primary wallet. Do you wish to proceed with claiming this NFT to",
  close: "Close",
  emptyEmailError: "You must enter an email",
  recipientNotFoundError: "Recipient not found",
  nftIdNotSpecifiedError: "NFT not specified",
  noPriceError: "Please provide a price.",
  copiedToClipBoard: "Copied to Clipboard",
  copyLink: "Copy public link",
  claim: "Claim",
  cancel: "cancel",
  title: "Place Item for Sale",
  subtitle: "Please enter a listing price below.",
  placeForSale: "List for Sale",
  placeForSaleInProgress: "Placing item for sale ",
  placeForSaleInProgressSubtitle: "This could take a few minutes.",
  pleaseWait: "Please wait...",
  btnTxt: "ok",
  continue: "continue",
  successTitle: "Item now for sale",
  successSubtitle: "Successfully listed on the marketplace.",
  showOnMarketplace: "Show on Marketplace",
  currentLowestPrice: "Current lowest price",
  marketplaceFee: "Marketplace Fee",
  biggerThan1e21: "Please put a price lower than 1e21.",
  confirm: "Confirm",
  wasSentTo: "Your NFT was sent to this address:",
  noAddress: "No address was found for this name.",
  invalidAddress: "This is not a valid address.",
  connectedAddress: "You are currently connected to this wallet.",
  notSetup: "This recipient's wallet is not set up.",
  learnMore: "Learn more",
  price: "Price",
  transferModal: {
    recipientStep: {
      title: "Transfer Item",
      subtitle:
        "Enter the profile name or blockchain address of the recipient.",
      fieldPlaceholder: "Address or profile name",
      searchRecipient: "Search for recipient",
      recipientNotFound: "Recipient not found",
    },
    transferStep: {
      title: "Transfer Item",
      confirmTitle: "Transfer to",
      loadingTitle: "Transferring to:",
      confirmSubtitleWithUser: "Your NFT will be sent to this user:",
      confirmSubtitleNoUser: "Your NFT will be sent to this address:",
      profileName: "Profile name",
      address: "Address",
      loadingDisclaimer:
        "This may take a while, do not close the window until the process is completed.",
      successTitle: "Transfer complete",
      successSubtitle: "Successfully sent to recipient:",
      errorTitle: "Transfer unsuccessful",
      errorSubtitle: "The NFT transfer could not be completed.",
      changeInput: "Change input",
      transferBtn: "Transfer",
    },
  },
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
