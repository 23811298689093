import React from "react";
import { useLanguage } from "lib/contexts/LanguageContext";
import { Config, getLocalizedStrings } from "config";
import { FAQ_ZENDESK } from "config/routes";

export const LearnMoreLink = React.memo(() => {
  const localizedStrings = getLocalizedStrings(
    "Pages",
    "Details",
    useLanguage()
  );

  switch (Config.Client.HIDE_NFT_OWNER_AND_CREATOR) {
    case true:
      return null;
    default:
      return (
        <a
          href={`${FAQ_ZENDESK}/articles/6403056080273-How-do-I-claim-my-NFT-`}
          target="_blank"
        >
          {localizedStrings.learnMoreLinkTxt}
        </a>
      );
  }
});
