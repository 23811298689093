import { SmartContract } from "../../../../../types";

export const NftResultStruct = (contract: SmartContract): string => `  
access(all) struct NftResult {
   access(all) var id: UInt64
    access(all) var name: String
    access(all) var description: String
    access(all) var thumbnail: {MetadataViews.File}
    access(all) var price: UFix64?
    access(all) var printingLimit: UInt64?
    access(all) var editionName: String?
    access(all) var editionNumber: UInt64
    access(all) var merchantID: UInt32?
    access(all) var traits: [MetadataViews.Trait]
    access(all) var smartContractName: String
    access(all) var smartContractAddress: String

    init(id: UInt64, price: UFix64?, merchantID: UInt32?, displayView: MetadataViews.Display, editionsView: MetadataViews.Editions, traits: [MetadataViews.Trait]) {
        self.id = id
        self.name = displayView.name
        self.description = displayView.description
        self.thumbnail = displayView.thumbnail
        self.price = price
        self.printingLimit = editionsView.infoList[0].max
        self.editionNumber = editionsView.infoList[0].number
        self.editionName = editionsView.infoList[0].name
        self.merchantID = merchantID
        self.traits = traits
        self.smartContractName = "${contract.name}"
        self.smartContractAddress = "${contract.address}";
    }
}

`;
