const MERCHANT_LIST = {
  Testnet: [{ id: "1", address: "0x28492e97618732eb" }],
  Mainnet: [{ id: "38", address: "0xd3409e6afc5deafc" }],
};

const MINT_STORE_MARKET = {
  Testnet: { id: 1, address: "0x28492e97618732eb" },
  Mainnet: { id: 7, address: "0xd3409e6afc5deafc" },
};

const WALLET_VIEWER_ID = {
  Testnet: 1,
  Mainnet: 3,
};

export { MERCHANT_LIST, MINT_STORE_MARKET, WALLET_VIEWER_ID };
