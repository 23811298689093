import feathersClient from "lib/feathers";
import { RootState } from "lib/store";
import { selectAppUser, setAppUser } from "lib/store/slices/user-slice";
import { Eth } from "types";
import { isValidAddress } from "utils/utils";
import { Builder } from "./builder";
import { Recipient } from "types/app-model/global";

export const ethWalletEndpoints = (builder: Builder) => ({
  getWalletList: builder.query<
    Eth.Wallet[],
    { userId: string | undefined; address?: string | undefined }
  >({
    async queryFn({ userId, address }) {
      if (userId == null) return { data: [] } as { data: Eth.Wallet[] };
      const { data } = await feathersClient
        .service("polygon/wallet")
        .find({ query: { userId, address } });
      const validWallets = data.filter((wallet) =>
        isValidAddress(wallet.address)
      );
      return { data: validWallets };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.map(({ id }) => ({ type: "EthWallet" as const, id })),
            "EthWallet",
          ]
        : ["EthWallet"],
  }),

  updateWallet: builder.mutation<
    Eth.Wallet,
    { id: number; walletName?: string }
  >({
    async queryFn(wallet) {
      const { id, ...data } = wallet;
      const updatedWallet = await feathersClient
        .service("polygon/wallet")
        .patch(id.toString(), data);
      return { data: updatedWallet };
    },
    invalidatesTags: (_, __, { id }) => [{ type: "EthWallet", id }],
  }),

  getRecipient: builder.mutation<Recipient, { nameOrAddress: string }>({
    async queryFn({ nameOrAddress: value }) {
      const method: "profileName" | "address" = isValidAddress(value)
        ? "address"
        : "profileName";

      try {
        const data = await feathersClient
          .service(`users/primary-wallet/${method}`)
          .get(value);

        return { data };
      } catch (_error) {
        return { data: null };
      }
    },
  }),

  registerWallet: builder.mutation<
    Eth.Wallet,
    { address: string; walletType: EthWalletType }
  >({
    async queryFn({ address, walletType }, { dispatch, getState }) {
      const wallet = await feathersClient
        .service("polygon/wallet/register")
        .create({ ethAddress: address, walletType });

      // Reset the user to get its new primary wallet ID
      const user = selectAppUser(getState() as RootState);
      if (user != null) {
        const nextUser = await feathersClient
          .service("users")
          .get(user.id.toString());
        dispatch(setAppUser(nextUser));
      }

      return { data: wallet };
    },
    invalidatesTags: ["EthWallet"],
  }),
});
