import { authenticationAPI } from "lib/store/api/authentication-api";
import { useAppDispatch } from "lib/store/hooks";
import { setAppUser } from "lib/store/slices/user-slice";
import { useState, useEffect } from "react";
import { LoginModalUI } from "../ui/LoginUI";

type Props = {
  onCancel: () => void;
  onComplete: () => void;
  isOnRedemptionPage: boolean;
};

export function LoginModalConnector({
  onCancel,
  onComplete,
  isOnRedemptionPage,
}: Props) {
  const dispatch = useAppDispatch();
  const [
    requestLoginCode,
    { data: emailData, isLoading: isEmailDataLoading, reset: resetEmailData },
  ] = authenticationAPI.useRequestLoginCodeMutation();
  const [
    loginWithCode,
    {
      data: loginData,
      isLoading: isLoginDataLoading,
      isError: loginError,
      originalArgs: lastEmailAndCode,
    },
  ] = authenticationAPI.useLazyLoginWithCodeQuery();
  const [invalidCode, setInvalidCode] = useState(false);

  async function onSubmit(email: string, code?: string) {
    const { pathname, search } = window.location;
    window.localStorage.setItem(
      "loginCallback",
      `${pathname ?? "/"}${search ?? ""}`
    );

    if (code == null) {
      await requestLoginCode({
        email: email,
        isRedemption: isOnRedemptionPage,
      });
      setInvalidCode(false);
    } else {
      await loginWithCode({ email, code });
    }
  }

  useEffect(() => {
    if (loginData) {
      dispatch(setAppUser(loginData));
      onComplete();
    }
  }, [loginData]);

  useEffect(() => {}, [emailData]);

  useEffect(() => {
    if (isEmailDataLoading === false && loginError) {
      setInvalidCode(true);
    }
  }, [loginError, isLoginDataLoading]);

  return (
    <LoginModalUI
      onSubmit={onSubmit}
      onCancel={onCancel}
      isEmailDataLoading={isEmailDataLoading}
      emailError={emailData?.error}
      resetEmailData={resetEmailData}
      isLoginDataLoading={isLoginDataLoading}
      loginError={invalidCode}
      isOnRedemptionPage={isOnRedemptionPage}
      lastEmailAndCode={lastEmailAndCode}
    />
  );
}
