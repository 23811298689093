const en = {
  login: "Login",
  email: {
    title: "Open your Mint wallet",
    subtitle: "Please enter your email to access or register your wallet.",
    inputPlaceholder: "Enter your email",
    btnTxt: "Continue",
    redeemTitle: "Enter email",
    redeemSubtitle: "Please enter your email address to receive your NFT.",
    redeemPlayOffSubtitle: "",
  },
  code: {
    title: "Enter login code",
    subtitle: "Please enter the six-digit code sent to your email.",
    btnTxt: "Continue",
    backLinkText: "Use another account",
  },
  errors: {
    incompleteCode: "Your login code is incomplete",
    emailRequired: "Email is required",
    invalidEmailAddress: "Please enter a valid email address.",
    incorrectCode: "Incorrect login code. Please try again or",
    resendCode: "click here to send new code",
  },
  agreeToTOS: "by Clicking continue you agree to these terms of service:",
  agreeToTOS2ndP: "",
  japaneseStuff: "",
};

const ja = { ...en };
const de = { ...en };

export default { en, ja, de };
