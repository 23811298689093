import { Config, getLocalizedStrings } from "config";
import {
  MARKETPLACE_ROUTE,
  NFT_LIST_ROUTE,
  REDEMPTION_NFT_ROUTE,
  UTILITY_ROUTE,
  REDEEM_ROUTE,
} from "config/routes";
import useIsMobile from "hooks/useIsMobile";
import { useLanguage } from "lib/contexts/LanguageContext";
import { useAppSelector } from "lib/store/hooks";
import { selectAppUser } from "lib/store/slices/user-slice";
import { FunctionComponent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginLink from "./LoginLink";
import style from "./style.module.scss";
import UserLinks from "./UserLinks";
const AppHeader: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(selectAppUser);
  const isHeader = true;
  const isMobile = useIsMobile(isHeader);
  const strings = getLocalizedStrings("Component", "AppHeader", useLanguage());

  const renderRedirectionLink = () => {
    if (!Config.MarketPlace.hasMarketplace) return NFT_LIST_ROUTE;

    return MARKETPLACE_ROUTE;
  };

  const redemptionPathRegex = "/" + REDEMPTION_NFT_ROUTE.split("/")[1] + "/.+";
  const isRedemptionPage = location.pathname.match(redemptionPathRegex);
  const isRedeemPathRegex = location.pathname.match(REDEEM_ROUTE);

  return (
    <nav className={style.nav}>
      <div className={`${style.header} ${style.container}`}>
        {Config.MarketPlace.redirectMarketplaceLink ? (
          <a
            href={`${Config.MarketPlace.redirectMarketplaceLink}/collection`}
            target="_blank"
            className={style.logo}
          >
            <img
              height={Config.Client.LOGO_HEIGHT_PX ?? 58}
              src={Config.Client.LOGO_SVG_SRC}
              alt={Config.Client.TEAM_NAME}
            ></img>
          </a>
        ) : (
          <img
            height={58}
            src={Config.Client.LOGO_SVG_SRC}
            onClick={() => {
              if (Config.Utility.HAS_UTILITY_PAGE) {
                navigate(UTILITY_ROUTE);
              } else {
                navigate(renderRedirectionLink());
              }
            }}
            alt={Config.Client.TEAM_NAME}
          />
        )}
        {user || isMobile ? (
          <UserLinks strings={strings} user={user} />
        ) : (
          !isRedemptionPage &&
          !isRedeemPathRegex &&
          !isMobile && <LoginLink strings={strings} />
        )}
      </div>
    </nav>
  );
};
export default AppHeader;
